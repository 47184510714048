<template>
	<v-layout row justify-center>
		<v-dialog v-model="show_detail" persistent max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">Detalle del registro</span>
				</v-card-title>
				<v-card-text>
					<v-container grid-list-md>
						<v-layout wrap>
							<v-flex xs12>
								<!-- Sucursal -->
								<v-list-tile avatar>
									<v-list-tile-action>
										<v-icon>store_mall_directory</v-icon>
									</v-list-tile-action>
									<v-list-tile-content>
										<v-list-tile-title>
											Cliente/Sucursal:
										</v-list-tile-title>
										<v-list-tile-sub-title>
											{{ sucursal_formateada }}
										</v-list-tile-sub-title>
									</v-list-tile-content>
								</v-list-tile>
							</v-flex>
							<v-flex xs12>
								<!-- Sucursal -->
								<v-list-tile avatar>
									<v-list-tile-action>
										<v-icon>school</v-icon>
									</v-list-tile-action>
									<v-list-tile-content>
										<v-list-tile-title>
											Tipo de orden:
										</v-list-tile-title>
										<v-list-tile-sub-title>
											{{ tipoOrden.nombre }}
										</v-list-tile-sub-title>
									</v-list-tile-content>
								</v-list-tile>
							</v-flex>
							<v-flex xs6>
								<!-- Fecha de inicio -->
								<v-list-tile avatar>
									<v-list-tile-action>
										<v-icon>event</v-icon>
									</v-list-tile-action>
									<v-list-tile-content>
										<v-list-tile-title>
											Fecha de inicio:
										</v-list-tile-title>
										<v-list-tile-sub-title>
											{{ fecha_inicio_formateada }}
										</v-list-tile-sub-title>
									</v-list-tile-content>
								</v-list-tile>
							</v-flex>

							<v-flex xs6>
								<!-- Fecha de fin -->
								<v-list-tile avatar>
									<v-list-tile-action>
										<v-icon>event</v-icon>
									</v-list-tile-action>
									<v-list-tile-content>
										<v-list-tile-title
											>Fecha de fin:</v-list-tile-title
										>
										<v-list-tile-sub-title>
											{{ fecha_fin_formateada }}
										</v-list-tile-sub-title>
									</v-list-tile-content>
								</v-list-tile>
							</v-flex>

							<v-flex xs6>
								<!-- Fecha expiración -->
								<v-list-tile avatar>
									<v-list-tile-action>
										<v-icon>notification_important</v-icon>
									</v-list-tile-action>
									<v-list-tile-content>
										<v-list-tile-title
											>Fecha de
											expiración:</v-list-tile-title
										>
										<v-list-tile-sub-title>
											{{ fecha_expiracion_formateada }}
										</v-list-tile-sub-title>
									</v-list-tile-content>
								</v-list-tile>
							</v-flex>

							<v-flex xs8>
								<!-- Número de orden -->
								<v-list-tile avatar>
									<v-list-tile-action>
										<v-icon>numbers</v-icon>
									</v-list-tile-action>
									<v-list-tile-content>
										<v-list-tile-title
											>Número de orden:</v-list-tile-title
										>
										<v-list-tile-sub-title>
											{{ numeroorden }}
										</v-list-tile-sub-title>
									</v-list-tile-content>
								</v-list-tile>
							</v-flex>
						</v-layout>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						flat
						@click="openDetail(false)"
					>
						Cerrar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-layout>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
	data: () => ({}),

	methods: {
		...mapMutations({
			openDetail: "orden/OPEN_DETAIL",
		}),

		formatDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
	},

	computed: {
		...mapState({
			tipoOrden: (state) => state.orden.tipoOrden,
			sucursal: (state) => state.orden.sucursal,
			numeroorden: (state) => state.orden.numeroorden,
			fechavencimiento: (state) => state.orden.fechavencimiento,
			fechainicio: (state) => state.orden.fechainicio,
			fechafin: (state) => state.orden.fechafin,
			show_detail: (state) => state.orden.show_detail,
			capacitacion: (state) => state.orden.capacitacion,
		}),

		fecha_expiracion_formateada() {
			return this.formatDate(this.fechavencimiento);
		},

		fecha_fin_formateada() {
			return this.fechafin
				? this.formatDate(this.fechafin)
				: "No se especificó";
		},

		fecha_inicio_formateada() {
			return this.formatDate(this.fechainicio);
		},

		sucursal_formateada() {
			return `${this.sucursal?.cliente?.razonSocial} | ${this.sucursal?.localidad?.nombre} | ${this.sucursal?.direccion}`;
		},
	},
};
</script>
