<template>
  <v-container>
    <h3 class="mb-4">Rubros</h3>
    <!-- Card -->
    <v-card class="my-2">
      <v-card-title>
        <v-layout row wrap class="mb-2 px-2">
          <v-spacer></v-spacer>
          <v-flex xs3 class="px-2">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Filtrar"
              single-line
              hide-details
            />
          </v-flex>
        </v-layout>
      </v-card-title>
      <!-- Table -->
      <v-data-table :headers="headers" :items="rubros_filter">
        <template v-slot:items="rubros">
          <td class="text-xs-center">
            {{ rubros.item.nombre }}
          </td>
          <td v-if="rubros.item.controlContratista" class="text-xs-center green--text text--lighten-1">Si</td>
          <td v-else class="text-xs-center red--text text--lighten-1">No</td>
          <td class="text-xs-center">
            -
          </td>
          <td class="text-xs-center">
            0
          </td>  
          <td class="justify-center layout px-0">
            <v-icon
              small
              title="Editar registro"
              class="mr-2"
              @click="showForm(rubros.item)"
            >
              edit
            </v-icon>
            <v-icon
              small
              title="Eliminar registro"
              @click="showConfirmDelete(rubros.item._id)"
            >
              delete
            </v-icon>
          </td>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
            No se encontraron resultados para "{{ search }}".
          </v-alert>
        </template>
      </v-data-table>
    </v-card>

    <v-layout align-center justify-end row fill-height class="mt-2">
      <v-flex xs2 class="mx-2">
        <v-btn color="primary" block @click="showForm(false)">
          Registrar rubro
        </v-btn>
      </v-flex>
    </v-layout>


    <!-- Modals -->
    <rubro-form />
    <confirm-delete :rubro_id="rubro_id" />
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import RubroForm from "./RubroForm.vue";
import ConfirmDelete from "./ConfirmDelete.vue";
//import Detail from "./Detail.vue";

export default {
  data: () => ({
    rubro_id: null,
    search: "",
    headers: [
      {
        sortable: true,
        text: "Nombre",
        value: "nombre",
        align: "center",
      },

      {
        sortable: true,
        text: "Control Contratista",
        value: "controlContratista",
        align: "center",
      },

      {
        sortable: true,
        text: "Descripción",
        value: "",
        align: "center",
      },

      {
        sortable: true,
        text: "Numero de clientes de este rubro",
        value: "",
        align: "center",
      },
      
      {
        sortable: false,
        text: "Acciones",
        value: "actions",
        align: "center",
      },
    ],
  }),

  mounted() {
    this.getRubros();
  },

  components: {
    RubroForm,
    ConfirmDelete,
  },

  computed: {
    ...mapState({
      rubros: (state) => state.rubro.rubros,
    }),

    rubros_filter() {
      const valid = this.search && this.search.trim().length > 0;
      if (valid) {
        return this.rubros.filter(
          (item) =>
            item.nombre.search(this.search) > -1  
        );
      }
      return this.rubros;
    },
  },

  methods: {
    ...mapActions({
      getRubros: "rubro/getRubros",
    }),

    ...mapMutations({
      /**
       * Rubros
       */
      setShowConfirmDelete: "rubro/SET_SHOW_CONFIRM_DELETE",
      openForm: "rubro/OPEN_FORM",
      loadForm: "rubro/LOAD_FORM",
      setIsUpdateForm: "rubro/SET_IS_UPDATE_FORM",
      resetForm: "rubro/RESET",
    }),
    
    showConfirmDelete(id) {
      this.rubro_id = id;
      this.setShowConfirmDelete(true);
    },
    
    showForm(rubro) {
      if (rubro) this.loadForm(rubro);
      this.setIsUpdateForm(rubro);
      this.openForm(true);
    },
    
  },
};
</script>

