export const defaultState = function() {
    return {
        //Action get
        getLoading: false,
        getSuccess: false,
        getError: false,
        getErrorMessage: '',
        //Action post
        postLoading: false,
        postSuccess: false,
        postError: false,
        postErrorMessage: '',
        //Action updateTheme
        updateLoading: false,
        updateSuccess: false,
        updateError: false,
        updateErrorMessage: '',
        //Action remove
        removeLoading: false,
        removeSuccess: false,
        removeError: false,
        removeErrorMessage: ''
    }
}