import { defaultState } from './config';

const state = Object.assign(
  {
    id: null,
    nombreFantasia: null,
    razonSocial: null,
    rubro: null,
    telefono: null,
    cuit: null,
    email: null,
    pais: null,
    categoriaAfip: null,
    image: null,
    active: null,
    show_confirm_delete: false,
    show_form: false,
    show_detail: false,
    is_update_form: false,
    filename: null,
    linktofile: null
  },
  defaultState()
);

export default state;