<template>
	<v-container>
		<h3 class="mb-4">Mis documentaciones</h3>

		<v-card class="my-2">
			<!-- <v-card-title>
				<v-layout row wrap class="mb-2 px-2">
					<v-spacer></v-spacer>
					<v-flex xs3 class="px-2">
						<v-text-field
							append-icon="search"
							label="Buscar"
							single-line
							hide-details
						/>
					</v-flex>
				</v-layout>
			</v-card-title> -->

			<v-card-text>
				<v-data-table
					:headers="headers"
					:items="documentos"
					:pagination.sync="pagination"
				>
					<template v-slot:items="documentos">
						<td class="text-xs-center">
							{{
								formatDate(
									documentos.item.createdAt.split("T")[0]
								)
							}}
						</td>
						<td class="text-xs-center">
							<v-btn
								:href="documentos.item.file"
								target="_blank"
								fab
								color="blue"
								dark
								small
								depressed
							>
								<v-icon>mdi-file</v-icon>
							</v-btn>
						</td>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
	data() {
		return {
			headers: [
				{
					sortable: true,
					text: "Fecha",
					value: "createdAt",
					align: "center",
				},
				{
					sortable: true,
					text: "Archivo",
					value: "pdf",
					align: "center",
				},
			],
			pagination: {
				rowsPerPage: -1, // con -1 mustra "Todos"
			},
		};
	},
	computed: {
		...mapState({
			documentos: (state) => state.pdf.documentosClient,
			user: (state) => state.user,
		}),
	},
	methods: {
		formatDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
	},
	mounted() {
		if (this.user.cliente) {
			const { _id } = this.user.cliente;
			this.$store.dispatch("pdf/getDocumentosByClient", _id);
		} else {
			this.$router.push("/documentaciones");
		}
	},
};
</script>
