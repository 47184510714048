import categoriaafipApi from "../../../api/categoriaafipApi";

export default {
    getCategoriasAfip({ commit }) {
        return categoriaafipApi.getCategoriasAfip()
            .then(response => {
                let categoriasAfip = response.data;
                console.log(categoriasAfip);
                commit("GET_CATEGORIAS_AFIP_SUCCESS", categoriasAfip);
            })
            .catch(e => {
                commit("GET_CATEGORIAS_AFIP_ERROR", e);
            });
    },
};