import notificacionApi from "../../../api/notificacionApi";

export default {
    getNotificaciones({ commit }) {
        return notificacionApi
            .getNotificaciones()
            .then((response) => {
                let notificaciones = response.data;
                commit("GET_NOTIFICACIONES_SUCCESS", notificaciones);
            }).catch((e) => {
                commit("GET_NOTIFICACIONES_ERROR", e);
            });
    }
}