import { defaultState } from './config';
import Vue from 'vue';

export const mutations = {
    RESET(state) {
        state.nombre = null;
        state.controlContratista = null;
    },
       
    GET_RUBROS_LOADING(state){
        state.getRubrosLoading = true;
        state.getRubrosSuccess = false;
        state.getRubrosError = false;
        state.getRubrosErrorMessage = '';
        state.clientes = [];
    },
    GET_RUBROS_SUCCESS(state, rubros) {
        state.getRubrosLoading = false;
        state.getRubrosSuccess = true;
        state.rubros= [];

      rubros.map((rubro, index) => {
        Vue.set(state.rubros, index, { ...rubro });
      });
    },
    GET_RUBROS_ERROR(state, e) {
        state.getRubrosLoading = false;
        state.getRubrosError = true;
        state.getRubrosErrorMessage = e;
    },
    REMOVE_RUBRO_SUCCESS(state) {
        state.removeRubroLoading = false;
        state.removeRubroSuccess = true;
    },
    REMOVE_RUBRO_LOADING(state){
        state.removeRubroLoading = true;
        state.removeRubroSuccess = false;
        state.removeRubroError = false;
        state.removeRubroErrorMessage = '';
    },

    REMOVE_RUBRO_ERROR(state, e) {
        state.removeRubroLoading = false;
        state.removeRubroError = true;
        state.removeRubroErrorMessage = e;
    },

    OPEN_FORM(state, payload) {
        state.show_form = payload;
    },
    
    LOAD_FORM(state, payload) {
        state.id = payload._id;
        state.nombre = payload.nombre;
        state.controlContratista = payload.controlContratista;
    },

    SET_IS_UPDATE_FORM(state, payload) {
        state.is_update_form = payload;
        if (!payload) {
          state.nombre = null;
          state.controlContratista = null;
        }
    },

    SET_NOMBRE(state, payload) {
        state.nombre = payload;
    },

    SET_CONTROLCONTRATISTA(state, payload) {
        state.controlContratista = payload;
    },    

    SET_SHOW_CONFIRM_DELETE(state, payload) {
        state.show_confirm_delete = payload;
    },

    POST_RUBRO_LOADING(state){
        state.postRubroLoading = true;
        state.postRubroSuccess = false;
        state.postRubroError = false;
        state.postRubroErrorMessage = '';
    },
    
    POST_RUBRO_SUCCESS(state) {
        state.postRubroLoading = false;
        state.postRubroSuccess = true;
        Vue.swal({
            title: 'Registrado',
            text: 'rubro laboral registrado',
            type: 'success',
            width: '350px',
            timer: 2000,
            position: 'top-end',
        });

    },

    POST_RUBRO_ERROR(state, e) {
        state.postRubroLoading = false;
        state.postRubroError = true;
        state.postRubroErrorMessage = e;
    },

    UPDATE_RUBRO_SUCCESS(state) {
        state.updateRubroThemeLoading = false;
        state.updateRubroThemeSuccess = true;
    },

    UPDATE_RUBRO_ERROR(state, e) {
        state.updateRubroLoading = false;
        state.updateRubroError = true;
        state.updateRubroErrorMessage = e;
        Vue.swal({
            title: 'Error',
            text: 'algo fallo durante la actualización',
            type: 'error',
            width: '350px',
            timer: 2000,
            position: 'top-end',
        });
    },
    
};