import { defaultState } from './config';

const state = Object.assign(
    {
      id: null,
      direccion: null,
      cliente: null,
      sucursal: null,
      localidad: null,
      direccion: null,
      telefono: null,
      email: null,
      secondEmail: null,
      show_confirm_delete: false,
      show_form: false,
      show_detail: false,
      is_update_form: false,
    },
    defaultState()
);

export default state;