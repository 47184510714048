<template>
	<v-layout row justify-center>
		<v-dialog v-model="show_form" persistent max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">{{ title_form }}</span>
				</v-card-title>
				<v-card-text>
					<v-form
						ref="form-localidad"
						v-model="valid"
						lazy-validation
					>
						<v-container grid-list-md>
							<v-layout wrap>
								<v-flex xs12>
									<v-text-field
										label="Nombre"
										v-model="nombre"
										@change="setNombre"
										required
										:rules="form_rules"
										prepend-icon="mdi-map-marker-radius"
									/>
								</v-flex>
								<v-flex xs12>
									<v-text-field
										label="Codigo Postal"
										v-model="codigoPostal"
										@change="setCodigoPostal"
										hint="campo opcional"
										:rules="form_rules"
										prepend-icon="mdi-numeric"
									/>
								</v-flex>
								<v-flex xs6>
									<v-select
										:items="provincias"
										v-model="provincia"
										@change="setProvincia"
										label="Provincias"
										item-text="nombre"
										outline
										required
										:rules="form_rules"
										return-object
										prepend-icon="mdi-map-marker-multiple"
									></v-select>
								</v-flex>
								<v-flex xs6>
									<v-autocomplete
										:items="paises"
										v-model="pais"
										@change="setPais"
										label="País"
										item-text="nombre"
										outline
										required
										:rules="form_rules"
										return-object
										prepend-icon="mdi-map-marker-multiple"
									></v-autocomplete>
								</v-flex>
							</v-layout>
						</v-container>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" flat @click="closeForm">
						Cerrar
					</v-btn>
					<v-btn color="blue darken-1" flat @click="save"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-layout>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import axios from "axios";
import Cookies from "js-cookie";

export default {
	data: () => ({
		dialog: false,
		valid: true,
		form_rules: [(v) => !!v || "Este campo es requerido"],
	}),

	mounted() {
		axios
			.get(`${process.env.VUE_APP_IP}/api/paises`, {
				headers: { "x-access-token": Cookies.get("token") },
			})
			.then((response) => {
				this.paises = response.data;
			});
	},

	watch: {
		is_update_form(newState, oldTitle) {
			if (!newState) this.resetForm();
		},
	},

	computed: {
		...mapState({
			provincias: (state) => state.provincia.provincias,
			nombre: (state) => state.localidad.nombre,
			provincia: (state) => state.localidad.provincia,
			codigoPostal: (state) => state.localidad.codigoPostal,
			pais: (state) => state.localidad.pais,
			show_form: (state) => state.localidad.show_form,
			is_update_form: (state) => state.localidad.is_update_form,
		}),

		title_form() {
			return this.is_update_form
				? "Actualizar localidad"
				: "Registrar localidad";
		},
	},

	methods: {
		...mapMutations({
			setProvincia: "localidad/SET_PROVINCIA",
			setNombre: "localidad/SET_NOMBRE",
			setCodigoPostal: "localidad/SET_CODIGOPOSTAL",
			setPais: "localidad/SET_PAIS",
			openForm: "localidad/OPEN_FORM",
			resetLocalidad: "localidad/RESET",
		}),

		...mapActions({
			postLocalidad: "localidad/postLocalidad",
			updateLocalidad: "localidad/updateLocalidad",
		}),

		formatDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},

		parseDate(date) {
			if (!date) return null;
			const [month, day, year] = date.split("/");
			return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
		},

		resetForm() {
			this.resetLocalidad();
			this.valid = true;
		},

		closeForm() {
			this.resetForm();
			this.$refs["form-localidad"].reset();
			this.openForm(false);
		},

		save() {
			if (this.$refs["form-localidad"].validate()) {
				if (this.is_update_form) {
					this.updateLocalidad();
				} else {
					this.postLocalidad();
				}
				this.closeForm(false);
			}
		},
	},
};
</script>
