<template>
  <v-layout row justify-center>
    <v-dialog v-model="show_form" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ title_form }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form-empleado" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs6>
                  <v-text-field
                    label="Apellido/s"
                    v-model="apellido"
                    @change="setApellido"
                    required
                    :rules="form_rules"
                  />
                </v-flex>

                <v-flex xs6>
                  <v-text-field
                    label="Nombre/s"
                    v-model="nombre"
                    @change="setNombre"
                    required
                    :rules="form_rules"
                  />
                </v-flex>

                <v-flex xs6>
                  <v-text-field
                    type="number"
                    label="Documento"
                    v-model="documento"
                    @change="setDocumento"
                  />
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    type="number"
                    label="CUIT"
                    v-model="cuit"
                    @change="setCuit"
                  />
                </v-flex>

                <v-flex xs6>
                  <v-menu
                    ref="menu_fecha_ingreso"
                    v-model="menu_fecha_ingreso"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="fecha_ingreso_formateada"
                        label="Fecha de ingreso"
                        :rules="form_rules"
                        persistent-hint
                        prepend-icon="event"
                        @blur="
                          fechadeingreso = parseDate(fecha_ingreso_formateada)
                        "
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="fechadeingreso"
                      @change="setFechadeIngreso"
                      no-title
                      @input="menu_fecha_ingreso = false"
                    />
                  </v-menu>
                </v-flex>

                <v-flex xs6>
                  <v-text-field
                    label="Puesto de Trabajo"
                    v-model="tipo"
                    @change="setTipo"
                    :rules="form_rules"
                  />
                </v-flex>

                <v-flex xs12 v-if="is_admin">
                  <v-autocomplete
                    :items="clientes"
                    v-model="cliente"
                    @change="setCliente"
                    label="Cliente"
                    item-text="nombreFantasia"
                    :rules="form_rules"
                    return-object
                    outline
                    required
                    prepend-icon="mdi-account-outline"
                  />
                </v-flex>

                <v-flex xs12>
                  <v-autocomplete
                    :items="filterSucursalByCliente"
                    v-model="sucursal"
                    @change="setSucursal"
                    label="Sucursal"
                    item-text="direccion"
                    :rules="form_rules"
                    return-object
                    outline
                    required
                    prepend-icon="house"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat @click="closeForm"> Cerrar </v-btn>
          <v-btn color="blue darken-1" flat @click="save">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data: () => ({
    dialog: false,
    valid: true,
    form_rules: [(v) => !!v || "Este campo es requerido"],
    /*
    number_rules: v  => {
      if (!v.trim()) return true;
      if (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) return true;
      return 'Este campo solo admite numeros';
    },
    */
    number_rules: [(v) => /[0-9]+-/.test(v) || "Este campo solo admite números"],
    menu_fecha_ingreso: false,
  }),

  mounted() {},

  watch: {
    is_update_form(newState, oldTitle) {
      if (!newState) this.resetForm();
    },
  },

  computed: {
    ...mapState({
      clientes: (state) => state.cliente.clientes,
      sucursales: (state) => state.sucursal.sucursales,
      nombre: (state) => state.empleado.nombre,
      apellido: (state) => state.empleado.apellido,
      documento: (state) => state.empleado.documento,
      cuit: (state) => state.empleado.cuit,
      tipo: (state) => state.empleado.tipo,
      fechadeingreso: (state) => state.empleado.fechadeingreso,
      cliente: (state) => state.empleado.cliente,
      sucursal: (state) => state.empleado.sucursal,
      show_form: (state) => state.empleado.show_form,
      is_update_form: (state) => state.empleado.is_update_form,
      user: (state) => state.user,
    }),

    title_form() {
      return this.is_update_form ? "Actualizar empleado" : "Nuevo empleado";
    },

    fecha_ingreso_formateada() {
      return this.formatDate(this.fechadeingreso);
    },

    is_admin() {
      return this.user.isAdmin;
    },

    filterSucursalByCliente() {
      if (this.cliente) {
        return this.sucursales.filter(
          (sucursal) => sucursal.cliente_id == this.cliente._id
        );
      } else {
        return this.sucursales;
      }
    },
  },

  methods: {
    ...mapMutations({
      setCliente: "empleado/SET_CLIENTE",
      setSucursal: "empleado/SET_SUCURSAL",
      setNombre: "empleado/SET_NOMBRE",
      setApellido: "empleado/SET_APELLIDO",
      setDocumento: "empleado/SET_DOCUMENTO",
      setCuit: "empleado/SET_CUIT",
      setProfesion: "empleado/SET_PROFESION",
      setTipo: "empleado/SET_TIPO",
      setFechadeIngreso: "empleado/SET_FECHA_DE_INGRESO",
      openForm: "empleado/OPEN_FORM",
      resetEmpleado: "empleado/RESET",
    }),

    ...mapActions({
      postEmpleado: "empleado/postEmpleado",
      updateEmpleado: "empleado/updateEmpleado",
    }),

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    resetForm() {
      this.resetEmpleado();
      this.valid = true;
    },

    closeForm() {
      this.resetForm();
      this.$refs["form-empleado"].reset()
      this.openForm(false);
    },

    async save() {
      if (this.$refs["form-empleado"].validate()) {
        if (this.is_update_form) {
          await this.updateEmpleado();
        } else {
          await this.postEmpleado();
        }

        this.closeForm()
      }
    },
  },
};
</script>
