import clienteApi from "../../../api/clienteApi";
import localidadApi from "../../../api/localidadApi";

export default {

  getClientes({ commit }) {
    return clienteApi
      .getClientes()
      .then((response) => {
        let clientes = response.data;
        commit("GET_CLIENTES_SUCCESS", { clientes });
      })
      .catch((e) => {
        commit("GET_CLIENTES_ERROR", e);
      });
  },
  getClientesDocs({ commit }) {
    return clienteApi
      .getClientesDocs()
      .then((response) => {
        let clientes = response.data;
        commit("GET_CLIENTES_SUCCESS", { clientes });
      })
      .catch((e) => {
        commit("GET_CLIENTES_ERROR", e);
      });
  },
  getDocs({ commit }, _id) {
    let result = false
    clienteApi
      .getDocs(_id)
      .then((response) => {
        let documentos = response.data;
        result = documentos
        commit("GET_DOCUMENTOS_SUCCESS", { documentos });
      })
      .catch((e) => {
        commit("GET_DOCUMENTOS_ERROR", e);
      });
    return new Promise((resolve) => {
      let x = setInterval(async () => {
        if (result) {
          clearInterval(x)
          resolve(result)
        }
      }, 20)
    })
  },

  postCliente({ dispatch, commit, state }) {
    return clienteApi
      .postCliente(state)
      .then((_response) => {
        if (_response.data == "cliente duplicado") {
          commit("POST_CLIENTE_DUPLICATED", _response.data);
        } else {
          commit("POST_CLIENTE_SUCCESS");
          dispatch("getClientes");
        }
      })
      .catch((e) => {
        commit("POST_CLIENTE_ERROR", e);
      });
  },

  updateCliente({ commit, dispatch, state }) {
    return clienteApi
      .updateCliente(state)
      .then((_response) => {
        dispatch("getClientes");
        commit("UPDATE_CLIENTE_SUCCESS");
      })
      .catch((e) => {
        commit("UPDATE_CLIENTE_ERROR", e);
      });
  },
  /*
  updateClienteStatus({ commit, dispatch, state }) {
    return clienteApi
      .updateClienteStatus(state)
      .then((_response) => {
        dispatch("getClientes");
        commit("UPDATE_CLIENTE_STATUS_SUCCESS");
      })
      .catch((e) => {
        commit("UPDATE_CLIENTE_STATUS_ERROR", e);
      });
  },
  */
  /* removeCliente({ dispatch, commit },  id) {
    commit("REMOVE_CLIENTE_LOADING");
    return clienteApi
      .removeCliente(id)
      .then((_response) => {
        commit("REMOVE_CLIENTE_SUCCESS");
        dispatch("getClientes");
      })
      .catch((e) => {
        commit("REMOVE_CLIENTE_ERROR", e);
      });
  }, */

  deactivateCliente({ dispatch, commit }, id) {
    return clienteApi
      .deactivateCliente(id)
      .then((_response) => {
        commit("DEACTIVATE_CLIENTE_SUCCESS");
        dispatch("getClientes");
      })
      .catch((e) => {
        commit("DEACTIVATE_CLIENTE_ERROR", e);
      });
  },
};