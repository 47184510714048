import { defaultState } from './config';
import Vue from 'vue';

export const mutations = {
    RESET(state) {
      state.cliente = null;
      state.sucursal = null;
      state.nombre = null;
      state.apellido = null;
      state.documento = null;
      state.cuit = null;
      state.tipo = null;
      state.fechadeingreso = null;
    },

    SET_SHOW_CONFIRM_DELETE(state, payload) {
        state.show_confirm_delete = payload;
    },

    SET_NOMBRE(state, payload) {
        state.nombre = payload;
    },

    SET_APELLIDO(state, payload) {
        state.apellido = payload;
    },

    SET_DOCUMENTO(state, payload) {
        state.documento = payload;
    },

    SET_CUIT(state, payload) {
        state.cuit = payload;
    },

    SET_TIPO(state, payload) {
        state.tipo = payload;
    },

    SET_FECHA_DE_INGRESO(state, payload) {
        state.fechadeingreso = payload;
    },

    SET_CLIENTE(state, payload) {
        state.cliente = payload;
        state.sucursal = null;
    },

    SET_SUCURSAL(state, payload) {
        state.sucursal = payload;
    },

    OPEN_FORM(state, payload) {
        state.show_form = payload;
    },

    OPEN_DETAIL(state, payload) {
        state.show_detail = payload;
    },

    LOAD_FORM(state, payload) {
        state.id = payload._id;
        state.cliente = payload.cliente;
        state.sucursal = payload.sucursal;
        state.nombre = payload.nombre;
        state.apellido = payload.apellido;
        state.documento = payload.documento;
        state.tipo = payload.tipo;
        state.fechadeingreso = payload.fechadeingreso;
        state.cuit = payload.cuit;
    },

    SET_IS_UPDATE_FORM(state, payload) {
        state.is_update_form = payload;
        if (!payload) {
          state.cliente = null;
          state.sucursal = null;
          state.nombre = null;
          state.apellido = null;
          state.documento = null;
          state.tipo = null;
          state.fechadeingreso = null;
        }
    },

    GET_EMPLEADOS_LOADING(state){
        state.getEmpleadosLoading = true;
        state.getEmpleadosSuccess = false;
        state.getEmpleadosError = false;
        state.getEmpleadosErrorMessage = '';
        state.clientes = [];
    },
    GET_EMPLEADOS_SUCCESS(state, empleados) {
        state.getEmpleadosLoading = false;
        state.getEmpleadosSuccess = true;
        state.empleados = empleados;

      empleados.map((empleado, index) => {
        Vue.set(state.empleados, index, { ...empleado });
      });
    },
    GET_EMPLEADOS_ERROR(state, e) {
        state.getEmpleadosLoading = false;
        state.getEmpleadosError = true;
        state.getEmpleadosErrorMessage = e;
    },
    REMOVE_EMPLEADO_SUCCESS(state) {
        state.removeEmpleadoLoading = false;
        state.removeEmpleadoSuccess = true;
    },
    REMOVE_EMPLEADO_LOADING(state){
        state.removeEmpleadoLoading = true;
        state.removeEmpleadoSuccess = false;
        state.removeEmpleadoError = false;
        state.removeEmpleadoErrorMessage = '';
    },

    REMOVE_EMPLEADO_ERROR(state, e) {
        state.removeEmpleadoLoading = false;
        state.removeEmpleadoError = true;
        state.removeEmpleadoErrorMessage = e;
    },
    POST_EMPLEADO_LOADING(state){
        state.postEmpleadoLoading = true;
        state.postEmpleadoSuccess = false;
        state.postEmpleadoError = false;
        state.postEmpleadoErrorMessage = '';
    },
    POST_EMPLEADO_SUCCESS(state) {
        state.postEmpleadoLoading = false;
        state.postEmpleadoSuccess = true;
        Vue.swal({
            title: 'Registrado',
            text: 'empleado registrado',
            type: 'success',
            width: '350px',
            timer: 2000,
            position: 'top-end',
        });

    },
    POST_EMPLEADO_ERROR(state, e) {
        state.postEmpleadoLoading = false;
        state.postEmpleadoError = true;
        state.postEmpleadoErrorMessage = e;
    },
    UPDATE_EMPLEADO_SUCCESS(state) {
        state.updateEmpleadoThemeLoading = false;
        state.updateEmpleadoThemeSuccess = true;
    },
    UPDATE_EMPLEADO_ERROR(state, e) {
        state.updateEmpleadoLoading = false;
        state.updateEmpleadoError = true;
        state.updateEmpleadoErrorMessage = e;
        Vue.swal({
            title: 'Error',
            text: 'algo fallo durante la actualización',
            type: 'error',
            width: '350px',
            timer: 2000,
            position: 'top-end',
        });
    },

};