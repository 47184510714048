<template>
	<div>
		<v-subheader>Lista de asistentes</v-subheader>
		<v-container class="pb-0">
			<v-form ref="form-step-2" v-model="valid" lazy-validation>
				<v-layout row wrap class="px-2">
					<v-flex xs3 class="px-2">
						<v-text-field
							ref="new-item-apellido"
							box
							v-model="new_item.apellido"
							label="Apellido/s"
							:rules="form_rules"
							required
						></v-text-field>
					</v-flex>

					<v-flex xs3 class="px-2">
						<v-text-field
							box
							v-model="new_item.nombre"
							label="Nombre/s"
							:rules="form_rules"
							required
						></v-text-field>
					</v-flex>

					<v-flex xs3 class="px-2">
						<v-text-field
							box
							:rules="number_rules"
							v-model="new_item.documento"
							type="number"
							label="Nº de Documento"
							required
						></v-text-field>
					</v-flex>

					<v-flex xs3 class="px-2">
						<v-layout align-center justify-end row fill-height>
							<v-btn
								color="success"
								depressed
								@keyup.enter="addAttendee()"
								@click="addAttendee()"
							>
								Agregar asistente
							</v-btn>
						</v-layout>
					</v-flex>
				</v-layout>
			</v-form>
		</v-container>

		<v-container>
			<v-data-table
				:headers="headers"
				:items="attendees"
				class="elevation-3"
			>
				<template v-slot:items="attendees">
					<td class="text-xs-center">
						{{ attendees.item.apellido }}
					</td>
					<td class="text-xs-center">{{ attendees.item.nombre }}</td>
					<td class="text-xs-center">
						{{ attendees.item.documento }}
					</td>
					<td class="justify-center layout px-0">
						<v-icon
							small
							@click="removeAttendee(attendees.item.cuit)"
						>
							delete
						</v-icon>
					</td>
				</template>
				<template v-slot:no-results>
					<v-alert :value="true" color="error" icon="warning">
						No se encontraron resultados para "{{ search }}".
					</v-alert>
				</template>
			</v-data-table>
		</v-container>

		<v-container>
			<v-layout row wrap justify-space-between class="px-2">
				<v-flex xs4 class="px-2">
					<v-checkbox
						v-model="add_all_employees"
						@change="addAllEmployees"
						label="¿Incluir a todos los empleados registrados del cliente?"
					>
					</v-checkbox>
				</v-flex>

				<v-flex xs4 class="px-2">
					<v-layout align-center justify-end row fill-height>
						<v-btn color="success" depressed @click="resetTable()">
							vaciar tabla
						</v-btn>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-container>
		<v-divider />

		<v-container>
			<v-layout row wrap class="px-2">
				<v-flex xs3 class="px-2">
					<input
						type="file"
						label="Adjuntar planilla"
						@change="uploadFile"
					/>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
	props: ["form_rules"],

	data() {
		return {
			valid: true,
			add_all_employees: false,
			number_rules: [
				(v) => /[0-9]+/.test(v) || "Este campo solo admite números",
			],
			new_item: {
				nombre: "",
				apellido: "",
				documento: "",
			},
			headers: [
				{
					sortable: true,
					text: "Apellido/s",
					value: "apellido",
					align: "center",
				},
				{
					sortable: true,
					text: "Nombre/s",
					value: "nombre",
					align: "center",
				},
				{
					sortable: true,
					text: "Nº de Documento",
					value: "documento",
					align: "center",
				},
				{
					sortable: true,
					text: "Acciones",
					value: "actions",
					align: "center",
				},
			],
		};
	},

	mounted() {
		this.resetForm();
	},

	computed: {
		...mapState({
			attendees: (state) => state.capacitacion.attendees,
			employees: (state) => state.capacitacion.employees,
		}),

		branchesToSelect() {
			return this.sucursales.map((item) => ({
				item_value: item._id,
				item_text: `${item.cliente.razonSocial} | ${item.localidad.nombre} | ${item.direccion}`,
			}));
		},
	},

	methods: {
		...mapMutations({
			setAttendees: "capacitacion/SET_ATTENDEES",
			setPlanilla: "capacitacion/SET_PLANILLA",
		}),

		setFocus() {
			this.$refs["new-item-apellido"].focus();
		},

		addAttendee() {
			if (this.$refs["form-step-2"].validate()) {
				this.setAttendees([...this.attendees, { ...this.new_item }]);
				this.new_item = {};
				this.resetForm();
			}
		},

		resetForm() {
			this.valid = true;
			this.$refs["form-step-2"].resetValidation();
			this.setFocus();
		},

		removeAttendee(cuit) {
			this.setAttendees(
				this.attendees.filter((item) => item.cuit !== cuit)
			);
			this.setFocus();
		},

		addAllEmployees() {
			if (this.add_all_employees) {
				this.setAttendees(this.employees);
			} else {
				this.resetTable();
			}
		},

		resetTable() {
			this.setAttendees([]);
			this.add_all_employees = false;
		},

		uploadFile(e) {
			this.setPlanilla(e.target.files[0]);
		},
	},
};
</script>
