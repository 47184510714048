<template>
	<v-layout row justify-center>
		<v-dialog v-model="show_form" persistent max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">{{ title_form }}</span>
				</v-card-title>
				<v-card-text>
					<v-form ref="form-orden" v-model="valid" lazy-validation>
						<v-container grid-list-md>
							<v-layout wrap>
								<v-flex xs12>
									<v-autocomplete
										:items="branchesToSelect"
										v-model="sucursal"
										@change="setSucursal"
										label="Sucursal"
										item-text="item_text"
										item-value="item_value"
										:rules="form_rules"
										required
										prepend-icon="store_mall_directory"
									/>
								</v-flex>
								<v-flex xs12>
									<v-autocomplete
										:items="typesToSelect"
										v-model="tipoOrden"
										@change="setTipoOrden"
										label="Tipo de orden"
										item-text="nombre"
										:rules="form_rules"
										required
										return-object
										prepend-icon="check_circle_outline"
									/>
								</v-flex>
								<v-flex xs6>
									<v-menu
										ref="menu_fecha_inicio"
										v-model="menu_fecha_inicio"
										:close-on-content-click="false"
										:nudge-right="40"
										lazy
										transition="scale-transition"
										offset-y
										full-width
										max-width="290px"
										min-width="290px"
									>
										<template v-slot:activator="{ on }">
											<v-text-field
												v-model="
													fecha_inicio_formateada
												"
												label="Fecha inicio"
												:rules="form_rules"
												required
												persistent-hint
												prepend-icon="event"
												@blur="
													fechainicio = parseDate(
														fecha_inicio_formateada
													)
												"
												v-on="on"
											/>
										</template>
										<v-date-picker
											locale="es"
											v-model="fechainicio"
											:min="
												new Date()
													.toISOString()
													.substr(0, 10)
											"
											@change="setFechaInicio"
											no-title
											@input="menu_fecha_inicio = false"
										/>
									</v-menu>
								</v-flex>

								<v-flex xs6>
									<v-menu
										ref="menu_fecha_fin"
										v-model="menu_fecha_fin"
										:close-on-content-click="false"
										:nudge-right="40"
										lazy
										transition="scale-transition"
										offset-y
										full-width
										max-width="290px"
										min-width="290px"
									>
										<template v-slot:activator="{ on }">
											<v-text-field
												v-model="fecha_fin_formateada"
												label="Fecha de fin"
												persistent-hint
												prepend-icon="event"
												@blur="
													fechafin =
														parseDate(
															fecha_fin_formateada
														)
												"
												v-on="on"
											/>
										</template>
										<v-date-picker
											locale="es"
											v-model="fechafin"
											:min="fechainicio"
											no-title
											@change="setFechaFin"
											@input="menu_fecha_fin = false"
										/>
									</v-menu>
								</v-flex>

								<v-flex xs6>
									<v-menu
										ref="menu_fecha_expiracion"
										v-model="menu_fecha_expiracion"
										:close-on-content-click="false"
										:nudge-right="40"
										lazy
										transition="scale-transition"
										offset-y
										full-width
										max-width="290px"
										min-width="290px"
									>
										<template v-slot:activator="{ on }">
											<v-text-field
												v-model="
													fecha_expiracion_formateada
												"
												label="Fecha de expiración"
												persistent-hint
												prepend-icon="event"
												@blur="
													fechavencimiento =
														parseDate(
															fecha_expiracion_formateada
														)
												"
												:rules="form_rules"
												required
												v-on="on"
											/>
										</template>

										<v-date-picker
											locale="es"
											v-model="fechavencimiento"
											:min="fechafin"
											no-title
											@change="setFechaExpiracion"
											@input="
												menu_fecha_expiracion = false
											"
										/>
									</v-menu>
								</v-flex>
							</v-layout>
						</v-container>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" flat @click="closeForm">
						Cerrar
					</v-btn>
					<v-btn color="blue darken-1" flat @click="save"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-layout>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
	data: () => ({
		dialog: false,
		valid: true,
		form_rules: [(v) => !!v || "Este campo es requerido"],
		menu_fecha_inicio: false,
		menu_fecha_fin: false,
		menu_fecha_expiracion: false,
	}),

	mounted() {},

	watch: {
		is_update_form(newState, oldTitle) {
			if (!newState) this.resetForm();
		},
	},

	computed: {
		...mapState({
			tiposOrdenes: (state) => state.tipoOrden.tiposOrdenes,
			sucursales: (state) => state.sucursal.sucursales,
			sucursal: (state) => state.orden.sucursal,
			tipoOrden: (state) => state.orden.tipoOrden,
			fechavencimiento: (state) => state.orden.fechavencimiento,
			fechainicio: (state) => state.orden.fechainicio,
			fechafin: (state) => state.orden.fechafin,
			show_form: (state) => state.orden.show_form,
			is_update_form: (state) => state.orden.is_update_form,
		}),

		title_form() {
			return this.is_update_form
				? "Actualizar registro"
				: "Crear registro";
		},

		fecha_expiracion_formateada() {
			return this.formatDate(this.fechavencimiento);
		},

		fecha_inicio_formateada() {
			return this.formatDate(this.fechainicio);
		},

		fecha_fin_formateada() {
			return this.formatDate(this.fechafin);
		},

		branchesToSelect() {
			return this.sucursales.map((item) => {
				const { cliente, localidad, direccion } = item;
				const item_text = `${
					cliente ? cliente.razonSocial : "Cliente no especificado"
				} | ${
					localidad ? localidad.nombre : "Localidad no especificada"
				} | ${direccion || "Dirección no especificada"}`;

				return {
					item_value: item,
					item_text,
				};
			});
		},

		typesToSelect() {
			return this.tiposOrdenes.filter((item) => !item.capacitacion);
		},
	},

	methods: {
		...mapMutations({
			setTipoOrden: "orden/SET_TIPO_ORDEN",
			setSucursal: "orden/SET_SUCURSAL",
			setFechaInicio: "orden/SET_FECHA_INICIO",
			setFechaFin: "orden/SET_FECHA_FIN",
			setFechaExpiracion: "orden/SET_FECHA_EXPIRACION",
			openForm: "orden/OPEN_FORM",
		}),

		...mapActions({
			getOrdenes: "orden/getOrdenes",
			postOrden: "orden/postOrden",
			updateOrden: "orden/updateOrden",
		}),

		formatDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},

		parseDate(date) {
			if (!date) return null;
			const [month, day, year] = date.split("/");
			return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
		},

		resetForm() {
			this.valid = true;
			this.$refs["form-orden"].resetValidation();
		},

		closeForm() {
			this.resetForm();
			this.openForm(false);
		},

		save() {
			if (this.$refs["form-orden"].validate()) {
				if (this.is_update_form) {
					this.updateOrden();
				} else {
					this.postOrden();
				}
				this.openForm(false);
			}
		},
	},
};
</script>
