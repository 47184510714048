export const defaultState = function() {
    return {

        //Action getTiposOrdenes
        getTiposOrdenesLoading: false,
        getTiposOrdenesSuccess: false,
        getTiposOrdenesError: false,
        getTiposOrdenessErrorMessage: '',
        tiposOrdenes:[],
        //Action postTipoOrden
        postTipoOrdenLoading: false,
        postTipoOrdenSuccess: false,
        postTipoOrdenError: false,
        postTipoOrdenErrorMessage: '',
        //Action updateTipoOrdenTheme
        updateTipoOrdenLoading: false,
        updateTipoOrdenSuccess: false,
        updateTipoOrdenError: false,
        updateTipoOrdenErrorMessage: '',
        //Action removeTipoOrden
        removeTipoOrdenLoading: false,
        removeTipoOrdenSuccess: false,
        removeTipoOrdenError: false,
        removeTipoOrdenErrorMessage: ''
    }
}