import { render, staticRenderFns } from "./DetailTrainig.vue?vue&type=template&id=4c3f38ac&scoped=true&"
import script from "./DetailTrainig.vue?vue&type=script&lang=js&"
export * from "./DetailTrainig.vue?vue&type=script&lang=js&"
import style0 from "./DetailTrainig.vue?vue&type=style&index=0&id=4c3f38ac&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c3f38ac",
  null
  
)

export default component.exports