export const defaultState = function () {
    return {
        //Action getClientes
        getClientesLoading: false,
        getClientesSuccess: false,
        getClientesError: false,
        getClientesErrorMessage: '',
        clientes: [],
        //Action getArchivos
        getArchivosLoading: false,
        getArchivosSuccess: false,
        getArchivosError: false,
        getArchivosErrorMessage: '',
        archivos: [],
        //Action postCliente
        postClienteLoading: false,
        postClienteSuccess: false,
        postClienteError: false,
        postClienteErrorMessage: '',
        //Action updateClienteTheme
        updateClienteLoading: false,
        updateClienteSuccess: false,
        updateClienteError: false,
        updateClienteErrorMessage: '',
        // Action updateClienteStatus
        updateClienteStatusLoading: false,
        updateClienteStatusSuccess: false,
        updateClienteStatusError: false,
        updateClienteStatusErrorMessage: '',
        //Action removeCliente
        removeClienteLoading: false,
        removeClienteSuccess: false,
        removeClienteError: false,
        removeClienteErrorMessage: ''
    }
}