<template>
	<v-container class="fill-height">
		<!--  -->
	</v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
	computed: {
		...mapState({
			user: (state) => state.user
		})
	},
	created() {
		const { user } = this

		if (user.isAdmin || user.tecnico) {
			this.$router.push('/documentaciones/asignar')
		} else if (user.cliente) {
			this.$router.push('/documentaciones/historial')
		}
	}
}
</script>
