<template>
  <v-app id="login">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 class="content-card-form">
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Inicio de Sesión</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    prepend-icon="person"
                    name="login"
                    label="Login"
                    type="text"
                    v-model="email"
                  ></v-text-field>
                  <v-text-field
                    id="password"
                    prepend-icon="lock"
                    name="password"
                    label="Password"
                    :type="showPw"
                    v-model="password"
                  ></v-text-field>
                  <v-checkbox
                    class="checkbox"
                    v-model="checkbox"
                    label= "Mostrar contraseña"
                    @change="$v.checkbox.$touch()"
                  ></v-checkbox>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="submit">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import Router from "../../router";

export default {
  name: "Login",
  checkbox: {
    checked(val) {
      return val;
    },
  },
  props: {
    source: String,
  },
  mounted() {},
  data: () => ({
    showPw: "password",
    email: "",
    password: "",
    checkbox: false,
  }),

  computed: {
    ...mapState({
      login: (state) => state.user.login,
      user: (state) => state.user
    }),
  },

  methods: {
    async submit(e) {
      e.preventDefault();
      await this.$store.dispatch("user/loginUser", {
        email: this.email,
        password: this.password,
      });
      if (login) {
        if (!!this.user.tecnico) {
          Router.push({ name: "DocumentacionTecnico" });
        } else {
          Router.push({ name: "ordenes" });
        }
      }
    },
  },
  watch: {
    checkbox: function () {
      if (!this.checkbox) {
        this.showPw = "password";
        return this.showPw;
      } else {
        this.showPw = "text"
        return this.showPw;
      }
    },
  },
};
</script>

<style>
#login {
  background-image: url("/img/auditoria-background.jpg");
  background-size: cover;
}

.v-input input {
  z-index: 10;
}
</style>
