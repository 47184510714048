import Vue from "vue";

export const mutations = {
  /**
   * Mutations for Form
   */

  RESET(state) {
    state.tipoOrden = null;
    state.tituloCapacitacion = null;
    state.sucursal = null;
    state.numeroorden = null;
    state.horainicio = null;
    state.horafin = null;
    state.ispresencial = false;
    state.fechainicio = null;
    state.fechafin = null;
    state.fechavencimiento = null;
    state.attendees = [];
    state.employees = [];
    state.planilla = null;
    state.capacitacion = null;
  },

  SET_TIPO_ORDEN(state, payload) {
    state.tipoOrden = payload;
  },
  SET_TITULO_CAPACITACION(state, payload) {
    state.tituloCapacitacion = payload;
  },

  SET_SUCURSAL(state, payload) {
    state.sucursal = payload;
  },

  SET_NUMERO_ORDEN(state, payload) {
    state.numeroorden = payload;
  },

  SET_HORA_INICIO(state, payload) {
    state.horainicio = payload;
  },

  SET_HORA_FIN(state, payload) {
    state.horafin = payload;
  },

  SET_IS_PRESENCIAL(state, payload) {
    state.ispresencial = payload;
  },

  SET_FECHA_INICIO(state, payload) {
    state.fechainicio = payload;
  },

  SET_FECHA_FIN(state, payload) {
    state.fechafin = payload;
  },

  SET_FECHA_EXPIRACION(state, payload) {
    state.fechavencimiento = payload;
  },

  SET_PLANILLA(state, payload) {
    state.planilla = payload;
  },

  SET_ATTENDEES(state, payload) {
    state.attendees = payload;
  },

  SET_EMPLOYEES(state, payload) {
    state.employees = payload;
  },

  SET_IS_UPDATE_FORM(state, payload) {
    state.is_update_form = payload;
    if (!payload) {
      state.tipoOrden = null;
      state.capacitacion = null;
      state.sucursal = null;
      state.numeroorden = null;
      state.horainicio = null;
      state.horafin = null;
      state.ispresencial = false;
      state.fechainicio = null;
      state.fechafin = null;
      state.fechavencimiento = null;
      state.attendees = [];
      state.employees = [];
      state.planilla = null;
    }
  },

  OPEN_FORM(state, payload) {
    state.show_form = payload;
  },

  OPEN_DETAIL(state, payload) {
    state.show_detail = payload;
  },

  LOAD_FORM(state, payload) {
    state.id = payload.capacitacion._id;
    state.orden_id = payload._id;
    state.tipoOrden = payload.tipoOrden;
    state.sucursal = payload.sucursal;
    state.horainicio = payload.capacitacion.horainicio;
    state.horafin = payload.capacitacion.horafin;
    state.ispresencial = payload.capacitacion.ispresencial;
    state.attendees = payload.capacitacion.asistentes;
    state.employees = payload.capacitacion.employees;
    state.filename = payload.capacitacion.filename;
    state.linktofile = payload.capacitacion.linktofile;
    state.planilla = payload.capacitacion.planilla;
    state.numeroorden = payload.numeroorden;
    state.fechainicio = payload.fechainicio;
    state.fechafin = payload.fechafin;
    state.fechavencimiento = payload.fechavencimiento;
  },

  /**
   * Mutations for Actions
   */

  GET_LOADING(state) {
    state.getLoading = true;
    state.getSuccess = false;
    state.Error = false;
    state.getErrorMessage = "";
  },

  GET_SUCCESS(state) {
    state.getLoading = false;
    state.getSuccess = true;
  },

  GET_ERROR(state, e) {
    state.Loading = false;
    state.Error = true;
    state.ErrorMessage = e;
  },

  REMOVE_SUCCESS(state) {
    state.removeLoading = false;
    state.removeSuccess = true;
  },

  REMOVE_LOADING(state) {
    state.removeLoading = true;
    state.removeSuccess = false;
    state.removeError = false;
    state.removeErrorMessage = "";
  },

  REMOVE_ERROR(state, e) {
    state.removeLoading = false;
    state.removeError = true;
    state.removeErrorMessage = e;
  },

  POST_LOADING(state) {
    state.postLoading = true;
    state.postSuccess = false;
    state.postError = false;
    state.postErrorMessage = "";
  },

  POST_SUCCESS(state) {
    state.postLoading = false;
    state.postSuccess = true;
    Vue.swal({
      title: "Registrado",
      text: " registrada",
      type: "success",
      width: "350px",
      timer: 2000,
      position: "top-end",
    });
  },

  POST_ERROR(state, e) {
    state.postLoading = false;
    state.postError = true;
    state.postErrorMessage = e;
  },

  UPDATE_SUCCESS(state) {
    state.updateThemeLoading = false;
    state.updateThemeSuccess = true;
  },

  UPDATE_ERROR(state, e) {
    state.updateLoading = false;
    state.updateError = true;
    state.updateErrorMessage = e;
    Vue.swal({
      title: "Error",
      text: "algo fallo durante la actualización",
      type: "error",
      width: "350px",
      timer: 2000,
      position: "top-end",
    });
  },
};
