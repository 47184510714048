import { defaultState } from './config';
import Vue from 'vue';

export const mutations = {
    GET_CATEGORIAS_AFIP_LOADING(state){
        state.getCategoriasAfipLoading = true;
        state.getCategoriasAfipSuccess = false;
        state.getCategoriasAfipError = false;
        state.getCategoriasAfipErrorMessage = '';
        state.categoriasAfip = [];
    },
    GET_CATEGORIAS_AFIP_SUCCESS(state, categoriasAfip) {
        state.getCategoriasAfipLoading = false;
        state.getCategoriasAfipSuccess = true;
        state.categoriasAfip= [];
        categoriasAfip.map((categoriaAfip, index) => {
        Vue.set(state.categoriasAfip, index, { ...categoriaAfip });
      });
    },
    GET_CATEGORIAS_AFIP_ERROR(state, e) {
        state.getCategoriasAfipLoading = false;
        state.getCategoriasAfipError = true;
        state.getCategoriasAfipErrorMessage = e;
    },
};