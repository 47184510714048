export const defaultState = function() {
    return {

        //Action getEmpleados
        getEmpleadosLoading: false,
        getEmpleadosSuccess: false,
        getEmpleadosError: false,
        getEmpleadosErrorMessage: '',
        empleados:[],
        //Action postEmpleado
        postEmpleadoLoading: false,
        postEmpleadoSuccess: false,
        postEmpleadoError: false,
        postEmpleadoErrorMessage: '',
        //Action updateEmpleadoTheme
        updateEmpleadoLoading: false,
        updateEmpleadoSuccess: false,
        updateEmpleadoError: false,
        updateEmpleadoErrorMessage: '',
        //Action removeEmpleado
        removeEmpleadoLoading: false,
        removeEmpleadoSuccess: false,
        removeEmpleadoError: false,
        removeEmpleadoErrorMessage: ''
    }
}