<template>
	<v-layout row justify-center>
		<v-dialog v-model="show_confirm_delete" persistent max-width="500">
			<v-card dark color="red">
				<v-card-title color="white" class="headline">
					Eliminar registro
				</v-card-title>

				<v-card-text color="white">
					Desea marcar al cliente como Inactivo?
				</v-card-text>

				<v-card-actions class="px-0">
					<v-spacer></v-spacer>
					<v-btn
						color="white darken-1"
						flat="flat"
						@click="setShowConfirmDelete(false)"
					>
						Cancelar
					</v-btn>

					<v-btn
						color="white darken-1"
						flat="flat"
						@click="CallActionDeactivateCliente"
					>
						Confirmar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-layout>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
	props: ["cliente_id"],

	data() {
		return {};
	},

	computed: {
		...mapState({
			show_confirm_delete: (state) => state.cliente.show_confirm_delete,
		}),
	},

	methods: {
		...mapActions({
			deactivateCliente: "cliente/deactivateCliente",
		}),

		...mapMutations({
			setShowConfirmDelete: "cliente/SET_SHOW_CONFIRM_DELETE",
		}),

		CallActionDeactivateCliente() {
			this.deactivateCliente(this.cliente_id);
			this.setShowConfirmDelete(false);
		},
	},
};
</script>
