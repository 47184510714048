import Vue from "vue";
import VueAnalytics from "vue-analytics";
import VueRouter from "vue-router";
import VueRouteMiddleware from 'vue-route-middleware';
import Meta from "vue-meta";
import paths from "./paths";

Vue.use(VueRouter);

// Create a new router
const Router = new VueRouter({
  mode: "history",
  routes: paths,
});

Router.beforeEach(VueRouteMiddleware());

Vue.use(Meta);

if (process.env.GOOGLE_ANALYTICS) {
  Vue.use(VueAnalytics, {
    id: process.env.GOOGLE_ANALYTICS,
    Router,
    autoTracking: {
      page: process.env.NODE_ENV !== "development",
    },
  });
}

export default Router;
