<template>
  <v-container>
    
    <v-layout align-center justify-space-between row fill-height class="mt-2 px-2">
      <h3 class="mb-4">Sucursales</h3>
      <v-flex xs3 class="mx-2">
        <v-btn color="primary" block @click="showForm(false)">
          Registrar sucursal
        </v-btn>
      </v-flex>
    </v-layout>

    <!-- Card -->
    <v-card class="my-2">
      <v-card-title>
        <v-layout row wrap class="mb-2 px-2">
          <v-spacer></v-spacer>
          <v-flex xs3 class="px-2">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Filtrar"
              single-line
              hide-details
            />
          </v-flex>
        </v-layout>
      </v-card-title>
      <!-- Table -->
      <v-data-table :headers="headers" :items="sucursales_filter">
        <template v-slot:items="sucursales">
          <td class="text-xs-left font-weight-medium">
            {{ sucursales.item.cliente ? sucursales.item.cliente.nombreFantasia : '-' }}
          </td>
          <td class="text-md-left">
            <b>{{ sucursales.item.direccion }}</b> - {{ sucursales.item.localidad ?  sucursales.item.localidad.nombre : '' }}
          </td>
          <td class="text-xs-center" style="width:5%">
            <b v-if="sucursales.item.cantidad_ordenes > 0" >{{ sucursales.item.cantidad_ordenes }}</b>
            <b v-else>-</b>
          </td>
          <td class="justify-center layout px-0">
            <v-icon
              small
              class="mr-2"
              title="Ver registro completo"
              @click="showDetail(sucursales.item)"
            >
              visibility
            </v-icon>

            <v-icon
              small
              title="Editar registro"
              class="mr-2"
              @click="showForm(sucursales.item)"
            >
              edit
            </v-icon>

            <v-icon
              small
              title="Eliminar registro"
              @click="showConfirmDelete(sucursales.item._id)"
            >
              delete
            </v-icon>

          </td>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
            No se encontraron resultados para "{{ search }}".
          </v-alert>
        </template>
      </v-data-table>
    </v-card>

    <!-- Modals -->

    <sucursal-form />
    <confirm-delete :sucursal_id="sucursal_id" />
    <detail />
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import SucursalForm from "./SucursalForm.vue";
import ConfirmDelete from "./ConfirmDelete.vue";
import Detail from "./Detail.vue";


export default {
  data: () => ({
    sucursal_id: null,
    search: "",
    headers: [
      {
        sortable: true,
        text: "Cliente",
        value: "cliente.nombreFantasia",
        align: "left",
      },
      {
        sortable: true,
        text: "Direccion",
        value: "direccion",
        align: "left",
      },
      {
        sortable: true,
        text: "Cantidad de Solicitudes de Servicio",
        align: "center",
      },
      {
        sortable: false,
        text: "Acciones",
        value: "actions",
        align: "center",
      },

    ],
  }),

  mounted() {
    this.getSucursales();
    this.getClientes();
    this.getLocalidades();
  },

  components: {
    SucursalForm,
    ConfirmDelete,
    Detail,
  },

  computed: {

    ...mapState({
      sucursales: (state) => state.sucursal.sucursales,
    }),

    sucursales_filter() {
      const { sucursales, search } = this
      const valid = search && search.trim().length > 0;

      if (valid) {
        return this.searchArray(sucursales, search)
      }
      return this.sucursales;
    },
  },

  methods: {
    ...mapActions({
      getLocalidades: "localidad/getLocalidades",
      getClientes: "cliente/getClientes",
      getSucursales: "sucursal/getSucursales"
    }),

    ...mapMutations({
      setShowConfirmDelete: "sucursal/SET_SHOW_CONFIRM_DELETE",
      openDetail: "sucursal/OPEN_DETAIL",
      openForm: "sucursal/OPEN_FORM",
      loadForm: "sucursal/LOAD_FORM",
      setIsUpdateForm: "sucursal/SET_IS_UPDATE_FORM",
      resetForm: "sucursal/RESET",

    }),

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },


    showConfirmDelete(id) {
      this.sucursal_id = id;
      this.setShowConfirmDelete(true);
    },

    showForm(sucursal) {
      if (sucursal) this.loadForm(sucursal);
      this.setIsUpdateForm(sucursal);
      this.openForm(true);
    },


    showDetail(sucursal) {
      if (sucursal) {
        this.loadForm(sucursal);
        this.openDetail(true);
      }
    },
    searchArray(array, query) {
			const filteredArray = array.filter((item) => {
				for (let key in item) {
					if (typeof item[key] === 'object') {
						if (this.searchArray([item[key]], query).length > 0) {
							return true
						}
					} else if (
						typeof item[key] === 'string' &&
						item[key].toLowerCase().includes(query.toLowerCase())
					) {
						return true
					}
				}
				return false
			})

			return filteredArray
		}
  },
};
</script>

