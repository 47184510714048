import Vue from "vue";
import ordenApi from "../../../api/ordenApi";

export default {
  getOrdenes({ commit }) {
    return ordenApi
      .getOrdenes()
      .then((response) => {
        const ordenes = response.data;
        commit("GET_ORDENES_SUCCESS", ordenes);
      })
      .catch((e) => {
        commit("GET_ORDENES_ERROR", e);
      });
  },
  getOrdenesByClient({ commit }, clientId) {
    return ordenApi
      .getOrdenesByCliente(clientId)
      .then((response) => {
        const ordenes = response.data;
        commit("GET_ORDENES_SUCCESS", ordenes);
      })
      .catch((e) => {
        commit("GET_ORDENES_ERROR", e);
      });
  },
  async exportOrdenesByClient({ commit }, clientId) {
    return ordenApi
      .exportOrdenesByCliente(clientId)
      .then((response) => {
        return response
      })
      .catch((e) => {
        commit("GET_ORDENES_ERROR", e);
      });
  },

  postOrden({ dispatch, commit, state }) {
    return ordenApi
      .postOrden(state)
      .then((_response) => {
        if (_response.data == "Orden duplicada") {
          commit("POST_ORDEN_DUPLICATED", _response.data);
        } else {
          commit("POST_ORDEN_SUCCESS");
          dispatch("getOrdenes");
        }
      })
      .catch((e) => {
        commit("POST_ORDEN_ERROR", e);
      });
  },

  updateOrden({ commit, dispatch, state }) {
    return ordenApi
      .updateOrden(state)
      .then((_response) => {
        dispatch("getOrdenes");
        commit("UPDATE_ORDEN_SUCCESS");
      })
      .catch((e) => {
        commit("UPDATE_ORDEN_ERROR", e);
      });
  },

  removeOrden({ dispatch, commit }, id) {
    commit("REMOVE_ORDEN_LOADING");
    return ordenApi
      .removeOrden(id)
      .then((_response) => {
        commit("REMOVE_ORDEN_SUCCESS");
        dispatch("getOrdenes");
      })
      .catch((e) => {
        commit("REMOVE_ORDEN_ERROR", e);
      });
  },
};
