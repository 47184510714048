export const defaultState = function() {
    return {

        //Action getOrdenes
        getOrdenesLoading: false,
        getOrdenesSuccess: false,
        getOrdenesError: false,
        getOrdenesErrorMessage: '',
        ordenes:[],
        //Action postOrden
        postOrdenLoading: false,
        postOrdenSuccess: false,
        postOrdenError: false,
        postOrdenErrorMessage: '',
        //Action updateOrdenTheme
        updateOrdenLoading: false,
        updateOrdenSuccess: false,
        updateOrdenError: false,
        updateOrdenErrorMessage: '',
        //Action removeOrden
        removeOrdenLoading: false,
        removeOrdenSuccess: false,
        removeOrdenError: false,
        removeOrdenErrorMessage: ''
    }
}