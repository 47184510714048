import Vue from 'vue';
import userApi from "../../../api/userApi";
import authApi from "../../../api/authApi";
import Cookies from "js-cookie";
import Router from "../../../router";

export default {
  loginUser({ commit }, { email, password }) {
    return authApi
      .login(email, password)
      .then(({ data }) => {
        if (data.success === false) {
          throw new Error('log in error');
        } else {
          Cookies.set("token", data.token, { expires: 1 }); //expira en 1 día
          localStorage.setItem("offline_user_data", JSON.stringify({ ...data.user, email }));
          commit("LOGIN_USER_SUCCESS", { ...data.user, email });
        }
      })
      .catch((e) => {
        commit("LOGIN_USER_ERROR", e);
      });
  },
  loadUser({ commit, state }) {
    /* debugger */
    const payload = JSON.parse(localStorage.getItem("offline_user_data"));
    if (!state.login) {
      if (payload) {
        commit("LOGIN_USER_SUCCESS", payload);
      } else {
        Cookies.remove('token');
        location.reload();
      }
    }
  },
  logoutUser({ commit }) {
    Cookies.remove('token');
    location.reload();
  },

  getUser({ commit }, { id, token }) {
    return userApi
      .getUser(id, token)
      .then((response) => {
        let user = response.data.user;
        commit("GET_USER_SUCCESS", user);
      })
      .catch((e) => {
        commit("GET_USER_ERROR", e);
      });
  },

  updateUser({ commit, dispatch }, { id, password, status, token }) {
    return userApi
      .updateUser(id, password, status, token)
      .then((_response) => {
        dispatch("getUser");
        commit("UPDATE_USER_SUCCESS");
      })
      .catch((e) => {
        commit("UPDATE_USER_ERROR", e);
      });
  },

  removeUser({ dispatch, commit }, { id, token }) {
    commit("REMOVE_USER_LOADING");
    return userApi
      .removeUser(id, token)
      .then((_response) => {
        commit("REMOVE_USER_SUCCESS");
        dispatch("getUser");
      })
      .catch((e) => {
        commit("REMOVE_USER_ERROR", e);
      });
  },

  getAllUsers({ dispatch, commit }) {
    return userApi.getUsers()
      .then(response => {
        const users = response.data;
        commit('GET_USERS_SUCCESS', users);
      })
      .catch(e => {
        commit('GET_USERS_ERROR', e);
      });
  },
  async enableDisableAccount({ dispatch }, { id }) {
    return userApi.enableDisableAccount({ id }).then((res) => {
      dispatch('getAllUsers');
    });
  },
  async validateAccount({ dispatch }, { token, password, id }) {
    return userApi.validateAccount({ token, password, id }).then((res) => {
      Vue.swal({
        title: "Cuenta activada con éxito",
        text: "Intente iniciar sesión",
        type: "success",
        timer: 3000,
      });
    }).catch(e => {
      Vue.swal({
        title: "Error al activar cuenta",
        text: "Intente contactar con el administrador para solucionar el error",
        type: "error",
        timer: 3000,
      });
      throw new Error(e);
    });
  }
};
