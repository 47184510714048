import axios from 'axios';
import router from '../router';
import authApi from './authApi';
import Router from "../router";
import storage, {KEY_AUTH} from '../store/storage';
//import dialog from '../components/dialog';

const api = {

    axiosInstance(baseUrl, timeout, headers, responseType = ''){
        let instance = axios.create({
            baseURL: baseUrl || this.getBaseUrl(),
            timeout: timeout || this.getDefaultTimeout(),
            headers: headers || this.getHeaders(),
            responseType: responseType
        });
        instance.interceptors.response.use(
            response => response,
            error => {
                let originalRequest = error.config;
                if (error.response && error.response.status === 401 && error.response.data && error.response.data.code == 'invalid_token'
                    && !originalRequest._retry) {
                    let auth = storage.getValue(KEY_AUTH);
                    originalRequest._retry = true;
                    return authApi.refreshToken(auth.refresh_token)
                    .then(({data}) => {
                        storage.saveValue(KEY_AUTH, data);
                        originalRequest.headers.Authorization = `Bearer ${data.access_token}`;
                        return axios(originalRequest);
                    })
                    .catch(e => {
                        storage.removeValue(KEY_AUTH);
                        router.push('/login');
                    });
                    //temporal validation of token expiration from SS. It will be replaced when the bluprint auth be implemented
                } else if (error.response && error.response.status === 500 && error.response.data && error.response.data.message === 'Request failed with status code 403') {
                    //dialog.showErrorMessage('Session expired');
                    Router.push({ name: "dashboard" });
                    //router.push('/login');
                }
                return Promise.reject(error);
            }
        );
        return instance;
    },

    getDefaultTimeout(){
        return 60000;
    },

    getBaseUrl(){
        return process.env.VUE_APP_ROOT_API;
    },

    getHeaders(){
        let auth = storage.getValue(KEY_AUTH);
        if(auth && auth.access_token){
            return{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.access_token}`
            }
        }
        return {
            "Content-Type": "application/json"
        };
    },

    getMultipartHeader(){
        let auth = storage.getValue(KEY_AUTH);
        if(auth && auth.access_token){
            return{
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${auth.access_token}`
            }
        }
        return {
            'Content-Type': 'multipart/form-data',
        };
    }

};

export default api;
