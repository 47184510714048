import api from './api';
import Cookies from "js-cookie";

const tipoOrdenApi = {
    getTiposOrdenes() {
        return api
          .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
          .get(`${process.env.VUE_APP_IP}/api/tiposOrden`); //TipoOrden
    },

    postTipoOrden({
        nombre,
        capacitacion,
        compliance,
        inspeccion
    }) {
        return api
            .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
            .post(`${process.env.VUE_APP_IP}/api/tiposOrden`, {
                nombre,
                capacitacion,
                compliance,
                inspeccion
            });
    },

    removeTipoOrden(id) {
        return api
           .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
           .delete(`${process.env.VUE_APP_IP}/api/tiposOrden/${id}`);
    },

    updateTipoOrden({
        id,
        nombre,
        capacitacion,
        inspeccion
    }) {
        return api
            .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
            .put(`${process.env.VUE_APP_IP}/api/tiposOrden/${id}`, {
                nombre,
                capacitacion,
                compliance,
                inspeccion
            });
    },


};

export default tipoOrdenApi;