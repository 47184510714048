import api from "./api";
import Cookies from "js-cookie";

const clienteApi = {
	getClientes() {
		return api
			.axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
			.get(`${process.env.VUE_APP_IP}/api/clientes`); //Cliente
	},
	getClientesDocs() {
		return api
			.axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
			.get(`${process.env.VUE_APP_IP}/api/clientes?filter=docs&doc=true`); //Cliente
	},
	getDocs(_id) {
		return api
			.axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
			.get(`${process.env.VUE_APP_IP}/api/documentacionTecnica/cliente/${_id}`); //Cliente
	},
	postCliente({
		nombreFantasia,
		razonSocial,
		cuit,
		email,
		telefono,
		pais,
		categoriaAfip,
		rubro,
		image,
		active,
	}) {
		let formData = new FormData();
		formData.append("nombreFantasia", nombreFantasia);
		formData.append("razonSocial", razonSocial);
		formData.append("cuit", cuit);
		formData.append("email", email);
		formData.append("telefono", telefono);
		formData.append("pais_id", pais._id);
		formData.append("categoriaAfip_id", categoriaAfip._id);
		formData.append("rubro_id", rubro._id);
		formData.append("image", image);
		formData.append("active", active);

		return api
			.axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
			.post(`${process.env.VUE_APP_IP}/api/clientes`, formData);
	},

	removeCliente(id) {
		return api
			.axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
			.delete(`${process.env.VUE_APP_IP}/api/clientes/${id}`);
	},

	updateCliente({
		id,
		nombreFantasia,
		razonSocial,
		cuit,
		email,
		telefono,
		pais,
		rubro,
		categoriaAfip,
		image,
		filename
	}) {
		let formData = new FormData();
		formData.append("nombreFantasia", nombreFantasia);
		formData.append("razonSocial", razonSocial);
		formData.append("cuit", cuit);
		formData.append("email", email);
		formData.append("telefono", telefono);
		formData.append("pais_id", pais._id);
		formData.append("categoriaAfip_id", categoriaAfip._id);
		formData.append("rubro_id", rubro._id);
		formData.append("image", image);
		formData.append("oldfilename", filename);
		return api
			.axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
			.put(
				`${process.env.VUE_APP_IP}/api/clientes/${id}`,
				formData
			);
	},

	updateClienteStatus({ id, active }) {
		return api
			.axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
			.put(`${process.env.VUE_APP_IP}/api/clientes/${id}`, {
				active,
			});
	},

	deactivateCliente(id) {
		return api
			.axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
			.patch(`${process.env.VUE_APP_IP}/api/clientes/${id}`, {
				active: false,
			});
	},
};

export default clienteApi;