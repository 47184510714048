export const defaultState = function() {
    return {

        //Action getLocalidades
        getLocalidadesLoading: false,
        getLocalidadesSuccess: false,
        getLocalidadesError: false,
        getLocalidadesErrorMessage: '',
        localidades:[],
        //Action postLocalidad
        postLocalidadLoading: false,
        postLocalidadSuccess: false,
        postLocalidadError: false,
        postLocalidadErrorMessage: '',
        //Action updateLocalidadTheme
        updateLocalidadLoading: false,
        updateLocalidadSuccess: false,
        updateLocalidadError: false,
        updateLocalidadErrorMessage: '',
        //Action removeLocalidad
        removeLocalidadLoading: false,
        removeLocalidadSuccess: false,
        removeLocalidadError: false,
        removeLocalidadErrorMessage: ''
    }
}