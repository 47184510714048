<template>
	<v-container>
		<div class="flex">
			<h3 class="mb-4">Ordenes</h3>

			<v-layout
				v-if="user.isAdmin"
				align-center
				justify-end
				row
				fill-height
				class="flex wrap"
			>
				<v-flex v-if="cliente" xs3 class="my-1 mx-1">
					<v-btn color="blue" dark block @click="exportExcel">
						{{
							loading
								? "Exportando Ordenes..."
								: "Exportar Ordenes"
						}}
					</v-btn>
				</v-flex>
				<v-flex xs2 class="my-1 mx-1">
					<v-btn color="primary" block @click="showForm(false)">
						Crear orden
					</v-btn>
				</v-flex>
				<v-flex xs3 class="my-1 mx-1">
					<v-btn
						color="primary"
						block
						@click="redirectToAddTraining(false)"
					>
						Crear capacitacion
					</v-btn>
				</v-flex>
			</v-layout>
		</div>
		<!-- Card -->
		<v-card class="my-2">
			<v-card-title>
				<v-layout row wrap class="mb-2 px-2">
					<v-flex v-if="isFiltering" xs1>
						<v-btn
							fab
							flat
							dark
							small
							color="primary"
							@click="resetFilters"
						>
							<v-icon> restart_alt </v-icon>
						</v-btn>
					</v-flex>
					<v-spacer></v-spacer>
					<v-flex xs3 class="px-2">
						<v-autocomplete
							v-if="user.isAdmin"
							:items="clientes"
							v-model="cliente"
							label="Filtrar por Cliente"
							item-text="nombreFantasia"
							return-object
							required
							single-line
							prepend-icon="mdi-account-outline"
							@change="filter(cliente._id)"
						/>
					</v-flex>
					<v-flex xs3 class="px-2">
						<v-text-field
							v-model="search"
							append-icon="search"
							label="Filtrar"
							single-line
							hide-details
							@input="isFiltering = true"
						/>
					</v-flex>
				</v-layout>
			</v-card-title>
			<!-- Table -->
			<v-data-table
				:headers="headers"
				:items="ordenes_filter"
				:pagination.sync="pagination"
			>
				<template v-slot:items="ordenes">
					<td class="text-xs-center">
						{{ nombreCliente(ordenes.item) }}
					</td>
					<td class="text-xs-center">
						{{ sucursalOrden(ordenes.item) }}
						-
						{{ localidadOrden(ordenes.item) }}
					</td>
					<td class="text-xs-center">
						{{ ordenes.item.numeroorden }}
					</td>
					<td
						v-if="
							ordenes.item.tipoOrden &&
							ordenes.item.tipoOrden.capacitacion
						"
						class="text-xs-center"
					>
						Capacitación:
						<b>"{{ ordenes.item.tipoOrden.nombre }}"</b>
					</td>
					<td v-else class="text-xs-center">
						{{
							ordenes.item.tipoOrden
								? ordenes.item.tipoOrden.nombre
								: "-"
						}}
					</td>
					<td class="text-xs-center">
						{{ formatDate(ordenes.item.fechavencimiento) }}
					</td>
					<td class="text-xs-center">
						{{ formatDate(ordenes.item.fechainicio) }}
					</td>
					<td class="text-xs-center">
						{{ formatDate(ordenes.item.createdAt.split("T")[0]) }}
					</td>
					<td class="justify-center layout px-2">
						<v-icon
							small
							class="mr-2"
							title="Ver registro completo"
							@click="showDetail(ordenes.item)"
						>
							visibility
						</v-icon>
						<v-icon
							v-if="user.isAdmin"
							small
							title="Editar registro"
							class="mr-2"
							@click="showForm(ordenes.item)"
						>
							edit
						</v-icon>
						<v-icon
							v-if="user.isAdmin"
							small
							title="Eliminar registro"
							@click="showConfirmDelete(ordenes.item._id)"
						>
							delete
						</v-icon>
					</td>
				</template>
				<template v-slot:no-results>
					<v-alert :value="true" color="error" icon="warning">
						No se encontraron resultados para "{{ search }}".
					</v-alert>
				</template>
			</v-data-table>
		</v-card>

		<!-- Modals -->
		<orden-form />
		<confirm-delete :orden_id="orden_id" />
		<detail />
		<detail-trainig />
	</v-container>
</template>

<script>
import Vue from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import OrdenForm from "./OrdenForm.vue";
import ConfirmDelete from "./ConfirmDelete.vue";
import Detail from "./Detail.vue";
import DetailTrainig from "./DetailTrainig.vue";
import Router from "../../router";

export default {
	data: () => ({
		isFiltering: false,
		orden_id: null,
		search: "",
		cliente: null,
		loading: false,
		pagination: {
			rowsPerPage: -1, // con -1 mustra "Todos"
		},
		headers: [
			{
				sortable: true,
				text: "Cliente",
				value: "sucursal.cliente.razonSocial",
				align: "center",
			},
			{
				sortable: false,
				text: "Sucursal",
				value: "sucursal.direccion",
				align: "center",
			},
			{
				sortable: true,
				text: "Número",
				value: "numeroorden",
				align: "center",
			},
			{
				sortable: false,
				text: "Tipo de orden",
				value: "tipoOrden.nombre",
				align: "center",
			},
			{
				sortable: true,
				text: "Vencimiento",
				value: "fechavencimiento",
				align: "center",
			},
			{
				sortable: false,
				text: "Inicio",
				value: "fechainicio",
				align: "center",
			},
			{
				sortable: false,
				text: "Alta",
				value: "createdAt",
				align: "center",
			},
			{
				sortable: false,
				text: "Acciones",
				value: "actions",
				align: "center",
			},
		],
	}),

	mounted() {
		this.loadUser();
		this.getSucursales();
		this.getTiposOrdenes();
		this.getClientes();

		if (this.user.cliente) {
			this.getOrdenesByClient(this.user.cliente._id);
		} else {
			this.getOrdenes();
		}
	},

	components: {
		OrdenForm,
		ConfirmDelete,
		Detail,
		DetailTrainig,
	},

	computed: {
		...mapState({
			ordenes: (state) => state.orden.ordenes,
			clientes: (state) => state.cliente.clientes,
			user: (state) => state.user,
		}),

		ordenes_filter() {
			const valid = this.search && this.search.trim().length > 0;
			const { search, ordenes } = this;

			if (valid) {
				return this.searchArray(ordenes, search);
			}
			return ordenes;
		},
	},

	methods: {
		...mapActions({
			getSucursales: "sucursal/getSucursales",
			getOrdenes: "orden/getOrdenes",
			getOrdenesByClient: "orden/getOrdenesByClient",
			exportOrdenesByClient: "orden/exportOrdenesByClient",
			getClientes: "cliente/getClientes",
			getTiposOrdenes: "tipoOrden/getTiposOrdenes",
			getEmpleadosCliente: "capacitacion/getEmpleadosCliente",
			loadUser: "user/loadUser",
		}),

		...mapMutations({
			/**
			 * Ordenes
			 */
			setShowConfirmDelete: "orden/SET_SHOW_CONFIRM_DELETE",
			openDetail: "orden/OPEN_DETAIL",
			openForm: "orden/OPEN_FORM",
			loadForm: "orden/LOAD_FORM",
			setIsUpdateForm: "orden/SET_IS_UPDATE_FORM",
			resetForm: "orden/RESET",
			/**
			 * Capacitaciones
			 */
			loadTrainigForm: "capacitacion/LOAD_FORM",
			setIsTrainigUpdateForm: "capacitacion/SET_IS_UPDATE_FORM",
			openTrainigDetail: "capacitacion/OPEN_DETAIL",
		}),

		formatDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},

		redirectToAddTraining(orden) {
			if (orden) {
				this.loadTrainigForm(orden);
				this.getEmpleadosCliente(orden.sucursal.cliente_id);
			}
			this.setIsTrainigUpdateForm(orden);
			Router.push({ name: "training" });
		},

		showConfirmDelete(id) {
			this.orden_id = id;
			this.setShowConfirmDelete(true);
		},

		showForm(orden) {
			if (orden?.capacitacion) {
				this.redirectToAddTraining(orden);
			} else {
				if (orden) this.loadForm(orden);
				this.setIsUpdateForm(orden);
				this.openForm(true);
			}
		},

		showDetail(orden) {
			if (orden.capacitacion) {
				this.loadTrainigForm(orden);
				this.openDetail(false);
				this.openTrainigDetail(true);
			} else {
				this.loadForm(orden);
				this.openTrainigDetail(false);
				this.openDetail(true);
			}
		},
		async exportExcel() {
			try {
				this.loading = true;
				const response = await this.exportOrdenesByClient(
					this.cliente._id
				);

				const url = window.URL.createObjectURL(
					new Blob([response.data])
				);
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "ordenes.xlsx"); // Nombre del archivo
				document.body.appendChild(link);
				link.click();
			} catch (error) {
				Vue.swal({
					type: "error",
					title: "Error al exportar ordenes.",
				});
			} finally {
				this.loading = false;
			}
		},
		nombreCliente(orden) {
			if (orden.sucursal) {
				if (orden.sucursal.cliente) {
					return orden.sucursal.cliente.razonSocial;
				} else if (orden.cliente) {
					return orden.cliente.razonSocial;
				} else {
					return "-";
				}
			} else {
				return "-";
			}
		},
		sucursalOrden(orden) {
			return orden.sucursal ? orden.sucursal.direccion : "";
		},
		localidadOrden(orden) {
			if (orden.sucursal) {
				const { localidad } = orden.sucursal;
				return localidad ? localidad.nombre : "";
			} else {
				if (orden.localidad) {
					return orden.localidad.nombre;
				} else {
					return "";
				}
			}
		},
		filter(clientId) {
			this.isFiltering = true;
			this.getOrdenesByClient(clientId);
		},
		resetFilters() {
			this.isFiltering = false;
			this.cliente = null;
			this.search = "";
			this.getOrdenes();
		},
		searchArray(array, query) {
			const filteredArray = array.filter((item) => {
				for (let key in item) {
					if (typeof item[key] === "object") {
						if (this.searchArray([item[key]], query).length > 0) {
							return true;
						}
					} else if (
						typeof item[key] === "string" &&
						item[key].toLowerCase().includes(query.toLowerCase())
					) {
						return true;
					}
				}
				return false;
			});

			return filteredArray;
		},
	},
};
</script>
<style scoped>
.v-sheet tr th:nth-child(4),
.v-sheet tr td:nth-child(4) {
	max-width: 100px !important;
}
</style>
