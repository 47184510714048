import sucursalApi from "../../../api/sucursalApi";

export default {

  getSucursales({ commit }) {
    return sucursalApi
      .getSucursales()
      .then((response) => {
        let sucursales = response.data;
        commit("GET_SUCURSALES_SUCCESS", { sucursales });
      })
      .catch((e) => {
        commit("GET_SUCURSALES_ERROR", e);
      });
  },

  postSucursal({ dispatch, commit, state }) {
    return sucursalApi
      .postSucursal(state)
      .then((_response) => {
        commit("POST_SUCURSAL_SUCCESS");
        dispatch("getSucursales");
      })
      .catch((e) => {
        commit("POST_SUCURSAL_ERROR", e);
      });
  },

  removeSucursal({ dispatch, commit }, id) {
    commit("REMOVE_SUCURSAL_LOADING");
    return sucursalApi
      .removeSucursal(id)
      .then((_response) => {
        commit("REMOVE_SUCURSAL_SUCCESS");
        dispatch("getSucursales");
      })
      .catch((e) => {
        commit("REMOVE_SUCURSAL_ERROR", e);
      });
  },

  updateSucursal({ commit, dispatch, state }) {
    return sucursalApi
      .updateSucursal(state)
      .then((_response) => {
        dispatch("getSucursales");
        commit("UPDATE_SUCURSAL_SUCCESS");
      })
      .catch((e) => {
        commit("UPDATE_SUCURSAL_ERROR", e);
      });
  },

};
