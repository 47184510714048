export const defaultState = function () {
  return {
    //Action getUser
    getUserLoading: false,
    getUserSuccess: false,
    getUserError: false,
    getUserErrorMessage: "",
    user: {},
    login: false,
    //Action updateUserTheme
    updateUserLoading: false,
    updateUserSuccess: false,
    updateUserError: false,
    updateUserErrorMessage: "",
    //Action removeUser
    removeUserLoading: false,
    removeUserSuccess: false,
    removeUserError: false,
    removeUserErrorMessage: "",
  };
};
