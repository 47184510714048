import authApi from "../../api/authApi";
import Cookies from "js-cookie";

export default (to, from, next) => {
  authApi
    .verifyUser(to.params.id)
    .then(({ data }) => {
      if (data.token === "") next({ name: "Login" });

      const inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000); //para que expire en 15 minutos
    
      Cookies.set("token", data.token, {
        expires: inFifteenMinutes,
      });

      next();
    })
    .catch((e) => {
      console.log(e);
    });
};
