<template>
  <v-layout row justify-center>
    <v-dialog v-model="show_detail" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Detalle del registro</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <!-- Apellido -->
                <v-list-tile avatar>
                  <v-list-tile-action>
                    <v-icon>mdi-account-outline</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title> Apellido/s: </v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ apellido }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-flex>
              <v-flex xs12>
                <!-- Nombre -->
                <v-list-tile avatar>
                  <v-list-tile-action>
                    <v-icon>mdi-account-outline</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title> Nombre/s: </v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ nombre }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-flex>
              <v-flex xs6>
                <!-- Fecha de Ingreso -->
                <v-list-tile avatar>
                  <v-list-tile-action>
                    <v-icon>mdi-calendar</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Fecha de Ingreso:</v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ fechadeingreso }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-flex>
              <v-flex xs6>
                <!-- Tipo -->
                <v-list-tile avatar>
                  <v-list-tile-action>
                    <v-icon>mdi-briefcase</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Puesto de Trabajo:</v-list-tile-title>
                    <v-list-tile-sub-title v-if="tipo">
                      {{ tipo }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title v-else>
                      *Rol no registrado
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-flex>
              <v-flex xs8>
                <!-- Cliente -->
                <v-list-tile avatar>
                  <v-list-tile-action>
                    <v-icon>mdi-contacts</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title> Cliente </v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ cliente ? cliente.razonSocial : '-' }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-flex>
              <v-flex xs8>
                <!-- Cliente -->
                <v-list-tile avatar>
                  <v-list-tile-action>
                    <v-icon>house</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title> Sucursal </v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ sucursal ? sucursal.direccion : '-' }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat @click="openDetail(false)">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  data: () => ({}),

  methods: {
    ...mapMutations({
      openDetail: "empleado/OPEN_DETAIL",
    }),

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },

  computed: {
    ...mapState({
      cliente: (state) => state.empleado.cliente,
      sucursales: (state) => state.sucursal.sucursales,
      nombre: (state) => state.empleado.nombre,
      apellido: (state) => state.empleado.apellido,
      tipo: (state) => state.empleado.tipo,
      sucursal: (state) => state.empleado.sucursal,
      fechadeingreso: (state) => state.empleado.fechadeingreso,
      show_detail: (state) => state.empleado.show_detail,
    }),
  },
};
</script>
