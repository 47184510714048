<template>
	<v-container>
		<h3 class="mb-4">Usuarios</h3>

		<v-card v-if="is_admin" class="my-2">
			<v-card-title>
				<v-layout row wrap class="mb-2 px-2">
					<v-spacer></v-spacer>
					<v-flex xs3 class="px-2">
						<v-text-field
							v-model="search"
							append-icon="search"
							label="Filtrar"
							single-line
							hide-details
						/>
					</v-flex>
				</v-layout>
			</v-card-title>

			<v-data-table
				:headers="headers"
				:items="users_results"
				:pagination.sync="pagination"
			>
				<template v-slot:items="users">
					<td class="text-xs-center">{{ users.item.email }}</td>
					<td
						:class="[
							'text-xs-center',
							colorStatus(users.item.active)
						]"
					>
						<b>{{ formatStatus(users.item.active) }}</b>
					</td>
					<td class="text-xs-center">
						{{ users.item.createdAt ? formatDate(users.item.createdAt.split('T')[0]) : '-' }}
					</td>
					<td class="text-xs-center">
						<v-btn
							v-if="users.item.active"
							fab
							flat
							small
							color="red"
							dark
							@click="enableDisable(users.item._id)"
						>
							<v-icon small title="Deshabilitar Usuario">
								disabled_by_default
							</v-icon>
						</v-btn>
						<v-btn
							v-else
							fab
							flat
							small
							color="green"
							dark
							@click="enableDisable(users.item._id)"
							><v-icon small title="Habilitar Usuario">
								check
							</v-icon></v-btn
						>
					</td>
				</template>
			</v-data-table>
		</v-card>

		<v-card v-else class="my-2">
			<v-alert :value="true" type="error" outline>
				Usted no tiene acceso a esta funcionalidad.
			</v-alert>
		</v-card>
	</v-container>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'

export default {
	data: () => ({
		search: '',
		headers: [
			{
				sortable: true,
				text: 'E-mail',
				value: 'email',
				align: 'center'
			},
			{
				sortable: true,
				text: 'Estado',
				value: 'status',
				align: 'center'
			},
			{
				sortable: true,
				text: 'Fecha de alta',
				value: 'createdAt',
				align: 'center'
			},
			{
				sortable: true,
				text: 'Acciones',
				value: 'actions',
				align: 'center'
			}
		],
		pagination: {
			rowsPerPage: -1 // con -1 mustra "Todos"
		}
	}),
	mounted() {
		this.getUsers()
	},
	computed: {
		...mapState({
			user: (state) => state.user,
			users: (state) => state.user.users
		}),
		users_results() {
			const valid = this.search && this.search.trim().length > 0

			if (valid) {
				return this.users.filter((user) =>
					user.email.toLowerCase().includes(this.search.toLowerCase())
				)
			}

			return this.users
		},
		is_admin() {
			return this.user.isAdmin
		}
	},
	methods: {
		...mapActions({
			getUsers: 'user/getAllUsers',
			enableDisableAccount: 'user/enableDisableAccount'
		}),
		formatDate(date) {
			if (!date) return null
			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
		formatStatus(status) {
			if (status) {
				return 'Habilitado'
			} else {
				return 'Deshabilitado'
			}
		},
		colorStatus(status) {
			if (status) {
				return 'green--text'
			} else {
				return 'red--text'
			}
		},
		async enableDisable(id) {
			try {
				await this.enableDisableAccount({ id })
				Vue.swal({
					type: 'success',
					title: 'Cambio realizado con éxito.'
				})
			} catch (error) {
				Vue.swal({
					type: 'error',
					title: 'Error al habilitar/deshabilitar cuenta.'
				})
			}
		}
	}
}
</script>
