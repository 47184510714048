var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.show_form),callback:function ($$v) {_vm.show_form=$$v},expression:"show_form"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.title_form))])]),_c('v-card-text',[_c('v-form',{ref:"form-orden",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-autocomplete',{attrs:{"items":_vm.branchesToSelect,"label":"Sucursal","item-text":"item_text","item-value":"item_value","rules":_vm.form_rules,"required":"","prepend-icon":"store_mall_directory"},on:{"change":_vm.setSucursal},model:{value:(_vm.sucursal),callback:function ($$v) {_vm.sucursal=$$v},expression:"sucursal"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-autocomplete',{attrs:{"items":_vm.typesToSelect,"label":"Tipo de orden","item-text":"nombre","rules":_vm.form_rules,"required":"","return-object":"","prepend-icon":"check_circle_outline"},on:{"change":_vm.setTipoOrden},model:{value:(_vm.tipoOrden),callback:function ($$v) {_vm.tipoOrden=$$v},expression:"tipoOrden"}})],1),_c('v-flex',{attrs:{"xs6":""}},[_c('v-menu',{ref:"menu_fecha_inicio",attrs:{"close-on-content-click":false,"nudge-right":40,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({attrs:{"label":"Fecha inicio","rules":_vm.form_rules,"required":"","persistent-hint":"","prepend-icon":"event"},on:{"blur":function($event){_vm.fechainicio = _vm.parseDate(
													_vm.fecha_inicio_formateada
												)}},model:{value:(
												_vm.fecha_inicio_formateada
											),callback:function ($$v) {
												_vm.fecha_inicio_formateada
											=$$v},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\tfecha_inicio_formateada\n\t\t\t\t\t\t\t\t\t\t\t"}},on))]}}]),model:{value:(_vm.menu_fecha_inicio),callback:function ($$v) {_vm.menu_fecha_inicio=$$v},expression:"menu_fecha_inicio"}},[_c('v-date-picker',{attrs:{"locale":"es","min":new Date()
												.toISOString()
												.substr(0, 10),"no-title":""},on:{"change":_vm.setFechaInicio,"input":function($event){_vm.menu_fecha_inicio = false}},model:{value:(_vm.fechainicio),callback:function ($$v) {_vm.fechainicio=$$v},expression:"fechainicio"}})],1)],1),_c('v-flex',{attrs:{"xs6":""}},[_c('v-menu',{ref:"menu_fecha_fin",attrs:{"close-on-content-click":false,"nudge-right":40,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({attrs:{"label":"Fecha de fin","persistent-hint":"","prepend-icon":"event"},on:{"blur":function($event){_vm.fechafin =
													_vm.parseDate(
														_vm.fecha_fin_formateada
													)}},model:{value:(_vm.fecha_fin_formateada),callback:function ($$v) {_vm.fecha_fin_formateada=$$v},expression:"fecha_fin_formateada"}},on))]}}]),model:{value:(_vm.menu_fecha_fin),callback:function ($$v) {_vm.menu_fecha_fin=$$v},expression:"menu_fecha_fin"}},[_c('v-date-picker',{attrs:{"locale":"es","min":_vm.fechainicio,"no-title":""},on:{"change":_vm.setFechaFin,"input":function($event){_vm.menu_fecha_fin = false}},model:{value:(_vm.fechafin),callback:function ($$v) {_vm.fechafin=$$v},expression:"fechafin"}})],1)],1),_c('v-flex',{attrs:{"xs6":""}},[_c('v-menu',{ref:"menu_fecha_expiracion",attrs:{"close-on-content-click":false,"nudge-right":40,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({attrs:{"label":"Fecha de expiración","persistent-hint":"","prepend-icon":"event","rules":_vm.form_rules,"required":""},on:{"blur":function($event){_vm.fechavencimiento =
													_vm.parseDate(
														_vm.fecha_expiracion_formateada
													)}},model:{value:(
												_vm.fecha_expiracion_formateada
											),callback:function ($$v) {
												_vm.fecha_expiracion_formateada
											=$$v},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\tfecha_expiracion_formateada\n\t\t\t\t\t\t\t\t\t\t\t"}},on))]}}]),model:{value:(_vm.menu_fecha_expiracion),callback:function ($$v) {_vm.menu_fecha_expiracion=$$v},expression:"menu_fecha_expiracion"}},[_c('v-date-picker',{attrs:{"locale":"es","min":_vm.fechafin,"no-title":""},on:{"change":_vm.setFechaExpiracion,"input":function($event){_vm.menu_fecha_expiracion = false}},model:{value:(_vm.fechavencimiento),callback:function ($$v) {_vm.fechavencimiento=$$v},expression:"fechavencimiento"}})],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","flat":""},on:{"click":_vm.closeForm}},[_vm._v(" Cerrar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","flat":""},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }