<template>
	<div>
		<v-subheader>Datos generales</v-subheader>
		<v-container>
			<v-form ref="form-step-1" v-model="valid" lazy-validation>
				<v-layout row wrap class="mb-2 px-2">
					<v-flex xs3 class="px-2">
						<v-select
							:items="branchesToSelect"
							v-model="sucursal"
							@change="getEmployees"
							:autocomplete="true"
							label="Sucursal"
							item-text="item_text"
							item-value="item_value"
							:rules="form_rules"
							required
							prepend-icon="store_mall_directory"
						/>
					</v-flex>
					<v-flex xs3 class="px-2">
						<v-select
							:items="typesToSelect"
							v-model="tipoOrden"
							@change="setTipoOrden"
							:autocomplete="true"
							label="Capacitación"
							item-text="nombre"
							:rules="form_rules"
							required
							return-object
							prepend-icon="school"
						/>
					</v-flex>
					<v-flex xs3 class="px-2">
						<v-text-field
							label="Titulo de la capacitación"
							v-model="tituloCapacitacion"
							@change="setTituloCapacitacion"
							required
							:rules="form_rules"
              prepend-icon="edit"
						/>
					</v-flex>
					<v-flex xs3 class="px-2">
						<v-checkbox
							v-model="ispresencial"
							@change="setIsPresencial"
							label="Actividad presencial"
						>
						</v-checkbox>
					</v-flex>
				</v-layout>
				<v-layout row wrap class="mb-2 px-2">
					<v-flex xs3 class="px-2">
						<v-menu
							ref="menu_fecha_inicio"
							v-model="menu_fecha_inicio"
							:close-on-content-click="false"
							:nudge-right="40"
							lazy
							transition="scale-transition"
							offset-y
							full-width
							max-width="290px"
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									v-model="fecha_inicio_formateada"
									label="Fecha inicio"
									:rules="form_rules"
									required
									persistent-hint
									prepend-icon="event"
									@blur="
										fechainicio = parseDate(
											fecha_inicio_formateada
										)
									"
									v-on="on"
								/>
							</template>
							<v-date-picker
								locale="es"
								v-model="fechainicio"
								@change="setFechaInicio"
								no-title
								@input="menu_fecha_inicio = false"
							/>
						</v-menu>
					</v-flex>

					<v-flex xs3 class="px-2">
						<v-text-field
							v-model="horainicio"
							type="time"
							label="Hora Inicio"
							:rules="form_rules"
							@change="setHoraInicio"
							required
						></v-text-field>
					</v-flex>

					<v-flex xs3 class="px-2">
						<v-menu
							ref="menu_fecha_fin"
							v-model="menu_fecha_fin"
							:close-on-content-click="false"
							:nudge-right="40"
							lazy
							transition="scale-transition"
							offset-y
							full-width
							max-width="290px"
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									v-model="fecha_fin_formateada"
									label="Fecha fin"
									persistent-hint
									prepend-icon="event"
									@blur="
										fechafin =
											parseDate(fecha_fin_formateada)
									"
									v-on="on"
								/>
							</template>
							<v-date-picker
								locale="es"
								v-model="fechafin"
								@change="setFechaFin"
								no-title
								@input="menu_fecha_fin = false"
							/>
						</v-menu>
					</v-flex>

					<v-flex xs3 class="px-2">
						<v-text-field
							v-model="horafin"
							type="time"
							label="Hora Fin"
							@change="setHoraFin"
							:rules="form_rules"
							required
						></v-text-field>
					</v-flex>
				</v-layout>

				<v-layout row wrap class="mb-2 px-2">
					<v-flex xs3 class="px-2">
						<v-menu
							ref="menu_fecha_expiracion"
							v-model="menu_fecha_expiracion"
							:close-on-content-click="false"
							:nudge-right="40"
							lazy
							transition="scale-transition"
							offset-y
							full-width
							max-width="290px"
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									v-model="fecha_expiracion_formateada"
									label="Fecha de expiración"
									persistent-hint
									prepend-icon="event"
									@blur="
										fechavencimiento = parseDate(
											fecha_expiracion_formateada
										)
									"
									:rules="form_rules"
									required
									v-on="on"
								/>
							</template>

							<v-date-picker
								locale="es"
								v-model="fechavencimiento"
								no-title
								@change="setFechaExpiracion"
								@input="menu_fecha_expiracion = false"
							/>
						</v-menu>
					</v-flex>
				</v-layout>
			</v-form>
		</v-container>
	</div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
	props: ['form_rules'],
	data() {
		return {
			valid: true,
			menu_fecha_inicio: false,
			menu_fecha_fin: false,
			menu_fecha_expiracion: false
		}
	},

	mounted() {
		this.getSucursales()
		this.getTiposOrden()
	},

	computed: {
		...mapState({
			tiposOrden: (state) => state.tipoOrden.tiposOrdenes,
			tituloCapacitacion: (state) => state.capacitacion.tituloCapacitacion,
			sucursales: (state) => state.sucursal.sucursales,
			sucursal: (state) => state.capacitacion.sucursal,
			tipoOrden: (state) => state.capacitacion.tipoOrden,
			ispresencial: (state) => state.capacitacion.ispresencial,
			horainicio: (state) => state.capacitacion.horainicio,
			horafin: (state) => state.capacitacion.horafin,
			fechavencimiento: (state) => state.capacitacion.fechavencimiento,
			fechainicio: (state) => state.capacitacion.fechainicio,
			fechafin: (state) => state.capacitacion.fechafin
		}),

		fecha_expiracion_formateada() {
			return this.formatDate(this.fechavencimiento)
		},

		fecha_fin_formateada() {
			return this.formatDate(this.fechafin)
		},

		fecha_inicio_formateada() {
			return this.formatDate(this.fechainicio)
		},

		branchesToSelect() {
			return this.sucursales.map((item) => ({
				item_value: item,
				item_text: `${item.cliente.razonSocial} | ${item.localidad.nombre} | ${item.direccion}`
			}))
		},

		typesToSelect() {
			return this.tiposOrden.filter((item) => item.capacitacion)
		}
	},

	methods: {
		...mapActions({
			getSucursales: 'sucursal/getSucursales',
			getEmpleadosCliente: 'capacitacion/getEmpleadosCliente',
			getTiposOrden: 'tipoOrden/getTiposOrden'
		}),

		...mapMutations({
			setTipoOrden: 'capacitacion/SET_TIPO_ORDEN',
			setTituloCapacitacion: 'capacitacion/SET_TITULO_CAPACITACION',
			setSucursal: 'capacitacion/SET_SUCURSAL',
			setHoraInicio: 'capacitacion/SET_HORA_INICIO',
			setHoraFin: 'capacitacion/SET_HORA_FIN',
			setIsPresencial: 'capacitacion/SET_IS_PRESENCIAL',
			setFechaInicio: 'capacitacion/SET_FECHA_INICIO',
			setFechaFin: 'capacitacion/SET_FECHA_FIN',
			setFechaExpiracion: 'capacitacion/SET_FECHA_EXPIRACION'
		}),

		formatDate(date) {
			if (!date) return null

			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},

		parseDate(date) {
			if (!date) return null

			const [month, day, year] = date.split('/')
			return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
		},

		getEmployees(sucursal) {
			this.setSucursal(sucursal)
			this.getEmpleadosCliente(sucursal.cliente_id)
		}
	}
}
</script>
