<template>
  <v-layout row justify-center>
    <v-dialog v-model="show_form" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ title_form }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form-sucursal" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex>
                  <v-autocomplete
                    :items="clientes"
                    v-model="cliente"
                    @change="setCliente"
                    label="Clientes"
                    item-text="nombreFantasia"
                    :rules="form_rules"
                    return-object
                    outline
                    required
                    prepend-icon="contacts"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12>
                  <v-autocomplete
                    :items="localidades"
                    v-model="localidad"
                    @change="setLocalidad"
                    label="Localidades"
                    item-text="nombre"
                    :rules="form_rules"
                    return-object
                    outline
                    required
                    prepend-icon="mdi-map-marker-radius"
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs12>
                  <v-text-field
                    label="Direccion"
                    v-model="direccion"
                    @change="setDireccion"
                    required
                    :rules="form_rules"
                    prepend-icon="mdi-home-map-marker"
                  ></v-text-field>
                </v-flex>
                <!--
                -->
                <v-flex xs12>
                  <v-text-field
                    label="Teléfono"
                    v-model="telefono"
                    @change="setTelefono"
                    required
                    :rules="form_rules"
                    prepend-icon="phone"
                  ></v-text-field>
                </v-flex>
                <!--
                -->
                <v-flex xs12>
                  <v-text-field
                    label="Email"
                    v-model="email"
                    @change="setEmail"
                    required
                    type="email"
                    prepend-icon="mdi-email"
                  ></v-text-field>
                </v-flex>
                <!--  -->
                <v-flex xs12>
                  <v-text-field
                    label="Email secundario"
                    v-model="secondEmail"
                    @change="setSecondEmail"
                    type="email"
                    prepend-icon="mdi-email"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat @click="closeForm"> Cerrar </v-btn>
          <v-btn color="blue darken-1" flat @click="save">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data: () => ({
    dialog: false,
    valid: true,
    form_rules: [(v) => !!v || "Este campo es requerido"],
  }),

  mounted() {},

  watch: {
    is_update_form(newState, oldTitle) {
      if (!newState) this.resetForm();
    },
  },

  computed: {
    ...mapState({
      clientes: (state) => state.cliente.clientes,
      cliente: (state) => state.sucursal.cliente,
      localidades: (state) => state.localidad.localidades,
      localidad: (state) => state.sucursal.localidad,
      direccion: (state) => state.sucursal.direccion,
      telefono: (state) => state.sucursal.telefono,
      email: (state) => state.sucursal.email,
      secondEmail: (state) => state.sucursal.secondEmail,
      show_form: (state) => state.sucursal.show_form,
      is_update_form: (state) => state.sucursal.is_update_form,
    }),

    title_form() {
      return this.is_update_form ? "Actualizar sucursal" : "Crear sucursal";
    },

  },

  methods: {
    ...mapMutations({
      setCliente: "sucursal/SET_CLIENTE",
      setLocalidad: "sucursal/SET_LOCALIDAD",
      setDireccion: "sucursal/SET_DIRECCION",
      setTelefono: "sucursal/SET_TELEFONO",
      setEmail: "sucursal/SET_EMAIL",
      setSecondEmail: "sucursal/SET_SECONDEMAIL",
      openForm: "sucursal/OPEN_FORM",
      resetSucursal: "sucursal/RESET",
    }),

    ...mapActions({
      postSucursal: "sucursal/postSucursal",
      updateSucursal: "sucursal/updateSucursal",
    }),

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    resetForm() {
      this.resetSucursal()
      this.valid = true;
    },

    closeForm() {
      this.resetForm();
      this.$refs["form-sucursal"].reset();
      this.openForm(false);
    },

    save() {
      if (this.$refs["form-sucursal"].validate()) {
        if (this.is_update_form) {
          this.updateSucursal();
        } else {
          this.postSucursal();
        }
        this.closeForm();
      }
    },
  },
};
</script>

