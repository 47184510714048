import api from "./api";
import Cookies from "js-cookie";

const docsApi = {
  uploadDoc({ nombre, etiqueta, documento }) {
    const url = `${process.env.VUE_APP_IP}/api/documentacion/`;

    const formData = new FormData();
    formData.append('nombre', nombre);
    formData.append('etiqueta', etiqueta);
    formData.append('documento', documento, documento.name);

    return api
      .axiosInstance(null, null, {
        "x-access-token": Cookies.get("token"),
        "Content-Type": 'multipart/form-data'
      }).post(url, formData);
  },
  getDocs() {
    const url = `${process.env.VUE_APP_IP}/api/documentacion/`;

    return api
      .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
      .get(url);
  },
  assignDoc({ cliente_id, documento_id, tecnico_id, file, oldfilename }) {
    const url = `${process.env.VUE_APP_IP}/api/documentacionTecnica`;

    const formData = new FormData();
    formData.append('cliente_id', cliente_id);
    formData.append('documento_id', documento_id);
    formData.append('tecnico_id', tecnico_id);
    formData.append('documentoTecnico', file, file.name);

    return api
      .axiosInstance(null, null, {
        "x-access-token": Cookies.get("token"),
        "Content-Type": 'multipart/form-data'
      }).post(url, formData);
  },
  getDocsByCliente({ id }) {
    const url = `${process.env.VUE_APP_IP}/api/documentacionTecnica/cliente/${id}`;

    return api
      .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
      .get(url);
  },
  removeDoc({ id }) {
    const url = `${process.env.VUE_APP_IP}/api/documentacion/${id}`;

    return api.axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
      .delete(url);
  }
};

export default docsApi;