<template>
	<v-layout row justify-center>
		<v-dialog v-model="show_detail" persistent max-width="1000px">
			<v-card>
				<v-card-title>
					<span class="headline">Detalle del registro</span>
				</v-card-title>
				<v-card-text>
					<v-container grid-list-md>
						<template>
							<v-container grid-list-md text-xs-center>
								<v-list two-line subheader>
									<v-subheader>Datos generales</v-subheader>
									<v-layout row wrap>
										<!-- 1º Columna -->
										<v-flex xs12>
											<!-- Sucursal -->
											<v-list-tile avatar>
												<v-list-tile-action>
													<v-icon
														>store_mall_directory</v-icon
													>
												</v-list-tile-action>
												<v-list-tile-content>
													<v-list-tile-title>
														Cliente/Sucursal:
													</v-list-tile-title>
													<v-list-tile-sub-title>
														{{
															sucursal_formateada
														}}
													</v-list-tile-sub-title>
												</v-list-tile-content>
											</v-list-tile>
										</v-flex>
										<v-flex xs12>
											<!-- Fecha y hora de inicio -->
											<v-list-tile avatar>
												<v-list-tile-action>
													<v-icon>event</v-icon>
												</v-list-tile-action>
												<v-list-tile-content>
													<v-list-tile-title>
														Fecha y hora de inicio:
													</v-list-tile-title>
													<v-list-tile-sub-title>
														{{
															fecha_inicio_formateada
														}}
													</v-list-tile-sub-title>
												</v-list-tile-content>
											</v-list-tile>
										</v-flex>
										<v-flex xs12>
											<!-- Planilla -->
											<v-list-tile avatar>
												<v-list-tile-action>
													<v-icon>attachment</v-icon>
												</v-list-tile-action>
												<v-list-tile-content>
													<v-list-tile-title>
														¿Se adjuntó algún
														archivo?
													</v-list-tile-title>
													<v-list-tile-sub-title>
														<v-btn
															v-if="planilla"
															depressed
															small
															style="
																font-size: smaller;
															"
															@click="
																downloadUrl()
															"
														>
															<v-icon
																style="
																	font-size: small;
																"
																>download</v-icon
															>&nbsp;descargar
														</v-btn>
														<span v-else>
															No se ha adjuntado
															ningún archivo"
														</span>
													</v-list-tile-sub-title>
												</v-list-tile-content>
											</v-list-tile>
										</v-flex>
										<!-- 2º Columna -->
										<v-flex xs12>
											<!-- Tipo orden -->
											<v-list-tile avatar>
												<v-list-tile-action>
													<v-icon>school</v-icon>
												</v-list-tile-action>
												<v-list-tile-content>
													<v-list-tile-title>
														Tipo de capacitación:
													</v-list-tile-title>
													<v-list-tile-sub-title>
														{{ tipoOrden.nombre }}
													</v-list-tile-sub-title>
												</v-list-tile-content>
											</v-list-tile>
											<!-- Número de orden -->
											<v-list-tile avatar>
												<v-list-tile-action>
													<v-icon>numbers</v-icon>
												</v-list-tile-action>
												<v-list-tile-content>
													<v-list-tile-title
														>Número de
														orden:</v-list-tile-title
													>
													<v-list-tile-sub-title>
														{{ numeroorden }}
													</v-list-tile-sub-title>
												</v-list-tile-content>
											</v-list-tile>
											<!-- Fecha y hora de fin -->
											<v-list-tile avatar>
												<v-list-tile-action>
													<v-icon>event</v-icon>
												</v-list-tile-action>
												<v-list-tile-content>
													<v-list-tile-title
														>Fecha y hora de
														fin:</v-list-tile-title
													>
													<v-list-tile-sub-title>
														{{
															fecha_fin_formateada
														}}
													</v-list-tile-sub-title>
												</v-list-tile-content>
											</v-list-tile>
										</v-flex>
										<!-- 3º Columna -->
										<v-flex xs12>
											<!-- Es presencial -->
											<v-list-tile avatar>
												<v-list-tile-action>
													<v-icon>front_hand</v-icon>
												</v-list-tile-action>
												<v-list-tile-content>
													<v-list-tile-title>
														¿Actividad presencial?
													</v-list-tile-title>
													<v-list-tile-sub-title>
														{{
															actividad_presencial_formateada
														}}
													</v-list-tile-sub-title>
												</v-list-tile-content>
											</v-list-tile>

											<!-- Fecha expiración -->
											<v-list-tile avatar>
												<v-list-tile-action>
													<v-icon
														>notification_important</v-icon
													>
												</v-list-tile-action>
												<v-list-tile-content>
													<v-list-tile-title
														>Fecha de
														expiración:</v-list-tile-title
													>
													<v-list-tile-sub-title>
														{{
															fecha_expiracion_formateada
														}}
													</v-list-tile-sub-title>
												</v-list-tile-content>
											</v-list-tile>
										</v-flex>
									</v-layout>
								</v-list>

								<v-divider class="my-4" />

								<v-list two-line subheader>
									<v-subheader>Participantes</v-subheader>
									<v-card-title>
										<v-list-tile avatar>
											<v-list-tile-action>
												<v-icon>people_alt</v-icon>
											</v-list-tile-action>
											<v-list-tile-content>
												<v-list-tile-title>
													Cantidad de participantes:
													{{ attendees.length }}
												</v-list-tile-title>
												<v-list-tile-sub-title>
													{{ cantidad_participantes }}
												</v-list-tile-sub-title>
											</v-list-tile-content>
										</v-list-tile>
										<v-spacer></v-spacer>
										<v-text-field
											v-model="search"
											append-icon="search"
											label="Filtrar"
											single-line
											hide-details
										></v-text-field>
									</v-card-title>

									<v-layout row wrap>
										<v-flex
											v-for="attendee in attendees_filter"
											:key="`${attendee}`"
										>
											<v-list-tile
												class="shadow ml-2 attendees-list-item"
											>
												<v-list-tile-content>
													<v-list-tile-title>
														{{
															`${attendee.apellido.toUpperCase()}, ${attendee.nombre.toUpperCase()}`
														}}
													</v-list-tile-title>
													<v-list-tile-sub-title>
														{{ attendee.documento }}
													</v-list-tile-sub-title>
												</v-list-tile-content>
											</v-list-tile>
										</v-flex>
									</v-layout>
								</v-list>
							</v-container>
						</template>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						flat
						@click="openDetail(false)"
					>
						Cerrar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-layout>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
	props: [],

	data() {
		return {
			search: null,
		};
	},

	methods: {
		...mapMutations({
			openDetail: "capacitacion/OPEN_DETAIL",
		}),

		formatDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},

		downloadUrl() {
			window.open(this.planilla, "_blank");
		},
	},
	computed: {
		...mapState({
			tipoOrden: (state) => state.capacitacion.tipoOrden,
			sucursal: (state) => state.capacitacion.sucursal,
			numeroorden: (state) => state.capacitacion.numeroorden,
			ispresencial: (state) => state.capacitacion.ispresencial,
			horainicio: (state) => state.capacitacion.horainicio,
			horafin: (state) => state.capacitacion.horafin,
			fechavencimiento: (state) => state.capacitacion.fechavencimiento,
			fechainicio: (state) => state.capacitacion.fechainicio,
			fechafin: (state) => state.capacitacion.fechafin,
			attendees: (state) => state.capacitacion.attendees,
			planilla: (state) => state.capacitacion.planilla,
			show_detail: (state) => state.capacitacion.show_detail,
		}),

		fecha_expiracion_formateada() {
			if (!this.show_detail) return "";
			return this.formatDate(this.fechavencimiento);
		},

		fecha_fin_formateada() {
			if (!this.show_detail) return "";
			if (this.fechafin)
				return `${this.formatDate(this.fechafin)} ${this.horafin}`;

			return `${this.horafin}`;
		},

		fecha_inicio_formateada() {
			if (!this.show_detail) return "";
			return `${this.formatDate(this.fechainicio)} ${this.horainicio}`;
		},

		sucursal_formateada() {
			if (!this.show_detail) return "";
			return `${this.sucursal?.cliente?.razonSocial} | ${this.sucursal?.localidad?.nombre} | ${this.sucursal?.direccion}`;
		},

		actividad_presencial_formateada() {
			if (!this.show_detail) return "";
			return this.ispresencial
				? "Esta es una actividad presencial"
				: "No es una actividad presencial";
		},

		cantidad_participantes() {
			if (!this.show_detail) return "";
			const cant = this.attendees.length;
			switch (cant) {
				case 0:
					return `La actividad no posee ningún participante registrado.`;
				case 1:
					return `La actividad cuenta por ahora con solo un participante registrado.`;
				default:
					return `La actividad posee ${cant} participantes registrados.`;
			}
		},

		attendees_filter() {
			if (!this.show_detail) return "";
			const valid = this.search && this.search.trim().length > 0;
			if (valid) {
				return this.attendees.filter(
					(item) =>
						item.apellido.search(this.search) > -1 ||
						item.nombre.search(this.search) > -1 ||
						item.documento.search(this.search) > -1
				);
			}
			return this.attendees;
		},
	},
};
</script>
<style scoped>
.attendees-list-item {
	border-radius: 5px;
	border: 1px #999 solid;
	width: 100%;
	margin-top: 0.5rem;
	box-shadow: 0px 0px 3px #333;
	margin-left: 4rem !important;
}
</style>
