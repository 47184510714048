import api from './api';
import Cookies from "js-cookie";

const rubroApi = {
    getRubros() {
        return api
            .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
            .get(`${process.env.VUE_APP_IP}/api/rubros`);   //Rubro
    },
    removeRubro(id) {
        return api.axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
            .delete(`${process.env.VUE_APP_IP}/api/rubros/${id}`);
    },

    postRubro({
        nombre,
        controlContratista,
    }) {
        return api
            .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
            .post(`${process.env.VUE_APP_IP}/api/rubros`, {
                nombre,
                controlContratista,
            });
    },

    updateRubro({
        id,
        nombre,
        controlContratista,
    }) {
        return api.axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
            .put(`${process.env.VUE_APP_IP}/api/rubros/${id}`, {
                nombre,
                controlContratista,
            });
    },
};

export default rubroApi;