<template>
  <v-container>
    <v-layout align-center row fill-height class="mt-2 px-2">
      <h3 class="mb-4 mr-auto">Empleados</h3>
      <v-flex xs4 class="mx-2">
        <v-btn color="primary" block @click="openExcelForm">
          Importar empleados
        </v-btn>
      </v-flex>
      <v-flex xs4 class="mx-2">
        <v-btn color="primary" block @click="showForm(false)">
          Registrar empleado
        </v-btn>
      </v-flex>
    </v-layout>

    <!-- Card -->
    <v-card class="my-2">
      <v-card-title>
        <v-layout row wrap class="mb-2 px-2">
          <v-spacer></v-spacer>
          <v-flex xs3 class="px-2">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Filtrar"
              single-line
              hide-details
            />
          </v-flex>
        </v-layout>
      </v-card-title>
      <!-- Table -->
      <v-data-table
        :headers="headers"
        :items="empleados_filter"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template v-slot:items="empleados">
          <td class="text-xs-center">
            {{ empleados.item.nombre }}
          </td>
          <td class="text-xs-center">
            {{ empleados.item.apellido }}
          </td>
          <td class="text-xs-center">
            {{ empleados.item.documento || "-" }}
          </td>
          <td class="text-xs-center">
            {{ empleados.item.cuit || "-" }}
          </td>
          <td class="text-xs-center">
            {{ formatDate(empleados.item.fechadeingreso) }}
          </td>
          <td class="text-xs-center">
            <b>{{ empleados.item.cliente ? empleados.item.cliente.nombreFantasia : '-' }}</b>
          </td>
          <td class="text-xs-center">
            {{ empleados.item.sucursal ? empleados.item.sucursal.direccion : '-' }}
          </td>
          <td class="text-xs-center">
            {{ formatDate(empleados.item.createdAt.split("T")[0]) }}
          </td>
          <td class="justify-center layout px-0">
            <v-icon
              small
              class="mr-2"
              title="Ver registro completo"
              @click="showDetail(empleados.item)"
            >
              visibility
            </v-icon>
            <v-icon
              small
              title="Editar registro"
              class="mr-2"
              @click="showForm(empleados.item)"
            >
              edit
            </v-icon>
            <v-icon
              small
              title="Eliminar registro"
              @click="showConfirmDelete(empleados.item._id)"
            >
              delete
            </v-icon>
          </td>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
            No se encontraron resultados para "{{ search }}".
          </v-alert>
        </template>
      </v-data-table>
    </v-card>

    <!-- Modals -->

    <empleado-form />
    <empleado-excel-form :show_form="showExcelForm" @close="closeExcelForm"></empleado-excel-form>
    <confirm-delete :empleado_id="empleado_id" />
    <detail />
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import EmpleadoForm from "./EmpleadoForm.vue";
import EmpleadoExcelForm from "./EmpleadoExcelForm.vue";
import ConfirmDelete from "./ConfirmDelete.vue";
import Detail from "./Detail.vue";

export default {
  data: () => ({
    showExcelForm: false,
    empleado_id: null,
    search: "",
    sortBy: "cliente.nombreFantasia",
    sortDesc: false,
    headers: [
      {
        sortable: false,
        text: "Nombre",
        value: "nombre",
        align: "center",
      },
      {
        sortable: false,
        text: "Apellido",
        value: "apellido",
        align: "center",
      },
      {
        sortable: false,
        text: "Documento",
        value: "documento",
        align: "center",
      },
      {
        sortable: false,
        text: "CUIT",
        value: "cuit",
        align: "center",
      },
      {
        sortable: false,
        text: "Fecha de Ingreso",
        value: "fechadeingreso",
        align: "center",
      },
      {
        sortable: true,
        text: "Cliente",
        value: "cliente.nombreFantasia",
        align: "center",
      },
      {
        sortable: false,
        text: "Sucursal",
        value: "sucursal.direccion",
        align: "center",
      },
      {
        sortable: false,
        text: "Fecha de alta",
        value: "createdAt",
        align: "center",
      },
      {
        sortable: false,
        text: "Acciones",
        value: "actions",
        align: "center",
      },
    ],
  }),

  mounted() {
    this.getEmpleados();
    this.getSucursales();
  },

  components: {
    EmpleadoForm,
    EmpleadoExcelForm,
    ConfirmDelete,
    Detail,
  },

  computed: {
    ...mapState({
      empleados: (state) => state.empleado.empleados,
      clientes: (state) => state.cliente.clientes,
      user: (state) => state.user,
    }),

    empleados_filter() {
      const valid = this.search && this.search.trim().length > 0;
      if (valid) {
        return this.empleados.filter(
          (item) =>
            item.nombre.search(this.search) > -1 ||
            item.apellido.search(this.search) > -1 ||
            item.cuit.search(this.search) > -1 ||
            item.fechadeingreso.search(this.search) > -1 ||
            item.cliente.nombreFantasia.search(this.search) > -1 ||
            this.formatDate(item.createdAt.split("T")[0]).search(this.search) >
              -1
        );
      }
      return this.empleados;
    },
  },

  methods: {
    ...mapActions({
      getClientes: "cliente/getClientes",
      getSucursales: "sucursal/getSucursales",
      getEmpleados: "empleado/getEmpleados",
    }),

    ...mapMutations({
      setShowConfirmDelete: "empleado/SET_SHOW_CONFIRM_DELETE",
      openDetail: "empleado/OPEN_DETAIL",
      openForm: "empleado/OPEN_FORM",
      loadForm: "empleado/LOAD_FORM",
      setIsUpdateForm: "empleado/SET_IS_UPDATE_FORM",
      resetForm: "empleado/RESET",
      setCliente: "empleado/SET_CLIENTE",
    }),

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    showConfirmDelete(id) {
      this.empleado_id = id;
      this.setShowConfirmDelete(true);
    },

    showForm(empleado) {
      if (this.user.isAdmin && this.clientes.length == 0) this.getClientes();
      this.setIsUpdateForm(empleado);
      if (this.user.cliente) this.setCliente(this.user.cliente);
      if (empleado) this.loadForm(empleado);
      this.openForm(true);
    },

    showDetail(empleado) {
      if (empleado) {
        this.loadForm(empleado);
        this.openDetail(true);
      }
    },

    openExcelForm() {
      if (this.clientes.length == 0) this.getClientes();
      this.showExcelForm = true;
    },
    closeExcelForm() {
      this.showExcelForm = false;
    }
  },
};
</script>
