import { defaultState } from "./config";
import Vue from "vue";

export const mutations = {
  /**
   * Mutations for Form
   */
  RESET(state) {
    state.tipoOrden = null;
    state.sucursal = null;
    state.numeroorden = null;
    state.fechainicio = null;
    state.fechafin = null;
    state.fechavencimiento = null;
    state.cliente_id = null;
  },

  SET_TIPO_ORDEN(state, payload) {
    state.tipoOrden = payload;
  },

  SET_SUCURSAL(state, payload) {
    state.sucursal = payload;
    state.cliente_id = payload.cliente_id;
  },

  SET_NUMERO_ORDEN(state, payload) {
    state.numeroorden = payload;
  },

  SET_FECHA_INICIO(state, payload) {
    state.fechainicio = payload;
  },

  SET_FECHA_FIN(state, payload) {
    state.fechafin = payload;
  },

  SET_FECHA_EXPIRACION(state, payload) {
    state.fechavencimiento = payload;
  },

  SET_SHOW_CONFIRM_DELETE(state, payload) {
    state.show_confirm_delete = payload;
  },

  SET_IS_UPDATE_FORM(state, payload) {
    state.is_update_form = payload;
    if (!payload) {
      state.tipoOrden = null;
      state.sucursal = null;
      state.numeroorden = null;
      state.fechainicio = null;
      state.fechafin = null;
      state.fechavencimiento = null;
      state.cliente_id = null;
    }
  },

  OPEN_FORM(state, payload) {
    state.show_form = payload;
  },

  OPEN_DETAIL(state, payload) {
    state.show_detail = payload;
  },

  LOAD_FORM(state, payload) {
    state.id = payload._id;
    state.tipoOrden = payload.tipoOrden;
    state.sucursal = payload.sucursal;
    state.cliente_id = payload.sucursal.cliente_id;
    state.numeroorden = payload.numeroorden;
    state.fechainicio = payload.fechainicio;
    state.fechafin = payload.fechafin;
    state.fechavencimiento = payload.fechavencimiento;
  },

  /**
   * Mutations for Actions
   */

  GET_ORDENES_LOADING(state) {
    state.getOrdenesLoading = true;
    state.getOrdenesSuccess = false;
    state.OrdenesError = false;
    state.getOrdenesErrorMessage = "";
  },

  GET_ORDENES_SUCCESS(state, ordenes) {
    state.getOrdenesLoading = false;
    state.getOrdenesSuccess = true;
    state.ordenes = [];
    ordenes.map((orden, index) => {
      Vue.set(state.ordenes, index, { ...orden });
    });
  },

  GET_ORDENES_ERROR(state, e) {
    state.OrdenesLoading = false;
    state.OrdenesError = true;
    state.OrdenesErrorMessage = e;
  },

  REMOVE_ORDEN_SUCCESS(state) {
    state.removeOrdenLoading = false;
    state.removeOrdenSuccess = true;
  },

  REMOVE_ORDEN_LOADING(state) {
    state.removeOrdenLoading = true;
    state.removeOrdenSuccess = false;
    state.removeOrdenError = false;
    state.removeOrdenErrorMessage = "";
  },

  REMOVE_ORDEN_ERROR(state, e) {
    state.removeOrdenLoading = false;
    state.removeOrdenError = true;
    state.removeOrdenErrorMessage = e;
  },
  POST_ORDEN_LOADING(state) {
    state.postOrdenLoading = true;
    state.postOrdenSuccess = false;
    state.postOrdenError = false;
    state.postOrdenErrorMessage = "";
  },

  POST_ORDEN_SUCCESS(state) {
    state.postOrdenLoading = false;
    state.postOrdenSuccess = true;
    Vue.swal({
      title: "Registrado",
      text: "orden registrada",
      type: "success",
      width: "350px",
      timer: 2000,
      position: "top-end",
    });
  },

  POST_ORDEN_ERROR(state, e) {
    state.postOrdenLoading = false;
    state.postOrdenError = true;
    state.postOrdenErrorMessage = e;
  },

  POST_ORDEN_DUPLICATED(state, message){
    state.postOrdenLoading = false;
    state.postOrdenError = true;
    state.postOrdenErrorMessage = message;
    Vue.swal({
      title: message,
      text: "Una orden con los mismos datos ya fue registrada",
      type: 'warning',
      width: '500px',
      timer: 9000,
      position: 'top-end',
    });
  },

  UPDATE_ORDEN_SUCCESS(state) {
    state.updateOrdenThemeLoading = false;
    state.updateOrdenThemeSuccess = true;
  },

  UPDATE_ORDEN_ERROR(state, e) {
    state.updateOrdenLoading = false;
    state.updateOrdenError = true;
    state.updateOrdenErrorMessage = e;
    Vue.swal({
      title: "Error",
      text: "algo fallo durante la actualización",
      type: "error",
      width: "350px",
      timer: 2000,
      position: "top-end",
    });
  },
};
