import { defaultState } from './config';
import Vue from 'vue';

export const mutations = {
  RESET(state) {
    const s = defaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },

  GET_PROVINCIAS_LOADING(state) {
    state.getLocalidadesLoading = true;
    state.getLocalidadesSuccess = false;
    state.getLocalidadesError = false;
    state.getLocalidadesErrorMessage = '';
    state.clientes = [];
  },
  GET_PROVINCIAS_SUCCESS(state, provincias) {
    state.getLocalidadesLoading = false;
    state.getLocalidadesSuccess = true;
    state.provincias = [];

    provincias.map((provincia, index) => {
      Vue.set(state.provincias, index, { ...provincia });
    });
  },
  GET_PROVINCIAS_ERROR(state, e) {
    state.getLocalidadesLoading = false;
    state.getLocalidadesError = true;
    state.getLocalidadesErrorMessage = e;
  },
};