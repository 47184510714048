<template>
<v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" dark v-on="on">Registrar nuevo Cliente</v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Nuevo CLiente</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field label="Razon Social*" required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field label="CUIT" hint="requistre cuit de sucursal"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="*Nombre y apellido"
                    hint="nombre y apellido de un delegado de contacto"
                    persistent-hint
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Email*" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Password*" type="password" required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    :items="['Resistencia | Chaco', 'Barranqueras | Chaco', 'Posadas | Misiones', 'CABA | Buenos Aires']"
                    label="Localidad*"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    :items="['Energía', 'fabrica Alimentos', 'venta Alimentos', 'venta Farmacos', 'Institución Educativa']"
                    label="Rubro Empresa"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
            <small>* campos requeridos</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Cerrar</v-btn>
            <v-btn color="blue darken-1" text @click="addCliente()">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>    
</template>

<script>
    //import Vue from 'vue'

    export default {
        name: "clienteModal",
        data: () => ({

            dialog: false,

            headers: [
            {
                sortable: false,
                text: 'Empresa',
                value: 'Empresa'
            },
            {
                sortable: false,
                text: 'Rubro',
                value: 'Rubro'
            },
            {
                sortable: false,
                text: 'Ciudad',
                value: 'Ciudad'
            },
            {
                sortable: false,
                text: 'Facturado',
                value: 'Facturado',
                align: 'right'
            }
            ],
            items: [
            {
                empresa: 'Farmacity SA - Sucursal 211',
                Rubro: 'Venta de Farmacos',
                Ciudad: 'Oud-Tunrhout',
                Facturado: '$35,738'
            },
            {
                Empresa: 'Minerva Hooper',
                Rubro: 'Venta de Productos de Limpleza',
                Ciudad: 'Sinaai-Waas',
                Facturado: '$23,738'
            }, {
                Empresa: 'Sage Rodriguez',
                Rubro: 'Energía Eléctrica',
                Ciudad: 'Alto Paraná',
                Facturado: '$56,142'
            }, {
                Empresa: 'Afilaciones Industriales & abastecimientos SA',
                Rubro: 'Fabricación de Herramientas de Hierro',
                Ciudad: 'Apóstoles',
                Facturado: '$38,735'
            }, {
                Empresa: 'Doris Keller',
                Rubro: 'Industria Maderera',
                Ciudad: 'Resistencia',
                Facturado: '$63,542'
            }, {
                Empresa: 'Juliana Gomez',
                Rubro: 'Industria Maderera',
                Ciudad: 'Villa Gral. Belgrano',
                Facturado: '$78,615'
            }
            ]
        }),
        methods:{
            addCliente() {
                alert('dispatch a postCliente')
                this.$store.dispatch("cliente/postCliente", {
                    email: this.userEmail,
                    theme_id: this.theme.id
                });
                
            },
            deleteCliente (id) {
                this.$store.dispatch("user/removeCliente", {id});       
            },
        }
    }
</script>
