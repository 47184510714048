import ordenApi from "../../../api/ordenApi";
import empleadoApi from "../../../api/empleadoApi";

export default {
  postCapacitacion({ dispatch, commit, state }) {
    return ordenApi
      .postCapacitacion(state)
      .then((_response) => {
        commit("POST_ORDEN_SUCCESS");
      })
      .catch((e) => {
        commit("POST_ORDEN_ERROR", e);
      });
  },

  getEmpleadosCliente({ commit }, payload) {
    return empleadoApi
      .getEmpleadosCliente(payload)
      .then(({ data }) => {
        commit("SET_EMPLOYEES", data);
      })
      .catch((e) => {
        console.log(`error: ${e}`);
      });
  },

  updateCapacitacion({ commit, dispatch, state }) {
    return ordenApi
      .updateCapacitacion(state)
      .then((_response) => {
        commit("UPDATE_ORDEN_SUCCESS");
      })
      .catch((e) => {
        commit("UPDATE_ORDEN_ERROR", e);
      });
  },
};
