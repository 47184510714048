import api from './api';
import Cookies from "js-cookie";

const localidadApi = {
    getLocalidades() {
        return api.axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
            .get(`${process.env.VUE_APP_IP}/api/localidades`);   //Localidad
    },
    removeLocalidad(id) {
        return api.axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
            .delete(`${process.env.VUE_APP_IP}/api/localidades/${id}`);
    },

    postLocalidad({ nombre, provincia, codigoPostal, pais }) {
        return api.axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
            .post(`${process.env.VUE_APP_IP}/api/localidades`, {
                nombre,
                provincia_id: provincia._id,
                codigoPostal,
                pais_id: pais._id
            });
    },

    updateLocalidad({
        id,
        nombre,
        provincia,
        codigoPostal,
        pais,
    }) {
        return api
            .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
            .put(`${process.env.VUE_APP_IP}/api/localidades/${id}`, {
                nombre,
                provincia_id: provincia._id,
                codigoPostal,
                pais_id: pais._id,
            });
    },

};

export default localidadApi;