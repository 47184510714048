import { defaultState } from "./config";
import Vue from "vue";

export const mutations = {
  RESET(state) {
    state.nombreFantasia = null;
    state.razonSocial = null;
    state.pais = null;
    state.categoriaAfip = null;
    state.rubro = null;
    state.cuit = null;
    state.email = null;
    state.telefono = null;
    state.image = null;
    state.target = null
  },

  GET_CLIENTES_SUCCESS(state, { clientes }) {
    state.getClientesLoading = false;
    state.getClientesSuccess = true;
    state.clientes = [];
    clientes.map((cliente, index) => {
      Vue.set(state.clientes, index, { ...cliente });
    });
  },

  GET_CLIENTES_ERROR(state, e) {
    state.getClientesLoading = false;
    state.getClientesError = true;
    state.getClientesErrorMessage = e;
    Vue.swal({
      title: "Error",
      text: e,
      type: "error",
      width: "350px",
      timer: 2000,
      position: "top-end",
    });
  },

  GET_DOCUMENTOS_SUCCESS(state, { documentos }) {
    state.getDocumentosLoading = false;
    state.getDocumentosSuccess = true;
    state.documentos = [];
    documentos.map((documento, index) => {
      Vue.set(state.documentos, index, { ...documento });
    });
  },

  GET_DOCUMENTOS_ERROR(state, e) {
    state.getDocumentosLoading = false;
    state.getDocumentosError = true;
    state.getDocumentosErrorMessage = e;
    Vue.swal({
      title: "Error",
      text: e,
      type: "error",
      width: "350px",
      timer: 2000,
      position: "top-end",
    });
  },



  OPEN_DETAIL(state, payload) {
    state.show_detail = payload;
  },

  LOAD_FORM(state, payload) {
    state.id = payload._id;
    state.nombreFantasia = payload.nombreFantasia;
    state.razonSocial = payload.razonSocial;
    state.pais = payload.pais;
    state.categoriaAfip = payload.categoria;
    state.rubro = payload.rubro;
    state.documentos = payload['documentos'] || [];
    state.cuit = payload.cuit;
    state.email = payload.email;
    state.telefono = payload.telefono;
    state.filename = payload.filename;
    state.linktofile = payload.linktofile;
  },

  SET_NOMBRE_FANTASIA(state, payload) {
    state.nombreFantasia = payload;
  },

  SET_RUBRO(state, payload) {
    state.rubro = payload;
  },

  SET_TELEFONO(state, payload) {
    state.telefono = payload;
  },

  SET_PAIS(state, payload) {
    state.pais = payload;
  },

  SET_CATEGORIA_AFIP(state, payload) {
    state.categoriaAfip = payload;
  },

  SET_CUIT(state, payload) {
    state.cuit = payload;
  },

  SET_EMAIL(state, payload) {
    state.email = payload;
  },

  SET_IMAGE(state, payload) {
    state.image = payload;
  },

  SET_RAZONSOCIAL(state, payload) {
    state.razonSocial = payload;
  },

  SET_IS_UPDATE_FORM(state, payload) {
    state.is_update_form = payload;
    if (!payload) {
      state.nombreFantasia = null;
      state.razonSocial = null;
      state.rubro = null;
      state.telefono = null;
      state.pais = null;
      state.categoriaAfip = null;
      state.cuit = null;
      state.email = null;
      state.filename = null;
      state.linktofile = null;
    }
  },

  OPEN_FORM(state, payload) {
    state.show_form = payload;
  },

  SET_SHOW_CONFIRM_DELETE(state, payload) {
    state.show_confirm_delete = payload;
  },

  POST_CLIENTE_LOADING(state) {
    state.postClienteLoading = true;
    state.postClienteSuccess = false;
    state.postClienteError = false;
    state.postClienteErrorMessage = "";
  },
  POST_CLIENTE_SUCCESS(state) {
    state.postClienteLoading = false;
    state.postClienteSuccess = true;
    Vue.swal({
      title: "Registrado",
      text: `Se le ha enviado un email de activación de cuenta. Para utilizar el cliente necesitara asociar almenos una sucursal`,
      type: "success",
      width: "350px",
      timer: 9000,
      position: "top-end",
    });
  },

  POST_CLIENTE_ERROR(state, e) {
    state.postClienteLoading = false;
    state.postClienteError = true;
    state.postClienteErrorMessage = e;
    Vue.swal({
      title: "Error",
      text: e,
      type: "error",
      width: "350px",
      timer: 2000,
      position: "top-end",
    });
  },

  POST_CLIENTE_DUPLICATED(state, message) {
    state.postClienteLoading = false;
    state.postClienteError = true;
    state.postClienteErrorMessage = message;
    Vue.swal({
      title: message,
      text: "verifique su cartera de clientes, ya existe un cliente con el email asignado",
      type: "warning",
      width: "500px",
      timer: 9000,
      position: "top-end",
    });
  },

  REMOVE_CLIENTE_SUCCESS(state) {
    state.removeClienteLoading = false;
    state.removeClienteSuccess = true;
  },
  DEACTIVATE_CLIENTE_SUCCESS(state) {
    state.deactivateClienteLoading = false;
    state.deactivateClienteSuccess = true;
  },
  REMOVE_CLIENTE_ERROR(state, e) {
    state.removeClienteLoading = false;
    state.removeClienteError = true;
    state.removeClienteErrorMessage = e;
  },
  DEACTIVATE_CLIENTE_ERROR(state, e) {
    state.deactivateClienteLoading = false;
    state.deactivateClienteError = true;
    state.deactivateClienteErrorMessage = e;
  },
  UPDATE_CLIENTE_SUCCESS(state) {
    state.updateClienteThemeLoading = false;
    state.updateClienteThemeSuccess = true;
  },
  /*
    UPDATE_CLIENTE_STATUS_SUCCESS(state) {
        state.updateClienteStatusThemeLoading = false;
        state.updateClienteStatusThemeSuccess = true;
    },
    */
  UPDATE_CLIENTE_ERROR(state, e) {
    state.updateClienteLoading = false;
    state.updateClienteError = true;
    state.updateClienteErrorMessage = e;
    Vue.swal({
      title: "Error",
      text: "algo fallo durante la actualización",
      type: "error",
      width: "350px",
      timer: 2000,
      position: "top-end",
    });
  },
};