<template>
    <div class="file">
        <input
            type="file"
            ref="file"
            prepend-icon="mdi-camera"
            @change="onselect"
        >
    </div>
</template>
<script>
    export default {
        name: 'FileUpload',
        data(){
            return{
                file:'',
                message:'',
            }
        },
        methods:{
            onselect(){
                const file = this.$refs.file.files[0];
                this.file= file;
                const formData = newFormData();
                formData.append('file', this.file)
            }
        }
    }


</script>