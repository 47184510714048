import { defaultState } from "./config";
import Vue from "vue";

export const mutations = {
  RESET(state) {
    const s = defaultState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
    state.email = null;
    state.cliente = null;
    state.status = null;
    state.isAdmin = false;
  },

  LOGIN_USER_SUCCESS(state, payload) {
    state.login = true;
    state.getUserLoading = false;
    state.getUserSuccess = true;
    state.isAdmin = (payload.cliente || payload.tecnico) ? false : true;
    state.email = payload.email;
    state.cliente = payload.cliente;
    state.tecnico = payload.tecnico;
    state.status = payload.status;
  },

  LOGIN_USER_ERROR(state, e) {
    Vue.swal({
      title: "Credenciales de acceso incorrectas",
      text: "por favor verifique su email y contraseña proporcionados",
      type: "warning",
      timer: 3500,
    });
    state.getUserLoading = false;
    state.getUserError = true;
    state.state.login = false;
    state.getUserErrorMessage = e;
  },


  GET_USER_SUCCESS(state, user) {
    state.getUserLoading = false;
    state.getUserSuccess = true;
    state.user = user;
  },

  GET_USER_ERROR(state, e) {
    state.getUserLoading = false;
    state.getUserError = true;
    state.getUserErrorMessage = e;
  },
  GET_USERS_SUCCESS(state, users) {
    state.getUsersLoading = false;
    state.getUsersSuccess = true;
    state.users = users;
  },

  GET_USERS_ERROR(state, e) {
    state.getUsersLoading = false;
    state.getUsersError = true;
    state.getUsersErrorMessage = e;
  },

  REMOVE_USER_SUCCESS(state) {
    state.removeUserLoading = false;
    state.removeUserSuccess = true;
  },

  REMOVE_USER_LOADING(state) {
    state.removeUserLoading = true;
    state.removeUserSuccess = false;
    state.removeUserError = false;
    state.removeUserErrorMessage = "";
  },

  REMOVE_USER_ERROR(state, e) {
    state.removeUserLoading = false;
    state.removeUserError = true;
    state.removeUserErrorMessage = e;
  },

  UPDATE_USER_SUCCESS(state) {
    state.updateUserThemeLoading = false;
    state.updateUserThemeSuccess = true;
  },

  UPDATE_USER_ERROR(state, e) {
    state.updateUserLoading = false;
    state.updateUserError = true;
    state.updateUserErrorMessage = e;
    Vue.swal({
      title: "Error",
      text: "algo fallo durante la actualización",
      type: "error",
      width: "350px",
      timer: 2000,
      position: "top-end",
    });
  },
};
