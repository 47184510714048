import { defaultState } from './config'; 

const state = Object.assign(
    {
      id: null,
      nombre: null,
      provincia: null,
      codigoPostal: null,
      pais: null,
      show_confirm_delete: false,
      show_form: false,
      show_detail: false,
      is_update_form: false,
    },
    defaultState()
);
  
export default state;
  