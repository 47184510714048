<template>
	<v-container>
		<v-layout
			align-center
			justify-space-between
			row
			fill-height
			class="mt-2 px-2"
		>
			<h3 class="mb-4">Clientes</h3>
			<v-flex xs2 class="mx-2"> </v-flex>
		</v-layout>
		<!-- Card -->
		<v-card class="my-2">
			<v-card-title>
				<v-layout row wrap class="mb-2 px-2 menu">
					<v-btn color="primary" @click="showForm(false)">
						Nuevo
					</v-btn>
					<v-btn
						append-icon="file"
						@click="requestClientDocs()"
						class="switch"
						v-bind:class="[onlyDoc ? 'active' : '']"
					>
						{{ onlyDoc ? "Ver todos" : "Solo con documentos" }}
						<v-icon small class="mr-2" v-if="!onlyDoc">
							mdi-file
						</v-icon>
					</v-btn>

					<v-spacer></v-spacer>
					<v-flex xs3 class="px-2">
						<v-text-field
							v-model="search"
							append-icon="search"
							label="Filtrar"
							single-line
							hide-details
						/>
					</v-flex>
				</v-layout>
			</v-card-title>
			<!-- Table -->
			<v-data-table
				:headers="headers"
				:items="clientes_filter"
				:pagination.sync="pagination"
			>
				<template v-slot:items="clientes">
					<td class="text-xs-center">
						<b>{{ clientes.item.razonSocial }}</b>
					</td>
					<td class="text-xs-center">
						{{ clientes.item.rubro.nombre }}
					</td>
					<td v-if="clientes.item.cuit" class="text-xs-center">
						{{ clientes.item.cuit }}
					</td>
					<td v-else class="text-xs-center">-</td>
					<td v-if="clientes.item.createdAt" class="text-xs-center">
						{{ formatDate(clientes.item.createdAt.split("T")[0]) }}
					</td>
					<td v-else class="text-xs-center">-</td>

					<td v-if="clientes.item.linktofile" class="text-xs-center">
						<v-img
							:src="clientes.item.linktofile"
							alt="customer profile image"
							width="50px"
							style="border-radius: 5px"
						></v-img>
					</td>
					<td v-else class="text-xs-center">Sin logo</td>
					<td class="text-xs-center">
						<small>
							<b>{{ clientes.item.status }}</b>
						</small>
					</td>

					<td class="text-xs-center text--lighten-1">
						<b>{{ clientes.item.sucursales.length }}</b>
					</td>
					<td class="justify-center layout px-2 pt-4">
						<v-icon
							small
							class="mr-2"
							title="Ver registro completo"
							@click="showDetail(clientes.item)"
						>
							visibility
						</v-icon>

						<v-icon
							small
							title="Editar registro"
							class="mr-2"
							@click="showForm(clientes.item)"
						>
							edit
						</v-icon>

						<v-icon
							small
							class="mr-2"
							title="Ver registro completo"
							@click="showDocs(clientes.item._id)"
						>
							mdi-file
						</v-icon>

						<v-icon
							small
							title="Eliminar registro"
							@click="showConfirmDelete(clientes.item._id)"
						>
							delete
						</v-icon>
					</td>
				</template>
				<template v-slot:no-results>
					<v-alert :value="true" color="error" icon="warning">
						No se encontraron resultados para "{{ search }}".
					</v-alert>
				</template>
			</v-data-table>
		</v-card>

		<!-- Modals -->

		<cliente-form />
		<confirm-delete :cliente_id="cliente_id" />
		<detail />
		<Dialog
			title="Archivos"
			:state="documentosDialog"
			@state="documentosDialog = $event"
		>
			<div class="button--list" v-if="documentos.length">
				<a
					class="button--list--item download"
					v-for="doc in documentos"
					:key="doc._id"
					:href="doc.file"
					target="_blank"
				>
					{{ doc.updatedAt.split(".")[0].replace("T", " ") }}
				</a>
			</div>
			<div v-else>
				<h3>No hay documentos</h3>
			</div>
		</Dialog>
	</v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ClienteForm from "./ClienteForm.vue";
import ConfirmDelete from "./ConfirmDelete.vue";
import Detail from "./Detail.vue";
import Dialog from "../../components/Dialog.vue";

export default {
	data: () => ({
		cliente_id: null,
		search: "",
		onlyDoc: false,
		documentos: [],
		documentosDialog: "hidden",
		clienteSeleccionado: "",
		pagination: {
			rowsPerPage: -1, // con -1 mustra "Todos"
		},
		headers: [
			{
				sortable: true,
				text: "Razon Social",
				value: "razonSocial",
				align: "center",
			},
			{
				sortable: true,
				text: "Rubro",
				value: "rubro.nombre",
				align: "center",
			},
			{
				sortable: true,
				text: "CUIL/CUIT",
				value: "cuit",
				align: "center",
			},
			{
				sortable: true,
				text: "Fecha de alta",
				value: "createdAt",
				align: "center",
			},
			{
				sortable: true,
				text: "Logo",
				value: "image",
				align: "center",
			},
			{
				sortable: true,
				text: "Estado",
				value: "active",
				align: "center",
			},
			{
				sortable: true,
				text: "N° Sucursales",
				align: "center",
			},
			{
				sortable: false,
				text: "Acciones",
				value: "actions",
				align: "center",
			},
		],
	}),

	mounted() {
		this.getRubros();
		this.getClientes();
	},

	components: {
		ClienteForm,
		ConfirmDelete,
		Detail,
		Dialog,
	},

	computed: {
		...mapState({
			clientes: (state) => state.cliente.clientes,
		}),

		clientes_filter() {
			const valid = this.search && this.search.trim().length > 0;
			if (valid) {
				return this.clientes.filter(
					(item) =>
						(item.nombreFantasia &&
							item.nombreFantasia.search(this.search) > -1) ||
						(item.razonSocial &&
							item.razonSocial.search(this.search) > -1) ||
						(item.rubro.nombre &&
							item.rubro.nombre.search(this.search) > -1) ||
						(item.cuit && item.cuit.search(this.search) > -1) ||
						(this.formatDate(item.createdAt.split("T")[0]) &&
							this.formatDate(
								item.createdAt.split("T")[0]
							).search(this.search)) > -1
				);
			}
			return this.clientes.filter((cliente) => cliente.active === true);
		},
	},

	methods: {
		...mapActions({
			getRubros: "rubro/getRubros",
			getClientes: "cliente/getClientes",
			getClientesDocs: "cliente/getClientesDocs",
			getDocs: "cliente/getDocs",
		}),

		...mapMutations({
			/**
			 * Clientes
			 */
			setShowConfirmDelete: "cliente/SET_SHOW_CONFIRM_DELETE",
			openDetail: "cliente/OPEN_DETAIL",
			openForm: "cliente/OPEN_FORM",
			loadForm: "cliente/LOAD_FORM",
			setIsUpdateForm: "cliente/SET_IS_UPDATE_FORM",
			resetForm: "cliente/RESET",
		}),

		requestClientDocs() {
			this.onlyDoc = !this.onlyDoc;
			if (this.onlyDoc) {
				this.getClientesDocs();
			} else this.getClientes();
		},
		async showDocs(_id) {
			const self = this;
			if (this.clienteSeleccionado != _id) {
				this.documentos = [];
				this.clienteSeleccionado = _id;
				this.documentosDialog = "loading";
				this.getDocs(_id).then((res) => {
					self.documentos = res;
					this.documentosDialog = "ready";
				});
			}
		},
		formatDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},

		showConfirmDelete(id) {
			this.cliente_id = id;
			this.setShowConfirmDelete(true);
		},

		showForm(cliente) {
			if (cliente) this.loadForm(cliente);
			this.setIsUpdateForm(cliente);
			this.openForm(true);
		},

		showDetail(cliente) {
			if (cliente) {
				this.loadForm(cliente);
				this.openDetail(true);
			}
		},
	},
};
</script>

<style scoped>
.menu button {
	font-size: 1rem;
}
.switch {
	margin-left: 10px;
	color: #fff;
	background: rgb(44, 88, 164) !important;
}
.switch.active {
	background: rgb(29, 59, 111) !important;
}
</style>
