<template>
  <v-layout row justify-center>
    <v-dialog v-model="show_detail" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Detalle del registro</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <!-- Cliente -->
                <v-list-tile avatar>
                  <v-list-tile-action>
                    <v-icon>contacts</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title> Cliente </v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ cliente ? cliente.nombreFantasia : '-' }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-flex>
              <v-flex xs12>
                <!-- Dirección -->
                <v-list-tile avatar>
                  <v-list-tile-action>
                    <v-icon>mdi-home-map-marker</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title> Dirección </v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ direccion }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-flex>
              <v-flex xs12>
                <!-- Localidad -->
                <v-list-tile avatar>
                  <v-list-tile-action>
                    <v-icon>mdi-map-marker-radius</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Localidad</v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ localidad ? localidad.nombre : '-' }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-flex>
              <v-flex xs12>
                <!-- Teléfono -->
                <v-list-tile avatar>
                  <v-list-tile-action>
                    <v-icon>phone</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Teléfono</v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ telefono }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-flex>

              <v-flex xs12>
                <!-- Email -->
                <v-list-tile avatar>
                  <v-list-tile-action>
                    <v-icon>email</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Email</v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ email ? email : cliente.email }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-flex>
              <v-flex v-if="secondEmail" xs12>
                <!-- Email -->
                <v-list-tile avatar>
                  <v-list-tile-action>
                    <v-icon>email</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Email Secundario</v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ secondEmail }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-flex>
              <!--
              <v-flex xs12>
                <v-list-tile avatar>
                  <v-list-tile-action>
                    <v-icon>mdi-latitude</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Latitud</v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ latitud }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-flex>

              <v-flex xs12>
                <v-list-tile avatar>
                  <v-list-tile-action>
                    <v-icon></v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Longitud</v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ longitud }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-flex>
              -->
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat @click="openDetail(false)">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  data: () => ({}),

  methods: {
    ...mapMutations({
      openDetail: "sucursal/OPEN_DETAIL",
    }),

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },

  computed: {
    ...mapState({
      direccion: (state) => state.sucursal.direccion,
      telefono: (state) => state.sucursal.telefono,
      cliente: (state) => state.sucursal.cliente,
      localidad: (state) => state.sucursal.localidad,
      show_detail: (state) => state.sucursal.show_detail,
      email: (state) => state.sucursal.email,
      secondEmail: (state) => state.sucursal.secondEmail,
    }),
  },
};
</script>

