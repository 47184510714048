import userApi from '../../../api/userApi';
import docsApi from '../../../api/docsApi';

export default {
  async getDocumentosByClient({ commit }, id) {
    return docsApi.getDocsByCliente({ id }).then(res => {
      commit('SET_DOCUMENTOS_CLIENT', res.data);
    });
  },
  async getDocumentos({ commit }) {
    return docsApi.getDocs().then((res) => {
      commit('SET_DOCUMENTOS', res.data);
    });
  },
  async assignDocToClient({ state }, { cliente_id, documento_id, tecnico_id, file, oldfilename }) {
    return docsApi.assignDoc({ cliente_id, documento_id, tecnico_id, file, oldfilename });
  },
  async uploadDocumento({ state }, { nombre, etiqueta, documento }) {
    return docsApi.uploadDoc({ nombre, etiqueta, documento });
  },
  async removeDocumento({ state, dispatch }, { id }) {
    return docsApi.removeDoc({ id }).then(() => {
      dispatch('getDocumentos')
    });
  },
};