import { defaultState } from "./config";

const state = Object.assign(
  {
    id: null,
    orden_id: null,
    tipoOrden: null,
    capacitacion: null,
    tituloCapacitacion: null,
    sucursal: null,
    numeroorden: null,
    horainicio: null,
    horafin: null,
    ispresencial: false,
    fechainicio: null,
    fechafin: null,
    fechavencimiento: null,
    attendees: [],
    employees: [],
    planilla: null,
    show_detail: false,
    is_update_form: false,
    filename: null,
    linktofile: null
  },
  defaultState()
);

export default state;
