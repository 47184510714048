import { defaultState } from './config';
import Vue from 'vue';

export const mutations = {

    RESET(state) {
        state.nombre = null;
        state.capacitacion = null;
    },

    SET_NOMBRE(state, payload) {
        state.nombre = payload;
    },

    SET_CAPACITACION(state, payload) {
        state.capacitacion = payload;
        if (payload === true) {
            state.compliance = false;
            state.inspeccion = false;
        }
    },
    SET_COMPLIANCE(state, payload) {
        state.compliance = payload;
        if (payload === true) {
            state.capacitacion = false;
            state.inspeccion = false;
        }
    },
    SET_INSPECCION(state, payload) {
        state.inspeccion = payload;
        if (payload === true) {
            state.compliance = false;
            state.capacitacion = false;
        }
    },

    GET_TIPOSORDEN_LOADING(state) {
        state.getTiposOrdenLoading = true;
        state.getTiposOrdenSuccess = false;
        state.TiposOrdenError = false;
        state.getTiposOrdenErrorMessage = '';
    },

    GET_TIPOSORDENES_SUCCESS(state, tiposOrdenes) {
        state.getTiposOrdenesLoading = false;
        state.getTiposOrdenesSuccess = true;
        state.tiposOrdenes = [];
        tiposOrdenes.map((tipoOrden, index) => {
            Vue.set(state.tiposOrdenes, index, { ...tipoOrden });
        });
    },

    GET_TIPOSORDEN_ERROR(state, e) {
        state.TiposOrdenLoading = false;
        state.TiposOrdenError = true;
        state.TiposOrdenErrorMessage = e;
    },

    REMOVE_TIPOORDEN_SUCCESS(state) {
        state.removeTipoOrdenLoading = false;
        state.removeTipoOrdenSuccess = true;
    },

    REMOVE_TIPOORDEN_LOADING(state) {
        state.removeTipoOrdenLoading = true;
        state.removeTipoOrdenSuccess = false;
        state.removeTipoOrdenError = false;
        state.removeTipoOrdenErrorMessage = '';
    },

    REMOVE_TIPOORDEN_ERROR(state, e) {
        state.removeTipoOrdenLoading = false;
        state.removeTipoOrdenError = true;
        state.removeTipoOrdenErrorMessage = e;
    },

    POST_TIPOORDEN_LOADING(state) {
        state.postTipoOrdenLoading = true;
        state.postTipoOrdenSuccess = false;
        state.postTipoOrdenError = false;
        state.postTipoOrdenErrorMessage = '';
    },

    POST_TIPOORDEN_SUCCESS(state) {
        state.postTipoOrdenLoading = false;
        state.postTipoOrdenSuccess = true;
        Vue.swal({
            title: 'Registrado',
            text: 'Tipo de orden registrada',
            type: 'success',
            width: '350px',
            timer: 2000,
            position: 'top-end',
        });

    },

    POST_TIPOORDEN_ERROR(state, e) {
        state.postTipoOrdenLoading = false;
        state.postTipoOrdenError = true;
        state.postTipoOrdenErrorMessage = e;
    },

    UPDATE_TIPOORDEN_SUCCESS(state) {
        state.updateTipoOrdenThemeLoading = false;
        state.updateTipoOrdenThemeSuccess = true;
    },

    UPDATE_TIPOORDEN_ERROR(state, e) {
        state.updateTipoOrdenLoading = false;
        state.updateTipoOrdenError = true;
        state.updateTipoOrdenErrorMessage = e;
        Vue.swal({
            title: 'Error',
            text: 'algo fallo durante la actualización',
            type: 'error',
            width: '350px',
            timer: 2000,
            position: 'top-end',
        });
    },

    SET_SHOW_CONFIRM_DELETE(state, payload) {
        state.show_confirm_delete = payload;
    },

    SET_IS_UPDATE_FORM(state, payload) {
        state.is_update_form = payload;
        if (!payload) {
            state.nombre = null;
            state.capacitacion = null;
            state.compliance = null;
            state.inspeccion = null;
        }
    },

    OPEN_FORM(state, payload) {
        state.show_form = payload;
    },

    OPEN_DETAIL(state, payload) {
        state.show_detail = payload;
    },

    LOAD_FORM(state, payload) {
        state.id = payload._id;
        state.nombre = payload.nombre;
        state.capacitacion = payload.capacitacion;
        state.compliance = payload.compliance;
        state.inspeccion = payload.inspeccion;
    },

};