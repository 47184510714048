import api from './api';
import Cookies from "js-cookie";

const sucursalApi = {
    getSucursales(){
        return api.axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
        .get(`${process.env.VUE_APP_IP}/api/sucursales`); //Sucursal
    },

    postSucursal({
       direccion,
       cliente,
       telefono,
       localidad,
       email,
       secondEmail,
    })  {
        return api
        .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
        .post(`${process.env.VUE_APP_IP}/api/sucursales`,{
          direccion,
          cliente_id: cliente._id,
          telefono,
          localidad_id: localidad._id,
          email,
          secondEmail,
        });
    },

    updateSucursal({
      id,
      direccion,
      cliente,
      telefono,
      localidad,
      email,
      secondEmail,
    }) {
        return api
        .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
        .put(`${process.env.VUE_APP_IP}/api/sucursales/${id}`, {
            direccion,
            cliente_id: cliente._id,
            telefono,
            localidad_id: localidad._id,
            email,
            secondEmail,
        });
    },

    removeSucursal(id){
        return api
            .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
            .delete(`${process.env.VUE_APP_IP}/api/sucursales/${id}`);
    }
};

export default sucursalApi;