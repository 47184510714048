import { defaultState } from './config';
import Vue from 'vue';
import Cookies from "js-cookie";

export const mutations = {
    RESET(state) {
        state.provincia = null;
        state.nombre = null;
        state.codigoPostal = null;
        state.pais = null;
    },
       
    GET_LOCALIDADES_LOADING(state){
        state.getLocalidadesLoading = true;
        state.getLocalidadesSuccess = false;
        state.getLocalidadesError = false;
        state.getLocalidadesErrorMessage = '';
        state.clientes = [];
    },
    GET_LOCALIDADES_SUCCESS(state, localidades) {
        state.getLocalidadesLoading = false;
        state.getLocalidadesSuccess = true;
        state.localidades= [];
        /*
        localidades.sort(function(a, b) {
        var emlA = a.eml.toUpperCase(); // ignore upper and lowercase
        var emlB = b.eml.toUpperCase(); // ignore upper and lowercase
        if (emlA < emlB) {
          return -1;
        }
        if (emlA > emlB) {
          return 1;
        }
        return 0;
      });*/
      localidades.map((localidad, index) => {
        Vue.set(state.localidades, index, { ...localidad });
      });
    },
    GET_LOCALIDADES_ERROR(state, e) {
        state.getLocalidadesLoading = false;
        state.getLocalidadesError = true;
        state.getLocalidadesErrorMessage = e;
        console.log('error al obtener la localidad');
        //Cookies.remove('token') 
    },

    OPEN_FORM(state, payload) {
        state.show_form = payload;
    },

    LOAD_FORM(state, payload) {
        state.id = payload._id;
        state.provincia = payload.provincia;
        state.nombre = payload.nombre;
        state.codigoPostal = payload.codigoPostal;
        state.pais = payload.pais;
    },

    SET_SHOW_CONFIRM_DELETE(state, payload) {
        state.show_confirm_delete = payload;
    },

    SET_PROVINCIA(state, payload) {
        state.provincia = payload;
    },

    SET_NOMBRE(state, payload) {
        state.nombre = payload;
    },

    SET_CODIGOPOSTAL(state, payload) {
        state.codigoPostal = payload;
    },
    
    SET_PAIS(state, payload) {
        state.pais = payload;
    },

    SET_IS_UPDATE_FORM(state, payload) {
        state.is_update_form = payload;
        if (!payload) {
          state.provincia = null;
          state.nombre = null;
          state.codigoPostal = null;
          state.pais = null;
        }
    },

    REMOVE_LOCALIDAD_SUCCESS(state) {
        state.removeLocalidadLoading = false;
        state.removeLocalidadSuccess = true;
    },
    REMOVE_LOCALIDAD_ERROR(state, e) {
        state.removeLocalidadLoading = false;
        state.removeLocalidadError = true;
        state.removeLocalidadErrorMessage = e;
    },
    POST_LOCALIDAD_LOADING(state){
        state.postLocalidadLoading = true;
        state.postLocalidadSuccess = false;
        state.postLocalidadError = false;
        state.postLocalidadErrorMessage = '';
    },
    POST_LOCALIDAD_SUCCESS(state) {
        state.postLocalidadLoading = false;
        state.postLocalidadSuccess = true;
        Vue.swal({
            title: 'Localidad registrada',
            type: 'success',
            width: '300px',
            timer: 2000,
            position: 'top-end',
        })
    },
    POST_LOCALIDAD_ERROR(state, e) {
        state.postLocalidadLoading = false;
        state.postLocalidadError = true;
        state.postLocalidadErrorMessage = e;
    },
    UPDATE_LOCALIDAD_SUCCESS(state) {
        state.updateLocalidadLoading = false;
        state.updateLocalidadSuccess = true;
    },
    UPDATE_LOCALIDAD_ERROR(state, e) {
        state.updateLocalidadLoading = false;
        state.updateLocalidadError = true;
        state.updateLocalidadErrorMessage = e;
        Vue.swal({
            title: 'Error',
            text: e,
            type: 'error',
            width: '300px',
            timer: 2000,
            position: 'top-end',
        })
    },
    /*
    
    UPDATE_LOCALIDAD_LOADING(state){
        state.updateLocalidadThemeLoading = true;
        state.updateLocalidadThemeSuccess = false;
        state.updateLocalidadThemeError = false;
        state.updateLocalidadThemeErrorMessage = '';
    },

    */
};