/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import Vue from "vue";
import Vuex from "vuex";

import app from "./modules/app";
import localidad from "./modules/localidad";
import cliente from "./modules/cliente";
import sucursal from "./modules/sucursal";
import provincia from "./modules/provincia";
import rubro from "./modules/rubro";
import iva from "./modules/iva";
import empleado from "./modules/empleado";
import tipoOrden from "./modules/tipoOrden";
import orden from "./modules/orden";
import user from "./modules/user";
import capacitacion from "./modules/capacitacion";
import notificacion from "./modules/notificacion";
import pdf from "./modules/pdf";
import tecnicos from "./modules/tecnicos";


Vue.use(Vuex);

// Create a new store
export default new Vuex.Store({
  modules: {
    app: {
      namespaced: true,
      ...app,
    },
    localidad: {
      namespaced: true,
      ...localidad,
    },
    cliente: {
      namespaced: true,
      ...cliente,
    },
    sucursal: {
      namespaced: true,
      ...sucursal,
    },
    provincia: {
      namespaced: true,
      ...provincia,
    },
    rubro: {
      namespaced: true,
      ...rubro,
    },
    iva: {
      namespaced: true,
      ...iva,
    },
    empleado: {
      namespaced: true,
      ...empleado,
    },
    tipoOrden: {
      namespaced: true,
      ...tipoOrden,
    },
    orden: {
      namespaced: true,
      ...orden,
    },
    user: {
      namespaced: true,
      ...user,
    },
    capacitacion: {
      namespaced: true,
      ...capacitacion,
    },
    notificacion: {
      namespaced: true,
      ...notificacion,
    },
    pdf: {
      namespaced: true,
      ...pdf,
    },
    tecnicos: {
      namespaced: true,
      ...tecnicos,
    },
    documentos: [],
  },
  //actions,
  //getters,
  //mutations,
  //state
});

//export default store
