<template>
	<v-container>
		<h3 class="mb-4">{{ title_form }}</h3>
		<v-card class="my-2">
			<v-stepper v-model="step">
				<!-- HEADER -->
				<v-stepper-header>
					<v-stepper-step :complete="step > 1" step="1">
						Datos generales
					</v-stepper-step>
					<v-divider></v-divider>
					<v-stepper-step :complete="step > 2" step="2">
						Lista de asistentes
					</v-stepper-step>
					<v-divider></v-divider>
					<v-stepper-step step="3"
						>Confirmar y guardar</v-stepper-step
					>
				</v-stepper-header>
				<!-- BODY -->
				<v-stepper-items>
					<!-- STEP 1 -->
					<v-stepper-content step="1">
						<v-card>
							<step-one
								ref="template-step-1"
								:form_rules="form_rules"
							/>
							<v-card-actions>
								<v-btn flat @click="returnToOrdenes">
									Volver
								</v-btn>
								<v-spacer></v-spacer>

								<v-btn
									:disabled="step === 3"
									color="primary"
									depressed
									@click="nextStep"
								>
									Siguiente
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-stepper-content>
					<!-- STEP 2 -->
					<v-stepper-content step="2">
						<v-card>
							<step-two
								ref="template-step-2"
								:form_rules="form_rules"
							/>
							<v-card-actions>
								<v-btn flat @click="step--"> Anterior </v-btn>
								<v-spacer></v-spacer>
								<v-btn
									:disabled="step === 3"
									color="primary"
									depressed
									@click="nextStep"
								>
									Siguiente
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-stepper-content>
					<!-- STEP 3 -->
					<v-stepper-content step="3">
						<v-card>
							<step-three />
							<v-card-actions>
								<v-btn flat @click="step--"> Anterior </v-btn>
								<v-spacer></v-spacer>
								<v-btn
									color="primary"
									depressed
									@click="saveTraining"
								>
									Guardar
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-stepper-content>
				</v-stepper-items>
			</v-stepper>
		</v-card>
		<v-btn color="primary" @click="returnToOrdenes">
			Volver a Ordenes
		</v-btn>
	</v-container>
</template>

<script>
import StepOne from "./StepOne.vue";
import StepTwo from "./StepTwo.vue";
import StepThree from "./StepThree.vue";
import { mapActions, mapState } from "vuex";
import Router from "../../router";

export default {
	data() {
		return {
			form_rules: [(v) => !!v || "Este campo es requerido"],
			step: 1,
		};
	},

	components: {
		StepOne,
		StepTwo,
		StepThree,
	},

	mounted() {},

	computed: {
		...mapState({
			is_update_form: (state) => state.capacitacion.is_update_form,
		}),

		title_form() {
			return this.is_update_form
				? "Actualizar registro"
				: "Crear registro";
		},

		currentTitle() {
			switch (this.step) {
				case 1:
					return "Datos generales";
				case 2:
					return "Asistentes";
				default:
					return "Confirmar";
			}
		},
	},

	methods: {
		...mapActions({
			create: "capacitacion/postCapacitacion",
			update: "capacitacion/updateCapacitacion",
			getOrdenes: "orden/getOrdenes",
		}),

		nextStep() {
			if (
				(this.step == 1 &&
					this.$refs["template-step-1"].$refs[
						"form-step-1"
					].validate()) ||
				this.step != 1
			) {
				this.step++;
			}
		},

		saveTraining() {
			if (this.is_update_form) {
				this.update();
			} else {
				this.create();
			}
			this.getOrdenes();
			this.returnToOrdenes();
		},

		returnToOrdenes() {
			Router.push({ name: "ordenes" });
		},
	},
};
</script>
