import api from "./api";
import qs from "qs";
import Cookies from "js-cookie";

const headers = {
  "Content-Type": "application/application/json",
  "x-access-token": Cookies.get("token"),
};

const authApi = {
  login(email, password) {

    const coso = {
      email,
      password,
    };

    return api
      .axiosInstance()
      .post(`${process.env.VUE_APP_IP}/api/auth/signin`, coso);
  },

  /* refreshToken(refreshToken) {
    const details = {
      grant_type: "refresh_token",
      refresh_token: refreshToken,
      client_id: "admin",
    };
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return api
      .axiosInstance(null, null, headers)
      .post("/Auth", qs.stringify(details));
  }, */

  async verifyUser(id) {
    return await api
      .axiosInstance()
      .get(`/${process.env.VUE_APP_IP}/api/auth/verify/${id}`);
  },
};

export default authApi;
