<template>
  <div>
    <v-container>
      <material-notification class="my-3" color="warning" icon="mdi-alert">
        <div class="title font-weight-light mb-2">
          Registro de una capacitación
        </div>
        <div class="category">
          Se encuentra a punto de registrar una nueva capacitación. Revise los
          datos y al final de esta página presione
          <b>GUARDAR</b> si se encuentra seguro de los mismos.
        </div>
      </material-notification>

      <template>
        <v-container grid-list-md text-xs-center>
          <v-list two-line subheader>
            <v-subheader>Datos generales</v-subheader>
            <v-layout row wrap>
              <!-- 1º Columna -->
              <v-flex xs4 class="flex-column">
                <!-- Sucursal -->
                <v-list-tile avatar>
                  <v-list-tile-action>
                    <v-icon>store_mall_directory</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title> Cliente/Sucursal: </v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ sucursal_formateada }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <!-- Fecha y hora de inicio -->
                <v-list-tile avatar>
                  <v-list-tile-action>
                    <v-icon>event</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      Fecha y hora de inicio:
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ fecha_inicio_formateada }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>

                <!-- Planilla -->
                <v-list-tile avatar>
                  <v-list-tile-action>
                    <v-icon>attachment</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      ¿Se adjuntó algún archivo?
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ planilla_formateada }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-flex>
              <!-- 2º Columna -->
              <v-flex xs4 class="flex-column">
                <!-- Tipo de orden -->
                <v-list-tile avatar>
                  <v-list-tile-action>
                    <v-icon>school</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      Capacitación:
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ tipoOrden.nombre }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <!-- Número de orden -->
                <v-list-tile avatar>
                  <v-list-tile-action>
                    <v-icon>numbers</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Número de orden:</v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ numeroorden }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <!-- Fecha y hora de fin -->
                <v-list-tile avatar>
                  <v-list-tile-action>
                    <v-icon>event</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Fecha y hora de fin:</v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ fecha_fin_formateada }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-flex>
              <!-- 3º Columna -->
              <v-flex xs4 class="flex-column">
              <!-- Titulo Capacitación -->
              <v-list-tile avatar>
                  <v-list-tile-action>
                    <v-icon>edit</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      Título de la Capacitación:
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ tituloCapacitacion }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <!-- Es presencial -->
                <v-list-tile avatar>
                  <v-list-tile-action>
                    <v-icon>front_hand</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      ¿Actividad presencial?
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ actividad_presencial_formateada }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>

                <!-- Fecha expiración -->
                <v-list-tile avatar>
                  <v-list-tile-action>
                    <v-icon>notification_important</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Fecha de expiración:</v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ fecha_expiracion_formateada }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-flex>
            </v-layout>
          </v-list>

          <v-divider class="my-4" />

          <v-list two-line subheader>
            <v-subheader>Participantes</v-subheader>
            <v-card-title>
              <v-list-tile avatar>
                <v-list-tile-action>
                  <v-icon>people_alt</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>
                    Cantidad de participantes:
                  </v-list-tile-title>
                  <v-list-tile-sub-title>
                    {{ cantidad_participantes }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Filtrar"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>

            <v-layout row wrap>
              <v-flex
                v-for="attendee in attendees_filter"
                :key="`${attendee}`"
                xs3
              >
                <v-list-tile avatar>
                  <v-list-tile-action>
                    <v-icon>noise_control_off</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      {{ `${attendee.apellido} ${attendee.nombre}` }}
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ attendee.documento }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-flex>
            </v-layout>
          </v-list>
        </v-container>
      </template>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: [],

  data() {
    return {
      search: null,
    };
  },

  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },

  computed: {
    ...mapState({
      tipoOrden: (state) => state.capacitacion.tipoOrden,
      tituloCapacitacion: (state) => state.capacitacion.tituloCapacitacion,
      sucursal: (state) => state.capacitacion.sucursal,
      numeroorden: (state) => state.capacitacion.numeroorden,
      ispresencial: (state) => state.capacitacion.ispresencial,
      horainicio: (state) => state.capacitacion.horainicio,
      horafin: (state) => state.capacitacion.horafin,
      fechavencimiento: (state) => state.capacitacion.fechavencimiento,
      fechainicio: (state) => state.capacitacion.fechainicio,
      fechafin: (state) => state.capacitacion.fechafin,
      attendees: (state) => state.capacitacion.attendees,
      planilla: (state) => state.capacitacion.planilla,
    }),

    fecha_expiracion_formateada() {
      return this.formatDate(this.fechavencimiento);
    },

    fecha_fin_formateada() {
      if (this.fechafin)
        return `${this.formatDate(this.fechafin)} ${this.horafin}`;

      return `${this.horafin}`;
    },

    fecha_inicio_formateada() {
      return `${this.formatDate(this.fechainicio)} ${this.horainicio}`;
    },

    sucursal_formateada() {
      return `${this.sucursal?.cliente?.razonSocial} | ${this.sucursal?.localidad?.nombre} | ${this.sucursal?.direccion}`;
    },

    planilla_formateada() {
      return this.planilla
        ? "Se ha adjuntado un archivo"
        : "No se ha adjuntado ningún archivo";
    },

    actividad_presencial_formateada() {
      return this.ispresencial
        ? "Esta es una actividad presencial"
        : "No es una actividad presencial";
    },

    cantidad_participantes() {
      const cant = this.attendees.length;
      switch (cant) {
        case 0:
          return `La actividad no posee ningún participante registrado.`;
        case 1:
          return `La actividad cuenta por ahora con solo un participante registrado.`;
        default:
          return `La actividad posee ${cant} participantes registrados.`;
      }
    },

    attendees_filter() {
      const valid = this.search && this.search.trim().length > 0;
      if (valid) {
        return this.attendees.filter(
          (item) =>
            item.apellido.search(this.search) > -1 ||
            item.nombre.search(this.search) > -1 ||
            item.documento.search(this.search) > -1
        );
      }
      return this.attendees;
    },
  },
};
</script>