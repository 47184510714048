export const defaultState = function() {
    return {

        //Action getRubros
        getRubrosLoading: false,
        getRubrosSuccess: false,
        getRubrosError: false,
        getRubrosErrorMessage: '',
        rubros:[],
        //Action postRubro
        postRubroLoading: false,
        postRubroSuccess: false,
        postRubroError: false,
        postRubroErrorMessage: '',
        //Action updateRubroTheme
        updateRubroLoading: false,
        updateRubroSuccess: false,
        updateRubroError: false,
        updateRubroErrorMessage: '',
        //Action removeRubro
        removeRubroLoading: false,
        removeRubroSuccess: false,
        removeRubroError: false,
        removeRubroErrorMessage: ''
    }
}