<template>
	<div>
		<core-filter />
		<core-toolbar />
		<core-view />
		<core-drawer v-if="user.login" />
	</div>
</template>
<script>
//creo que tengo que importar estos componentes de arriba
import Cookies from "js-cookie";
import { mapActions } from 'vuex'
//
export default {
	name: 'AppLayout',
	computed: {
		user() {
			return this.$store.state.user
		}
	},
	methods: {
		...mapActions({
			loadUser: 'user/loadUser',
			logoutUser: 'user/logoutUser'
		})
	},
	mounted() {
		if (Cookies.get('token')) {
			this.loadUser()
		}
	}
}
</script>
