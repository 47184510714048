import provinciaApi from '../../../api/provinciaApi';

export default {
    getProvincias({ commit }) {
        return provinciaApi.getProvincias()
            .then(response => {
                let provincias = response.data;
                console.log(provincias)
                commit("GET_PROVINCIAS_SUCCESS", provincias);
            })
            .catch(e => {
                commit("GET_PROVINCIAS_ERROR", e);
            });
    },
};