import { defaultState } from './config';
import Vue from 'vue';

export const mutations = {

    RESET(state) {
      state.cliente = null;
      state.localidad = null;
      state.direccion = null;
      state.telefono = null;
      state.email = null;
      state.secondEmail = null;
    },

    OPEN_DETAIL(state, payload) {
        state.show_detail = payload;
    },

    OPEN_FORM(state, payload) {
        state.show_form = payload;
    },

    SET_SHOW_CONFIRM_DELETE(state, payload) {
        state.show_confirm_delete = payload;
    },

    SET_IS_UPDATE_FORM(state, payload) {
        state.is_update_form = payload;
        if (!payload) {
          state.cliente = null;
          state.direccion = null;
          state.telefono = null;
          state.localidad = null;
          state.email = null;
          state.secondEmail = null;
        }
    },



    LOAD_FORM(state, payload) {
        state.id = payload._id;
        state.cliente = payload.cliente;
        state.direccion = payload.direccion;
        state.telefono = payload.telefono;
        state.localidad = payload.localidad;
        state.email = payload.email;
        state.secondEmail = payload.secondEmail;
    },

    GET_SUCURSALES_LOADING(state){
        state.getSucursalesLoading = true;
        state.getSucursalesSuccess = false;
        state.getSucursalesError = false;
        state.getSucursalesErrorMessage = '';
        state.clientes = [];
    },

    GET_SUCURSALES_SUCCESS(state, {sucursales}) {
        state.getSucursalesLoading = false;
        state.getSucursalesSuccess = true;
        state.sucursales= [];
        sucursales.map((sucursal, index) => {
            Vue.set(state.sucursales, index, { ...sucursal });
        });
    },

    SET_CLIENTE(state, payload) {
        state.cliente = payload;
    },

    SET_LOCALIDAD(state, payload) {
        state.localidad = payload;
    },

    SET_DIRECCION(state, payload) {
        state.direccion = payload;
    },

    SET_TELEFONO(state, payload) {
        state.telefono = payload;
    },

    SET_EMAIL(state, payload) {
        state.email = payload;
    },

    SET_SECONDEMAIL(state, payload) {
        state.secondEmail = payload;
    },

    GET_SUCURSALES_ERROR(state, e) {
        state.getSucursalesLoading = false;
        state.getSucursalesError = true;
        state.getSucursalesErrorMessage = e;
        Vue.swal({
            title: 'Error',
            text: e,
            type: 'error',
            width: '350px',
            timer: 2000,
            position: 'top-end',
        })
    },
    POST_SUCURSAL_LOADING(state){
        state.postSucursalLoading = true;
        state.postSucursalSuccess = false;
        state.postSucursalError = false;
        state.postSucursalErrorMessage = '';
    },
    POST_SUCURSAL_SUCCESS(state) {
        state.postSucursalLoading = false;
        state.postSucursalSuccess = true;
        Vue.swal({
            title: 'Registrada',
            text: `La sucursal fue añadida satisfactoriamente`,
            type: 'success',
            width: '350px',
            timer: 2000,
            position: 'top-end',
        })
    },
    POST_SUCURSAL_ERROR(state, e) {
        state.postSucursalLoading = false;
        state.postSucursalError = true;
        state.postSucursalErrorMessage = e;
        Vue.swal({
            title: 'Error',
            text: 'verifique los datos proporcionados',
            type: 'error',
            width: '350px',
            timer: 2000,
            position: 'top-end',
        });
    },
    UPDATE_SUCURSAL_SUCCESS(state) {
        state.updateSucursalThemeLoading = false;
        state.updateSucursalThemeSuccess = true;
    },
    UPDATE_SUCURSAL_ERROR(state, e) {
        state.updateSucursalLoading = false;
        state.updateSucursalError = true;
        state.updateSucursalErrorMessage = e;
        Vue.swal({
            title: 'Error',
            text: 'algo fallo durante la actualización',
            type: 'error',
            width: '350px',
            timer: 2000,
            position: 'top-end',
        });
    },

};