import tipoOrdenApi from "../../../api/tipoOrdenApi";

export default {
  getTiposOrdenes({ commit }) {
    return tipoOrdenApi
      .getTiposOrdenes()
      .then((response) => {
        let tiposordenes = response.data;
        commit("GET_TIPOSORDENES_SUCCESS", tiposordenes);
      })
      .catch((e) => {
        commit("GET_TIPOSORDENES_ERROR", e);
      });
  },

  postTipoOrden({ dispatch, commit, state }) {
    return tipoOrdenApi
      .postTipoOrden(state)
      .then((_response) => {
        commit("POST_TIPOORDEN_SUCCESS");
        dispatch("getTiposOrdenes");
      })
      .catch((e) => {
        commit("POST_TIPOORDEN_ERROR", e);
      });
  },

  updateTipoOrden({ commit, dispatch, state }) {
    return tipoOrdenApi
      .updateTipoOrden(state)
      .then((_response) => {
        dispatch("getTiposOrdenes");
        commit("UPDATE_TIPOORDEN_SUCCESS");
      })
      .catch((e) => {
        commit("UPDATE_TIPOORDEN_ERROR", e);
      });
  },

  removeTipoOrden({ dispatch, commit }, id) {
    commit("REMOVE_TIPOORDEN_LOADING");
    return tipoOrdenApi
      .removeTipoOrden(id)
      .then((_response) => {
        commit("REMOVE_TIPOORDEN_SUCCESS");
        dispatch("getTiposOrdenes");
      })
      .catch((e) => {
        commit("REMOVE_TIPOORDEN_ERROR", e);
      });
  },
  
};
