<template>
	<v-layout row justify-center>
		<v-dialog v-model="show_form" persistent max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">{{ title_form }}</span>
				</v-card-title>
				<v-card-text>
					<v-form ref="form-cliente" v-model="valid" lazy-validation>
						<v-container grid-list-md>
							<v-layout wrap>
								<v-flex xs12>
									<v-text-field
										label="Nombre"
										v-model="nombreFantasia"
										@change="setNombreFantasia"
										required
										:rules="form_rules"
										prepend-icon="contacts"
									/>
								</v-flex>
								<v-flex xs12>
									<v-text-field
										label="Razon Social"
										v-model="razonSocial"
										@change="setRazonSocial"
										required
										:rules="form_rules"
										prepend-icon="contacts"
									/>
								</v-flex>
								<v-flex xs6>
									<v-autocomplete
										:items="rubros"
										v-model="rubro"
										@change="setRubro"
										label="Rubro"
										item-text="nombre"
										outline
										required
										:rules="form_rules"
										return-object
										prepend-icon="cases"
									></v-autocomplete>
								</v-flex>
								<v-flex xs6>
									<v-autocomplete
										:items="categoriasAfip"
										v-model="categoriaAfip"
										@change="setCategoriaAfip"
										label="Categoría AFIP"
										item-text="nombre"
										outline
										required
										:rules="form_rules"
										return-object
										prepend-icon="cases"
									></v-autocomplete>
								</v-flex>
								<v-flex xs6>
									<v-text-field
										:disabled="is_update_form"
										label="CUIT/CUIL"
										v-model="cuit"
										@change="setCuit"
										required
										:rules="form_rules"
										prepend-icon="content_paste_search"
									/>
								</v-flex>

								<v-flex xs6>
									<v-text-field
										label="Teléfono"
										v-model="telefono"
										@change="setTelefono"
										required
										prepend-icon="mdi-phone"
									/>
								</v-flex>
								<v-flex xs6>
									<v-text-field
										:disabled="is_update_form"
										label="Email"
										v-model="email"
										@change="setEmail"
										:rules="[form_rules, isUnique]"
										prepend-icon="mdi-email"
									/>
								</v-flex>
								<v-flex xs6>
									<v-autocomplete
										:items="paises"
										v-model="pais"
										@change="setPais"
										label="País"
										item-text="nombre"
										outline
										required
										:rules="form_rules"
										return-object
										prepend-icon="mdi-map-marker-multiple"
									></v-autocomplete>
								</v-flex>

								<v-flex>
									<input
										type="file"
										label="Adjuntar imagen"
										@change="uploadFile"
									/>
								</v-flex>
							</v-layout>
						</v-container>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" flat @click="closeForm">
						Cerrar
					</v-btn>
					<v-btn color="blue darken-1" flat @click="save"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-layout>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import axios from 'axios'
import Cookies from 'js-cookie'

export default {
	data: () => ({
		dialog: false,
		valid: true,
		form_rules: [(v) => !!v || 'Este campo es requerido']
	}),

	mounted() {
		axios
			.get(`${process.env.VUE_APP_IP}/api/categorias`, {
				headers: { 'x-access-token': Cookies.get('token') }
			})
			.then((response) => {
				this.categoriasAfip = response.data
			})

		axios
			.get(`${process.env.VUE_APP_IP}/api/paises`, {
				headers: { 'x-access-token': Cookies.get('token') }
			})
			.then((response) => {
				this.paises = response.data
			})
	},

	watch: {
		is_update_form(newState, oldTitle) {
			if (!newState) this.resetForm()
		}
	},

	computed: {
		...mapState({
			rubros: (state) => state.rubro.rubros,
			rubro: (state) => state.cliente.rubro,
			nombreFantasia: (state) => state.cliente.nombreFantasia,
			razonSocial: (state) => state.cliente.razonSocial,
			telefono: (state) => state.cliente.telefono,
			cuit: (state) => state.cliente.cuit,
			email: (state) => state.cliente.email,
			pais: (state) => state.cliente.pais,
			categoriaAfip: (state) => state.cliente.categoriaAfip,
			active: (state) => state.cliente.active,
			show_form: (state) => state.cliente.show_form,
			is_update_form: (state) => state.cliente.is_update_form,
			image: (state) => state.cliente.image
		}),

		title_form() {
			return this.is_update_form ? 'Actualizar cliente' : 'Crear cliente'
		}
	},

	methods: {
		...mapMutations({
			setNombreFantasia: 'cliente/SET_NOMBRE_FANTASIA',
			setRubro: 'cliente/SET_RUBRO',
			setPais: 'cliente/SET_PAIS',
			setCategoriaAfip: 'cliente/SET_CATEGORIA_AFIP',
			setTelefono: 'cliente/SET_TELEFONO',
			setRazonSocial: 'cliente/SET_RAZONSOCIAL',
			setCuit: 'cliente/SET_CUIT',
			setEmail: 'cliente/SET_EMAIL',
			onFileSelected: 'cliente/SET_IMAGE',
			setActive: 'cliente/SET_ACTIVE',
			openForm: 'cliente/OPEN_FORM',
			setImage: 'cliente/SET_IMAGE',
			resetCliente: 'cliente/RESET'
		}),

		...mapActions({
			postCliente: 'cliente/postCliente',
			updateCliente: 'cliente/updateCliente'
		}),

		formatDate(date) {
			if (!date) return null
			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},

		parseDate(date) {
			if (!date) return null
			const [month, day, year] = date.split('/')
			return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
		},

		resetForm() {
      this.resetCliente()
			this.valid = true
		},

		isUnique(val) {
			if (val == '') {
				return `Name "${val}" already exist`
			} else {
				return true
			}
		},

		uploadFile(e) {
			this.setImage(e.target.files[0])
		},

		closeForm() {
			this.resetForm()
			this.$refs['form-cliente'].reset()
			this.openForm(false)
		},

		save() {
			if (this.$refs['form-cliente'].validate()) {
				if (this.is_update_form) {
					this.updateCliente()
				} else {
					this.postCliente()
				}
        this.resetForm()
				this.openForm(false)
			}
		}
	}
}
</script>
