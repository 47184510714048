<template>
	<v-layout row justify-center>
		<v-dialog v-model="show_form" persistent max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">{{ title_form }}</span>
				</v-card-title>
				<v-card-text>
					<v-form
						ref="form-tipoOrden"
						v-model="valid"
						lazy-validation
					>
						<v-container grid-list-md>
							<v-layout wrap>
								<v-flex xs12>
									<v-text-field
										label="Nombre"
										v-model="nombre"
										@change="setNombre"
										required
										:rules="form_rules"
										prepend-icon="mdi-rename-box"
									></v-text-field>
								</v-flex>
								<v-flex xs12>
									<v-checkbox
										label="Capacitacion"
										v-model="capacitacion"
										@change="setCapacitacion"
										return-object
										true-value
									></v-checkbox>
								</v-flex>
								<v-flex xs12>
									<v-checkbox
										label="Cumplimiento Normativo"
										v-model="compliance"
										@change="setCompliance"
										return-object
										true-value
									></v-checkbox>
								</v-flex>
								<v-flex xs12>
									<v-checkbox
										label="Inspección"
										v-model="inspeccion"
										@change="setInspeccion"
										return-object
										true-value
									></v-checkbox>
								</v-flex>
							</v-layout>
						</v-container>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" flat @click="closeForm">
						Cerrar
					</v-btn>
					<v-btn color="blue darken-1" flat @click="save"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-layout>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
	data: () => ({
		dialog: false,
		valid: true,
		doesUserExist: null,
		form_rules: [(v) => !!v || 'Este campo es requerido']
	}),

	mounted() {},

	watch: {
		is_update_form(newState, oldTitle) {
			if (!newState) this.resetForm()
		}
	},

	computed: {
		...mapState({
			tiposOrden: (state) => state.tipoOrden.tiposOrdenes,
			nombre: (state) => state.tipoOrden.nombre,
			capacitacion: (state) => state.tipoOrden.capacitacion,
			compliance: (state) => state.tipoOrden.compliance,
			inspeccion: (state) => state.tipoOrden.inspeccion,
			show_form: (state) => state.tipoOrden.show_form,
			is_update_form: (state) => state.tipoOrden.is_update_form
		}),

		title_form() {
			return this.is_update_form
				? 'Actualizar registro'
				: 'Crear registro'
		}
	},

	methods: {
		...mapMutations({
			setNombre: 'tipoOrden/SET_NOMBRE',
			setCapacitacion: 'tipoOrden/SET_CAPACITACION',
			setCompliance: 'tipoOrden/SET_COMPLIANCE',
			setInspeccion: 'tipoOrden/SET_INSPECCION',
			openForm: 'tipoOrden/OPEN_FORM',
			resetTipoOrden: 'tipoOrden/RESET'
		}),

		...mapActions({
			postTipoOrden: 'tipoOrden/postTipoOrden',
			updateTipoOrden: 'tipoOrden/updateTipoOrden'
		}),

		resetForm() {
			this.valid = true
			this.resetTipoOrden()
		},

		closeForm() {
			this.resetForm()
			this.$refs['form-tipoOrden'].reset()
			this.openForm(false)
		},

		save() {
			if (this.capacitacion || this.compliance || this.inspeccion) {
				if (this.$refs['form-tipoOrden'].validate()) {
					if (this.is_update_form) {
						this.updateTipoOrden()
					} else {
						this.postTipoOrden()
					}

					this.closeForm(false)
				}
			} else {
				Vue.swal({
					type: 'warning',
					title: 'Seleccione un tipo de Orden'
				})
			}
		}
		/*
    checkDuplicate(val) {
      // write your api call and return the below statement if it already exist
      if ( val === "tiposOrdenes.item.nombre") {
        return `Name "${val}" already exist`;
      } else {
        return true;
      }
    },
    */
	}
}
</script>
