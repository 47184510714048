<template>
  <v-layout row justify-center>
    <v-dialog v-model="show_form" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ title_form }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form-rubro" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    label="Nombre"
                    v-model="nombre"
                    @change="setNombre"
                    required
                    :rules="form_rules"
                    prepend-icon="mdi-rename-box"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-checkbox
                    label="Control Contratista"
                    v-model="controlContratista"
                    @change="setControlContratista"
                    return-object
                  ></v-checkbox>
                </v-flex>
                <!--
                <v-flex xs12>
                  <v-text-field
                    label="CIIU"
                    hint="campo opcional"
                    :rules="form_rules"
                    required
                    prepend-icon
                  />
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="Descripcion (opcional)"
                    required
                    :rules="form_rules"
                    prepend-icon
                  />
                </v-flex>
                -->
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat @click="closeForm"> Cerrar </v-btn>
          <v-btn color="blue darken-1" flat @click="save">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data: () => ({
    dialog: false,
    valid: true,
    form_rules: [(v) => !!v || "Este campo es requerido"],
  }),

  mounted() {},

  watch: {
    is_update_form(newState, oldTitle) {
      if (!newState) this.resetForm();
    },
  },

  computed: {
    ...mapState({
      rubros: (state) => state.rubro.rubros,
      nombre: (state) => state.rubro.nombre,
      controlContratista: (state) => state.rubro.controlContratista,
      show_form: (state) => state.rubro.show_form,
      is_update_form: (state) => state.rubro.is_update_form,
    }),

    title_form() {
      return this.is_update_form ? "Actualizar registro" : "Crear registro";
    },

  },

  methods: {
    ...mapMutations({
      setNombre: "rubro/SET_NOMBRE",
      setControlContratista: "rubro/SET_CONTROLCONTRATISTA",
      openForm: "rubro/OPEN_FORM",
      resetRubro: "rubro/RESET",
    }),

    ...mapActions({
      postRubro: "rubro/postRubro",
      updateRubro: "rubro/updateRubro",
    }),

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    resetForm() {
      this.resetRubro()
      this.valid = true;
      this.$refs["form-rubro"].resetValidation();
    },

    closeForm() {
      this.resetForm();
      this.$refs["form-rubro"].reset();
      this.openForm(false);
    },

    save() {
      if (this.$refs["form-rubro"].validate()) {
        if (this.is_update_form) {
          this.updateRubro();
        } else {
          this.postRubro();
        }
        this.resetForm();
      }
    },
  },
};
</script>

