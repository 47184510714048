export const defaultState = function() {
    return {
        //Action getNotificaciones
        getNotificacionesLoading: false,
        getNotificacionesSuccess: false,
        getNotificacionesError: false,
        getNotificacionesErrorMessage: '',
        notificaciones:[],
        //Action postNotificacion
        postNotificacionLoading: false,
        postNotificacionSuccess: false,
        postNotificacionError: false,
        postNotificacionErrorMessage: '',
        //Action updateNotificacion
        updateNotificacionLoading: false,
        updateNotificacionSuccess: false,
        updateNotificacionError: false,
        updateNotificacionErrorMessage: '',
    }
}