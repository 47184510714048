import { defaultState } from "./config";

const state = Object.assign(
  {
    id: null,
    cliente: null,
    nombre: null,
    apellido: null,
    documento: null,
    cuit: null,
    tipo: null,
    fechadeingreso: null,
    show_confirm_delete: false,
    show_form: false,
    show_detail: false,
    is_update_form: false,
    sucursal: null,
  },
  defaultState()
);

export default state;
