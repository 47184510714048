import tecnicosApi from '../../../api/tecnicosApi.js';

export default {
  async getTecnicos({ commit }) {
    return tecnicosApi.getTecnicos().then((res) => {
      commit('SET_TECNICOS', res.data);
    });
  },
  async createTecnico({ commit, dispatch }, payload) {
    return tecnicosApi.postTecnico(payload).then(res => {
      dispatch('getTecnicos');
    });
  },
  updateTecnico({ commit, dispatch }, payload) {
    return tecnicosApi.updateTecnico(payload).then(res => {
      dispatch('getTecnicos');
    });
  },
  async removeTecnico({ commit, dispatch }, id) {
    return tecnicosApi.removeTecnico({ id }).then(res => {
      dispatch('getTecnicos');
    });
  }
};