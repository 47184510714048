<template>
	<v-layout row justify-center>
		<v-dialog v-model="show_form" persistent max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">Importar Empleados</span>
				</v-card-title>
				<v-card-text>
					<v-form ref="form-empleado" v-model="valid" lazy-validation>
						<v-container grid-list-md>
							<v-layout wrap>
								<v-flex xs12>
									<v-autocomplete
										:items="clientes"
										v-model="cliente"
										label="Cliente"
										item-text="nombreFantasia"
										:rules="form_rules"
										return-object
										outline
										required
										prepend-icon="mdi-account-outline"
									/>
								</v-flex>
								<v-flex xs12>
									<input
										type="file"
										name="excel"
										id="excel"
										@change="validateFile"
										required
									/>
								</v-flex>
								<v-flex xs12>
									<v-alert
										v-model="fileValid"
										outline
										type="success"
										>Archivo válidado
									</v-alert>
									<v-alert
										v-model="fileHasErrors"
										outline
										type="error"
										>Archivo inválido, por favor utilice uno
										de los siguientes formatos:
										<b>.xlsx</b>
									</v-alert>
								</v-flex>
							</v-layout>
						</v-container>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" flat @click="closeForm">
						Cerrar
					</v-btn>
					<v-btn color="blue darken-1" flat @click="save">
						{{ loading ? 'Importando...' : 'Importar'  }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-layout>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
	props: {
		show_form: {
			type: Boolean,
			required: true
		}
	},
	data: () => ({
		dialog: false,
		valid: true,
		fileValid: false,
		fileHasErrors: false,
		form_rules: [(v) => !!v || 'Este campo es requerido'],
		fileForm: null,
		cliente: null,
		loading: false,
	}),

	mounted() {},

	computed: {
		...mapState({
			user: (state) => state.user,
			clientes: (state) => state.cliente.clientes
		}),
		is_admin() {
			return this.user.isAdmin
		}
	},

	methods: {
		...mapActions({
			postEmpleadoExcel: 'empleado/postEmpleadoExcel'
		}),
		resetForm() {
			this.valid = true
		},
		closeForm() {
			this.$emit('close')
		},
		validateFile() {
			const fileInput = document.getElementById('excel')
			const filePath = fileInput.value

			// allowed file types
			var allowedExtensions = /(\.xlsx)$/i

			if (!allowedExtensions.exec(filePath)) {
				this.fileHasErrors = true
				this.fileValid = false
				fileInput.value = ''
			} else {
				this.fileHasErrors = false
				this.fileValid = true

				if (fileInput.files && fileInput.files[0]) {
					this.fileForm = fileInput.files[0]
				}
			}
		},
		async save() {
			if (this.fileValid) {
				this.loading = true;
				const formData = new FormData()
				formData.append('excelFile', this.fileForm, this.fileForm.name)

				try {
					await this.postEmpleadoExcel({
						cliente_id: this.cliente._id,
						req: formData
					})

					this.resetForm()
					this.closeForm()
				} catch (error) {
					console.error('error en el form: ', error)
				} finally {
					this.loading = false
				}
			}
		}
	}
}
</script>
