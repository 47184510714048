<template>
  <v-app id="activation">
    <v-img class="img-success" src="/img/auditoria-background2.jpg">
      <v-content class="content">
        <v-container fluid fill-height>
          <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
              <v-card class="formOp">
                <v-toolbar color="primary">
                  <span class="title">Inicio de Sesión</span>
                </v-toolbar>
                <v-form
                  class="form-group"
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                  <v-text-field
                    v-model="pw"
                    :rules="pwRules"
                    label="Contraseña"
                    required
                    :type="showPw"
                  ></v-text-field>

                  <v-text-field
                    v-model="confirmPw"
                    ref="confirmPw"
                    :rules="confirmPwRules.concat(pwConfirmationRule)"
                    label="Confirmar contraseña"
                    required
                    :type="showPw"
                  ></v-text-field>
                  <v-checkbox
                    class="checkbox"
                    v-model="checkbox"
                    label="Mostrar contraseñas"
                    @change="$v.checkbox.$touch()"
                  ></v-checkbox>
                  <div id="messageReq">
                    * La contraseña debe contener 8 caracteres mínimo, una
                    mayúscula, una minúscula y un número.
                  </div>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="btn-submit" color="success" @click="validate"
                      >Activar cuenta</v-btn
                    >
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-content>
    </v-img>
  </v-app>
</template>

<script>
import userApi from "../../api/userApi";
export default {
  name: "Activate",
  checkbox: {
    checked(val) {
      return val;
    },
  },
  mounted() {},
  data: () => ({
    showPw: "password",
    valid: true,
    pw: "",
    pwRules: [
      (v) => !!v || "Contraseña Requerida",
      (v) =>
        /^[a-zA-Z\d].{7,}$/.test(v) ||
        "La contraseña debe tener al menos 8 caracteres",
      (v) =>
        /[A-Z]/.test(v) || "La contraseña debe contener al menos una mayúscula",
      (v) =>
        /[a-z]/.test(v) || "La contraseña debe contener al menos una minúscula",
      (v) =>
        /[|\d]/.test(v) || "La contraseña debe contener al menos un número",
    ],
    confirmPw: "",
    confirmPwRules: [(v) => !!v || "Debe confirmar la contraseña"],
    checkbox: false,
  }),
  computed: {
    pwConfirmationRule() {
      return () => this.pw === this.confirmPw || "Las contraseñas no coinciden";
    },
  },
  methods: {
    submit() {
      let datos = {
        id: this.$route.params.id,
        status: "VERIFIED",
        password: this.pw
      };
      userApi
        .updateUser(datos)
        .then(({ data }) => {
          window.location = "/login";
        })
        .catch(() => {
          console.log("error");
        });
    },
    validate(e) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        this.submit();
      } else {
        alert("¡formulario inválido!");
      }
    },
  },
  watch: {
    checkbox: function () {
      if (!this.checkbox) {
        this.showPw = "password";
        return this.showPw;
      } else {
        this.showPw = "text";
        return this.showPw;
      }
    },
  },
};
</script>

<style>
.title {
  font-weight: bolder;
  color: white;
  font-size: larger;
}
.content {
  zoom: 130%;
}
#activation {
  width: 100%;
  height: 100%;
}
.img-success {
  max-height: 100vh;
}
.form-group {
  padding: 25px 25px 25px 25px;
}
.btn-submit {
  height: 35px;
  bottom: -10px;
  right: -10px;
}
.content {
  margin-top: 9rem;
}
.formOp {
  background-color: white !important;
  border-color: transparent !important;
}

#messageReq {
  font-size: 0.6rem;
  color: red;
  cursor: default;
  text-shadow: 0px 0px 0.5px #000000;
}
.checkbox {
  zoom: 80%;
}
</style>
