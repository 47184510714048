import api from "./api";
import Cookies from "js-cookie";

const empleadoApi = {
  getEmpleados() {
     return api
      .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
      .get(`${process.env.VUE_APP_IP}/api/empleados`); / //Empleado
  },

  getEmpleadosCliente(cliente_id) {
    return api
      .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
      .get(
        `${process.env.VUE_APP_IP}/api/empleados/cliente/${cliente_id}`
      );
  },

  postEmpleado({
    nombre,
    apellido,
    fechadeingreso,
    tipo,
    documento,
    cuit,
    cliente,
    sucursal,
  }) {
    return api
      .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
      .post(`${process.env.VUE_APP_IP}/api/empleados`, {
        nombre,
        apellido,
        fechadeingreso,
        tipo,
        documento,
        cuit,
        cliente_id: cliente._id,
        sucursal_id: sucursal._id,
      });
  },

  removeEmpleado(id) {
    return api
      .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
      .delete(`${process.env.VUE_APP_IP}/api/empleados/${id}`);
  },

  updateEmpleado({
    id,
    nombre,
    apellido,
    fechadeingreso,
    tipo,
    documento,
    cuit,
    cliente,
    sucursal
  }) {
    return api
      .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
      .put(`${process.env.VUE_APP_IP}/api/empleados/${id}`, {
        nombre,
        apellido,
        fechadeingreso,
        tipo,
        documento,
        cuit,
        cliente_id: cliente._id,
        sucursal_id: sucursal._id,
      });
  },

  postEmpleadoExcel({
    cliente_id,
    req
  }) {
    return api
      .axiosInstance(null, null, { "x-access-token": Cookies.get("token"), 'Content-Type': 'multipart/form-data' })
      .post(`${process.env.VUE_APP_IP}/api/empleados/cliente/${cliente_id}`, req)
  }

};

export default empleadoApi;
