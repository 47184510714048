import Dashboard from "../views/Dashboard.vue";
import Training from "../views/Trainings/index.vue";
import UserProfile from "../views/UserProfile.vue";
import Clientes from "../views/Clientes/index.vue";
import Sucursales from "../views/Sucursales/index.vue";
import AddTraining from "../views/Trainings/index.vue";
import Localidades from "../views/Localidades/index.vue";
import Typography from "../views/Typography.vue";
import Empleados from "../views/Empleados/index.vue";
import TiposOrdenes from "../views/TiposOrden/index.vue";
import Ordenes from "../views/Ordenes/index.vue";
import Notificaciones from "../views/Notificaciones/index.vue";
import Icons from "../views/Icons.vue";
import Maps from "../views/Maps.vue";
import Notifications from "../views/Notifications.vue";
import Rubros from "../views/Rubros/index.vue";
import Login from "../views/auth/Login.vue";
import Activation from "../views/auth/Activate.vue";
import Tecnicos from '../views/Tecnicos/index.vue';
import Users from '../views/Users/index.vue';
import UsersValidate from '../views/Users/validate.vue';
import Documentacion from '../views/PDF/index.vue';
import DocumentacionTecnico from '../views/PDF/tecnico.vue';
import DocumentacionCliente from '../views/PDF/cliente.vue';
import AuthMiddleware from './middleware/auth.js';
import ActivationMiddleware from './middleware/activation';

export default [
  {
    path: "/",
    // Relative to /src/views
    name: "dashboard",
    component: Dashboard,
    meta: {
      middleware: [AuthMiddleware]
    },
  },
  {
    path: "/documentaciones",
    name: "Documentacion",
    component: Documentacion,
    meta: {
      middleware: [AuthMiddleware]
    },
  },
  {
    path: "/documentaciones/asignar",
    name: "DocumentacionTecnico",
    component: DocumentacionTecnico,
    meta: {
      middleware: [AuthMiddleware]
    },
  },
  {
    path: "/documentaciones/historial",
    name: "DocumentacionCliente",
    component: DocumentacionCliente,
    meta: {
      middleware: [AuthMiddleware]
    },
  },
  {
    path: "/user-profile",
    name: "user-profile",
    component: UserProfile,
    meta: {
      middleware: [AuthMiddleware]
    },
  },
  {
    path: "/tecnicos",
    name: "Tecnicos",
    component: Tecnicos,
    meta: {
      middleware: [AuthMiddleware]
    },
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    component: Users,
    meta: {
      middleware: [AuthMiddleware]
    },
  },
  {
    path: "/usuarios/validate/",
    name: "UsersValidate",
    component: UsersValidate,
    query: {
      token: 'string',
      id: 'string'
    }
  },
  {
    path: "/clientes",
    name: "Clientes",
    component: Clientes,
    meta: {
      middleware: [AuthMiddleware]
    },
  },
  {
    path: "/ordenes/add-training",
    name: "AddTraining",
    component: AddTraining,
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/sucursales",
    name: "Sucursales",
    component: Sucursales,
    meta: {
      middleware: [AuthMiddleware]
    },
  },
  {
    path: "/localidades",
    name: "Localidades",
    component: Localidades,
    meta: {
      middleware: [AuthMiddleware]
    },
  },
  {
    path: "/typography",
    name: "Typography",
    component: Typography,
    meta: {
      middleware: [AuthMiddleware]
    },
  },
  {
    path: "/empleados",
    name: "Empleados",
    component: Empleados,
    meta: {
      middleware: [AuthMiddleware]
    },
  },
  {
    path: "/tiposOrdenes",
    name: "TiposOrdenes",
    component: TiposOrdenes,
    meta: {
      middleware: [AuthMiddleware]
    },
  },
  {
    path: "/ordenes",
    name: "ordenes",
    component: Ordenes,
    meta: {
      middleware: [AuthMiddleware],
    },
  },

  {
    path: "/ordenes/training",
    name: "training",
    component: Training,
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/icons",
    name: "Icons",
    component: Icons,
    meta: {
      middleware: [AuthMiddleware]
    },
  },
  {
    path: "/maps",
    name: "Maps",
    component: Maps,
    meta: {
      middleware: [AuthMiddleware]
    },
  },
  {
    path: "/notificaciones",
    name: "notificaciones",
    component: Notificaciones,
    meta: {
      middleware: [AuthMiddleware]
    },
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
    meta: {
      middleware: [AuthMiddleware]
    },
  },
  {
    path: "/rubros",
    name: "Rubros",
    component: Rubros,
    meta: {
      middleware: [AuthMiddleware]
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/activation/:id",
    name: "Activation",
    component: Activation,
    meta: {
      middleware: [ActivationMiddleware]
    },
  },
];
