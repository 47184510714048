<template>
	<v-container>
		<h3 class="mb-4">Tecnicos</h3>

		<v-card v-if="is_admin" class="my-2">
			<v-card-title>
				<v-layout row wrap class="mb-2 px-2">
					<v-spacer></v-spacer>
					<v-flex xs3 class="px-2">
						<v-text-field
							v-model="search"
							append-icon="search"
							label="Filtrar"
							single-line
							hide-details
						/>
					</v-flex>
				</v-layout>
			</v-card-title>

			<v-data-table
				:headers="headers"
				:items="tecnicos_results"
				:pagination.sync="pagination"
			>
				<template v-slot:items="tecnicos">
					<td class="text-xs-center">
						{{ tecnicos.item.nombre }} {{ tecnicos.item.apellido }}
					</td>
					<td class="text-xs-center">
						<span v-if="tecnicos.item.cuit">{{
							tecnicos.item.cuit
						}}</span>
						<span v-else>-</span>
					</td>
					<td class="text-xs-center">{{ tecnicos.item.email }}</td>
					<td class="text-xs-center">
						<small>
							<b>{{ tecnicos.item.status }}</b>
						</small>
					</td>
					<td class="text-xs-center">
						{{ formatDate(tecnicos.item.createdAt.split('T')[0]) }}
					</td>
					<td class="justify-center layout px-2 py-4">
						<v-icon
							class="mr-2"
							small
							title="Ver Perfil Técnico"
							@click="openFormUpdate(tecnicos.item)"
						>
							edit
						</v-icon>
						<v-icon
							class="mr-2"
							small
							title="Eliminar Perfil Técnico"
							@click="shoudRemoveTecnico(tecnicos.item._id)"
						>
							delete
						</v-icon>
					</td>
				</template>
			</v-data-table>

			<v-card-actions class="layout justify-end py-4">
				<v-btn color="primary" @click="openFormCreate">
					Registrar Técnico
				</v-btn>
			</v-card-actions>
		</v-card>

		<v-card v-else class="my-2">
			<v-alert :value="true" type="error" outline>
				Usted no tiene acceso a esta funcionalidad.
			</v-alert>
		</v-card>

		<form-create
			:show-form="showFormNew"
			@close="closeFormNew"
		></form-create>
		<form-update
			:show-form="showFormEdit"
			@close="closeFormEdit"
		></form-update>
	</v-container>
</template>

<script>
import Vue from 'vue'
import FormCreate from './FormCreate.vue'
import FormUpdate from './FormUpdate.vue'
import { mapActions, mapState } from 'vuex'

export default {
	components: {
		FormCreate,
		FormUpdate
	},
	data: () => ({
		search: '',
		headers: [
			{
				sortable: true,
				text: 'Nombre',
				value: 'name',
				align: 'center'
			},
			{
				sortable: true,
				text: 'CUIT',
				value: 'cuit',
				align: 'center'
			},
			{
				sortable: true,
				text: 'E-mail',
				value: 'email',
				align: 'center'
			},
			{
				sortable: true,
				text: 'Estado',
				value: 'status',
				align: 'center'
			},
			{
				sortable: true,
				text: 'Fecha de alta',
				value: 'createdAt',
				align: 'center'
			},
			{
				sortable: false,
				text: 'Acciones',
				value: 'actions',
				align: 'center'
			}
		],
		pagination: {
			rowsPerPage: -1 // con -1 mustra "Todos"
		},
		showFormNew: false,
		showFormEdit: false
	}),
	mounted() {
		this.getTecnicos()
	},
	computed: {
		...mapState({
			user: (state) => state.user,
			tecnicos: (state) => state.tecnicos.tecnicos
		}),
		tecnicos_results() {
			const valid = this.search && this.search.trim().length > 0

			if (valid) {
				return this.tecnicos.filter((tecnico) =>
					Object.values(tecnico).some(
						(val) =>
							val != null &&
							val
								.toString()
								.toLowerCase()
								.includes(this.search.toLowerCase())
					)
				)
			}

			return this.tecnicos
		},
		is_admin() {
			return this.user.isAdmin
		}
	},
	methods: {
		...mapActions({
			getTecnicos: 'tecnicos/getTecnicos',
			removeTecnico: 'tecnicos/removeTecnico'
		}),
		formatDate(date) {
			if (!date) return null
			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
		openFormCreate() {
			this.showFormNew = true
		},
		async openFormUpdate(tecnico) {
			// dispatch action to select form
			await this.$store.commit('tecnicos/SET_TECNICO_SELECTED', tecnico)
			this.showFormEdit = true
		},
		closeFormNew() {
			this.showFormNew = false
		},
		closeFormEdit() {
			this.showFormEdit = false
		},
		shoudRemoveTecnico(id) {
			Vue.swal
				.fire({
					title: '¿Está seguro de eliminar este perfil?',
					type: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Sí, estoy seguro',
					cancelButtonText: 'Cancelar',
					reverseButtons: true
				})
				.then((result) => {
					if (result.value) {
						try {
							this.removeTecnico(id).then(() => {
								Vue.swal({
									title: 'Perfil eliminado',
									type: 'success'
								})
							})
						} catch (error) {
							Vue.swal({
								title: 'Error al eliminar perfil',
								type: 'Error'
							})
						}
					}
				})
		}
	}
}
</script>
