import rubroApi from '../../../api/rubroApi';

export default {
    getRubros({ commit }) {
        return rubroApi.getRubros()
            .then(response => {
                let rubros = response.data;
                commit("GET_RUBROS_SUCCESS", rubros);
            })
            .catch(e => {
                commit("GET_RUBROS_ERROR", e);
            });
    },

    postRubro({ dispatch, commit, state }) {
        return rubroApi
            .postRubro(state)
            .then((_response) => {
                commit("POST_RUBRO_SUCCESS");
                dispatch("getRubros");
            })
            .catch((e) => {
                commit("POST_RUBRO_ERROR", e);
            });
    },

    updateRubro({ commit, dispatch, state }) {
        return rubroApi
            .updateRubro(state)
            .then((_response) => {
                dispatch("getRubros");
                commit("UPDATE_RUBRO_SUCCESS");
            })
            .catch((e) => {
                commit("UPDATE_RUBRO_ERROR", e);
            });
    },

    removeRubro({ dispatch, commit }, id) {
        commit("REMOVE_RUBRO_LOADING");
        return rubroApi
            .removeRubro(id)
            .then((_response) => {
                commit("REMOVE_RUBRO_SUCCESS");
                dispatch("getRubros");
            })
            .catch((e) => {
                commit("REMOVE_RUBRO_ERROR", e);
            });
    },
};   