<template>
  <v-layout row justify-center>
    <v-dialog v-model="show_confirm_delete" persistent max-width="500">
      <v-card dark color="red">
        <v-card-title color="white" class="headline">
          Eliminar registro
        </v-card-title>

        <v-card-text color="white">
          Una vez que elimine el registro ya no podrá accerder a él. ¿Se
          encuentra seguro de hacerlo?
        </v-card-text>

        <v-card-actions class="px-0">
          <v-spacer></v-spacer>
          <v-btn
            color="white darken-1"
            flat="flat"
            @click="setShowConfirmDelete(false)"
          >
            Cancelar
          </v-btn>

          <v-btn color="white darken-1" flat="flat" @click="deleteRubro">
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  props: ["rubro_id"],

  data() {
    return {};
  },

  computed: {
    ...mapState({
      show_confirm_delete: (state) => state.rubro.show_confirm_delete,
    }),
  },

  methods: {
    ...mapActions({
      removeRubro: "rubro/removeRubro",
    }),

    ...mapMutations({
      setShowConfirmDelete: "rubro/SET_SHOW_CONFIRM_DELETE",
    }),

    deleteRubro() {
      this.removeRubro(this.rubro_id);
      this.setShowConfirmDelete(false);
    },
  },
};
</script>