import localidadApi from '../../../api/localidadApi';

export default {
    getLocalidades({ commit }) {
        return localidadApi
            .getLocalidades()
            .then((response) => {
                let localidades = response.data;
                commit("GET_LOCALIDADES_SUCCESS", localidades);
            })
            .catch((e) => {
                commit("GET_LOCALIDADES_ERROR", e);
            });
    },

    postLocalidad({ dispatch, commit, state }) {
        return localidadApi
            .postLocalidad(state)
            .then((_response) => {
                commit("POST_LOCALIDAD_SUCCESS");
                dispatch("getLocalidades");
            })
            .catch((e) => {
                commit("POST_LOCALIDAD_ERROR", e);
            });
    },

    removeLocalidad({ dispatch, commit }, id) {
        commit("REMOVE_LOCALIDAD_LOADING");
        return localidadApi
            .removeLocalidad(id)
            .then((_response) => {
                commit("REMOVE_LOCALIDAD_SUCCESS");
                dispatch("getLocalidades");
            })
            .catch((e) => {
                commit("REMOVE_LOCALIDAD_ERROR", e);
            });
    },

    updateLocalidad({ commit, dispatch, state }) {
        return localidadApi
            .updateLocalidad(state)
            .then((_response) => {
                dispatch("getLocalidades");
                commit("UPDATE_LOCALIDAD_SUCCESS");
            })
            .catch((e) => {
                commit("UPDATE_LOCALIDAD_ERROR", e);
            });
    },
};