<template>
	<dialog class="dialog--center" open v-show="state != 'hidden'">
		<v-flex xs3 class="dialog--header">
			<h3 class="dialog--title">{{ title }}</h3>
			<button class="button--list-item" @click="$emit('state', 'hidden')">
				Cerrar
			</button>
		</v-flex>
		<LoadingIndicator v-if="state == 'loading'"></LoadingIndicator>
		<div v-if="state == 'ready'">
			<slot></slot>
		</div>
	</dialog>
</template>
<script>
import LoadingIndicator from "./LoadingIndicator.vue";
export default {
	props: ["title", "state"],
	components: {
		LoadingIndicator,
	},
};
</script>
<style scoped>
.dialog--center {
	position: fixed;
	width: 80vw;
	height: calc(100vh - 5rem);
	max-height: calc(100vh - 5rem);
	overflow-y: auto;
	top: auto;
	bottom: 5rem;
	left: 10vw;
	padding: 1rem 2rem;
	z-index: 100000;
}
dialog .button--list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.dialog--header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-width: 100%;
}
dialog .button--list--item {
	margin: 0.25rem;
	padding: 0.25rem;
	font-size: 1rem;
	border-radius: 5px;
	background-color: teal;
	min-width: 40%;
	text-align: center;
	color: #fff;
}
dialog .button--list--item:visited {
	color: #fff;
}
</style>
