<template>
	<div class="validate">
		<v-container class="py-8">
			<v-layout row wrap justify-center>
				<v-flex xs12>
					<h3 class="mb-4 text-xs-center">
						VALIDACIÓN DE USUARIO
					</h3></v-flex
				>
				<v-flex xs10 md8 lg6>
					<v-card class="my-1">
						<v-card-text>
							<v-form
								ref="form-validation"
								@submit.prevent="sendForm"
							>
								<v-layout wrap class="justify-center">
									<v-flex xs12>
										<v-text-field
											v-model="form.password"
											label="Nueva Contraseña"
											id="password"
											:type="showPw ? 'text' : 'password'"
											prepend-icon="lock"
										></v-text-field>
										<v-checkbox
											class="checkbox"
											v-model="showPw"
											label="Mostrar contraseña"
										></v-checkbox>
									</v-flex>
									<v-flex xs6>
										<v-btn
											type="submit"
											class="d-block ml-auto"
											color="primary"
											:loading="loading"
											>Enviar</v-btn
										>
									</v-flex>
								</v-layout>
							</v-form>
						</v-card-text>
					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
</template>
<script>
import Cookies from "js-cookie";

export default {
	data() {
		return {
			valid: false,
			form: {
				token: "",
				password: "",
				id: "",
			},
			showPw: false,
			loading: false,
		};
	},
	methods: {
		async sendForm() {
			try {
				this.loading = true;

				const res = await this.$store.dispatch(
					"user/validateAccount",
					this.form
				);
				setTimeout(() => {
					this.$router.push("/login");
				}, 4000);
			} catch (error) {
				console.error(error);
			} finally {
				this.loading = false;
			}
		},
	},
	created() {
		if (Cookies.get("token")) {
			this.$store.dispatch("user/logoutUser");
		}
	},
	mounted() {
		const { id, token } = this.$route.query;
		this.form.id = id;
		this.form.token = token;
	},
};
</script>
