export const mutations = {
  SET_DOCUMENTOS(state, payload) {
    state.documentos = payload
  },
  SET_DOCUMENTOS_CLIENT(state, payload) {
    state.documentosClient = payload
  },
  SET_DOCUMENTO_SELECTED(state, payload) {
    state.documentoSelected = payload
  }
}