import api from "./api";
import Cookies from "js-cookie";

const notificacionApi = {
    getNotificaciones() {
        return api
            .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
            .get(`${process.env.VUE_APP_IP}/api/notificaciones`);
    }
};

export default notificacionApi;