<template>
	<v-layout row justify-center>
		<v-dialog v-model="showForm" persistent max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">Asignar Documentación</span>
				</v-card-title>
				<v-card-text>
					<v-form ref="form-asignar" v-model="valid" lazy-validation>
						<v-container grid-list-md>
							<v-layout wrap>
								<v-flex xs12>
									<v-autocomplete
										:items="clientes"
										v-model="cliente"
										label="Cliente"
										:rules="form_rules"
										item-text="nombreFantasia"
										return-object
										outline
										required
										prepend-icon="mdi-account-outline"
									/>
								</v-flex>
								<v-flex xs12>
									<input
										type="file"
										name="pdf"
										id="pdf2"
										@change="validateFile"
										required
									/>
								</v-flex>
								<v-flex xs12>
									<v-alert
										v-model="fileValid"
										outline
										type="success"
										>Archivo válidado
									</v-alert>
									<v-alert
										v-model="fileHasErrors"
										outline
										type="error"
										>Archivo inválido, por favor utilice uno
										de los siguientes formatos:
										<b>.pdf</b>
									</v-alert>
								</v-flex>
							</v-layout>
						</v-container>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn flat @click="closeForm">
						Cerrar
					</v-btn>
					<v-btn dark color="primary" depressed @click="sendForm">
						{{ loading ? 'Asignando...' : 'Asignar' }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-layout>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
	props: {
		showForm: {
			type: Boolean,
			required: true
		}
	},
	data: () => ({
		dialog: false,
		valid: true,
		fileValid: false,
		fileHasErrors: false,
		form_rules: [(v) => !!v || 'Este campo es requerido'],
		fileForm: null,
		cliente: null,
		loading: false
	}),

	mounted() {},

	computed: {
		...mapState({
			user: (state) => state.user,
			clientes: (state) => state.cliente.clientes,
		}),
		is_admin() {
			return this.user.isAdmin
		}
	},

	methods: {
		...mapActions({
			assignDocToClient: 'pdf/assignDocToClient'
		}),
		resetForm() {
			this.valid = true
		},
		closeForm() {
			const fileInput = document.getElementById('pdf2')
			this.cliente = null
			this.fileForm = null
			this.fileHasErrors = false
			this.fileValid = false
			fileInput.value = null
			this.$refs['form-asignar'].reset();
			this.$emit('close')
		},
		validateFile() {
			const fileInput = document.getElementById('pdf2')
			const filePath = fileInput.value

			// allowed file types
			var allowedExtensions = /(\.pdf)$/i

			if (!allowedExtensions.exec(filePath)) {
				this.fileHasErrors = true
				this.fileValid = false
				fileInput.value = ''
			} else {
				this.fileHasErrors = false
				this.fileValid = true

				if (fileInput.files && fileInput.files[0]) {
					this.fileForm = fileInput.files[0]
				}
			}
		},
		async sendForm() {
			//  cliente_id,file, oldfilename?
			try {
				this.loading = true

				const response = await this.assignDocToClient({
					cliente_id: this.cliente._id,
					file: this.fileForm
				})
				this.loading = false

				Vue.swal({
					type: 'success',
					title: 'Documento técnico creado'
				}).then(() => {
					this.closeForm()
				})
			} catch (error) {
				this.loading = false
				Vue.swal({
					type: 'error',
					title: 'Error al asignar documento'
				})
			}
		}
	}
}
</script>
