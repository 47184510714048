<template>
<v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" dark v-on="on">Registrar nueva Localidad</v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Nueva Localidad</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              
                <v-col cols="12" sm="6" md="4">
                  <v-text-field label="Nombre*" required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field label="Codigo Postal" hint="campo opcional"></v-text-field>
                  <v-flex xs12 sm6 d-flex>
                    <v-select
                      :items="provincias"
                      label="Provincias"
                      item-text="nombre"
                      item-value="id"
                      outline
                    ></v-select>
                    
                  </v-flex>
                </v-col>
                
              
            </v-container>
            <small>* campos requeridos</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Cerrar</v-btn>
            <v-btn color="blue darken-1" text @click="addCliente()">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>    
</template>

<script>
    //import Vue from 'vue'

    export default {
        name: "localidadCreate",
        data: () => ({
            dialog: false,
        }),
        methods:{
            addLocalidad() {
                this.$store.dispatch("localidad/postLocalidad", {
                    nombre: this.localidadNombre,
                    provincia_id: this.provincia.id
                });
                
            },
            deleteLocalidad (id) {
                this.$store.dispatch("localidad/removeLocalidad", {id});       
            },
        }
    }
</script>