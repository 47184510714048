const state = Object.assign(
    {
        email: null,
        cliente: null,
        tecnico: null,
        status: null,
        isAdmin: false,
        login: null,
        users: []
    },
);

export default state;