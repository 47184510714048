<template>
  <v-container
    fluid
    grid-list-xl
    fill-height>
    <v-layout
      v-if="notificaciones.length>0"
      justify-center
      align-center
    >
        <material-card color="green"
          v-for="(notificacion, index) in notificaciones"
          :key="notificacion.id"
          xs12
          lg12 
        >
          <div
            slot="header"
          >
            <div class="title font-weight-light mb-2"><b>{{index+1}})</b> {{notificacion.textoDescriptivo}}</div>
            <div class="category">
              Para mas detalles vaya a la sección "ordenes"
              <v-icon
                size="16"
              >
                mdi-wench
              </v-icon>
            </div>
          </div>
        </material-card>
    </v-layout>
    <v-layout
      v-else
      justify-center
      align-center
    >
      <v-flex>
        <material-card
          color="grey">
          <div
            slot="header"
          >
            <div class="title font-weight-light mb-1">No tiene alertas sin leer</div>
            <div class="category">
              Se encuentra al día con sus ordenes de servicio
              <v-icon
                size="16"
              >
                mdi-package-up
              </v-icon>
            </div>
          </div>
        </material-card>
      </v-flex>
      </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data: () => ({
    
  }),

  mounted() {
    this.getNotificaciones();
  },

  methods: {
    ...mapActions({
        getNotificaciones: "notificacion/getNotificaciones"
    })
  },
  computed: {
    ...mapState({
        notificaciones: (state) => state.notificacion.notificaciones
    })
  }
}
</script>
