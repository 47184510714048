<template>
	<v-layout row justify-center>
		<v-dialog v-model="show_detail" persistent max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">Detalle del registro</span>
				</v-card-title>
				<v-card-text>
					<v-container grid-list-md>
						<v-layout wrap>
							<v-flex xs12>
								<!-- Cliente -->
								<v-list-tile avatar>
									<v-list-tile-action>
										<v-icon>contacts</v-icon>
									</v-list-tile-action>
									<v-list-tile-content>
										<v-list-tile-title>
											Cliente
										</v-list-tile-title>
										<v-list-tile-sub-title>
											{{ nombreFantasia }}
										</v-list-tile-sub-title>
									</v-list-tile-content>
								</v-list-tile>
							</v-flex>
							<v-flex xs12>
								<!-- Razon Social -->
								<v-list-tile avatar>
									<v-list-tile-action>
										<v-icon>contacts</v-icon>
									</v-list-tile-action>
									<v-list-tile-content>
										<v-list-tile-title>
											Razón Social
										</v-list-tile-title>
										<v-list-tile-sub-title>
											{{ razonSocial }}
										</v-list-tile-sub-title>
									</v-list-tile-content>
								</v-list-tile>
							</v-flex>
							<v-flex xs12>
								<!-- Rubro -->
								<v-list-tile avatar>
									<v-list-tile-action>
										<v-icon>cases</v-icon>
									</v-list-tile-action>
									<v-list-tile-content>
										<v-list-tile-title>
											Rubro
										</v-list-tile-title>
										<v-list-tile-sub-title>
											{{ rubro ? rubro.nombre : "-" }}
										</v-list-tile-sub-title>
									</v-list-tile-content>
								</v-list-tile>
							</v-flex>
							<v-flex xs12>
								<!-- CUIT -->
								<v-list-tile avatar>
									<v-list-tile-action>
										<v-icon>content_paste_search</v-icon>
									</v-list-tile-action>
									<v-list-tile-content>
										<v-list-tile-title
											>CUIT</v-list-tile-title
										>
										<v-list-tile-sub-title>
											{{ cuit }}
										</v-list-tile-sub-title>
									</v-list-tile-content>
								</v-list-tile>
							</v-flex>
							<v-flex xs12>
								<!-- Teléfono -->
								<v-list-tile avatar>
									<v-list-tile-action>
										<v-icon>phone</v-icon>
									</v-list-tile-action>
									<v-list-tile-content>
										<v-list-tile-title
											>Teléfono</v-list-tile-title
										>
										<v-list-tile-sub-title>
											{{ telefono }}
										</v-list-tile-sub-title>
									</v-list-tile-content>
								</v-list-tile>
							</v-flex>
							<v-flex xs12>
								<!-- Email -->
								<v-list-tile avatar>
									<v-list-tile-action>
										<v-icon>email</v-icon>
									</v-list-tile-action>
									<v-list-tile-content>
										<v-list-tile-title
											>Email</v-list-tile-title
										>
										<v-list-tile-sub-title>
											{{ email }}
										</v-list-tile-sub-title>
									</v-list-tile-content>
								</v-list-tile>
							</v-flex>
							<v-flex xs12>
								<!-- País -->
								<v-list-tile avatar>
									<v-list-tile-action>
										<v-icon>mdi-map-marker-radius</v-icon>
									</v-list-tile-action>
									<v-list-tile-content>
										<v-list-tile-title
											>Pais</v-list-tile-title
										>
										<v-list-tile-sub-title>
											{{ pais ? pais.nombre : "-" }}
										</v-list-tile-sub-title>
									</v-list-tile-content>
								</v-list-tile>
							</v-flex>
							<v-flex xs12>
								<!-- Categoria AFIP -->
								<v-list-tile avatar>
									<v-list-tile-action>
										<v-icon></v-icon>
									</v-list-tile-action>
									<v-list-tile-content>
										<v-list-tile-title
											>Categoria AFIP</v-list-tile-title
										>
										<v-list-tile-sub-title>{{
											nombreCategoriaCliente
										}}</v-list-tile-sub-title>
									</v-list-tile-content>
								</v-list-tile>
							</v-flex>
						</v-layout>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						flat
						@click="openDetail(false)"
					>
						Cerrar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-layout>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
	data: () => ({}),

	methods: {
		...mapMutations({
			openDetail: "cliente/OPEN_DETAIL",
		}),

		formatDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
	},

	computed: {
		...mapState({
			nombreFantasia: (state) => state.cliente.nombreFantasia,
			razonSocial: (state) => state.cliente.razonSocial,
			rubro: (state) => state.cliente.rubro,
			telefono: (state) => state.cliente.telefono,
			cuit: (state) => state.cliente.cuit,
			pais: (state) => state.cliente.pais,
			categoriaAfip: (state) => state.cliente.categoriaAfip,
			email: (state) => state.cliente.email,
			show_detail: (state) => state.cliente.show_detail,
		}),

		nombreCategoriaCliente() {
			return this.categoriaAfip
				? this.categoriaAfip.nombre
				: "No Registrado";
		},
	},
};
</script>
