export const defaultState = function() {
    return {
        //Action getSucursales
        getSucursalesLoading: false,
        getSucursalesSuccess: false,
        getSucursalesError: false,
        getSucursalesErrorMessage: '',
        sucursales:[],
        //Action postSucursal
        postSucursalLoading: false,
        postSucursalSuccess: false,
        postSucursalError: false,
        postSucursalErrorMessage: '',
        //Action updateSucursalTheme
        updateSucursalLoading: false,
        updateSucursalSuccess: false,
        updateSucursalError: false,
        updateSucursalErrorMessage: '',
        //Action removeSucursal
        removeSucursalLoading: false,
        removeSucursalSuccess: false,
        removeSucursalError: false,
        removeSucursalErrorMessage: '',
    }
};