import { defaultState } from "./config";
import Vue from "vue";

export const mutations = {
    GET_NOTIFICACIONES_LOADING(state) {
        state.getNotificacionesLoading = true;
        state.getNotificacionesSuccess = false;
    },
    GET_NOTIFICACIONES_SUCCESS(state, notificaciones) {
        state.getNotificacionesLoading = false;
        state.getNotificacionesSuccess = true;
        state.notificaciones = [];
        notificaciones.map((notificacion, index) => {
            Vue.set(state.notificaciones, index, { ...notificacion })
        });
    },
    GET_NOTIFICACIONES_ERROR(state, e) {
        state.getNotificacionesLoading = false;
        state.getNotificacionesSuccess = false;
        state.getNotificacionesError = true;
        state.getNotificacionesErrorMessage = e;
    }
}