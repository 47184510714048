import { defaultState } from "./config";

const state = Object.assign(
  {
    id: null,
    sucursal: null,
    numeroorden: null,
    tipoOrden: null,
    fechainicio: null,
    fechafin: null,
    fechavencimiento: null,
    cliente_id: null,
    show_confirm_delete: false,
    show_form: false,
    show_detail: false,
    is_update_form: false,
  },
  defaultState()
);

export default state;
