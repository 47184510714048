<template>
  <v-container>
    <h1>Esta es la pantalla de reseteo de passowrd bien cruda...</h1>
    <v-form>
      <v-input
        type="password"
        class="form-control"
        placeholder="Password"
        v-model="password"
        v-on:keyup="keyUp"
      >New Password</v-input>
    </v-form>
    <v-row>
      <v-input>New Password</v-input>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'SetUserForm',
  }
</script>