import api from "./api";
import Cookies from "js-cookie";

const ordenApi = {
  getOrdenes() {
    return api
      .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
      .get(`${process.env.VUE_APP_IP}/api/ordenes`);
  },

  getOrdenesByCliente(id) {
    return api
      .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
      .get(`${process.env.VUE_APP_IP}/api/ordenes/cliente/${id}`);
  },
  exportOrdenesByCliente(id) {
    return api
      .axiosInstance(null, null, {
        "x-access-token": Cookies.get("token"),
        responseType: 'blob', // important
      }, 'arraybuffer')
      .get(`${process.env.VUE_APP_IP}/api/ordenes/export/${id}`);
  },

  postOrden({
    sucursal,
    tipoOrden,
    fechainicio,
    fechafin,
    fechavencimiento,
    cliente_id,
  }) {
    return api
      .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
      .post(`${process.env.VUE_APP_IP}/api/ordenes`, {
        tipoOrden_id: tipoOrden._id,
        sucursal_id: sucursal._id,
        fechainicio,
        fechafin,
        fechavencimiento,
        cliente_id,
      });
  },

  removeOrden(id) {
    return api
      .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
      .delete(`${process.env.VUE_APP_IP}/api/ordenes/${id}`);
  },

  updateOrden({
    id,
    sucursal,
    tipoOrden,
    fechainicio,
    numeroorden,
    fechafin,
    fechavencimiento,
    cliente_id,
  }) {
    return api
      .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
      .put(`${process.env.VUE_APP_IP}/api/ordenes/${id}`, {
        tipoOrden_id: tipoOrden._id,
        sucursal_id: sucursal._id,
        fechainicio,
        numeroorden,
        fechafin,
        fechavencimiento,
        cliente_id,
      });
  },

  postCapacitacion({
    tipoOrden,
    tituloCapacitacion,
    ispresencial,
    horainicio,
    horafin,
    planilla,
    attendees,
    sucursal,
    fechainicio,
    fechafin,
    fechavencimiento,
  }) {
    let formData = new FormData();
    formData.append("tipoOrden_id", tipoOrden._id);
    formData.append("tituloCapacitacion", tituloCapacitacion);
    formData.append("ispresencial", ispresencial);
    formData.append("horainicio", horainicio);
    formData.append("horafin", horafin);
    formData.append("attendees", JSON.stringify(attendees));
    if (planilla) {
      formData.append("planilla", planilla, planilla.name);
    }
    formData.append("sucursal_id", sucursal._id);
    formData.append("cliente_id", sucursal.cliente_id);
    formData.append("fechainicio", fechainicio);
    formData.append("fechafin", fechafin);
    formData.append("fechavencimiento", fechavencimiento);
    return api
      .axiosInstance(null, null, { "x-access-token": Cookies.get("token"), 'Content-Type': 'multipart/form-data' })
      .post(
        `${process.env.VUE_APP_IP}/api/capacitaciones`,
        formData
      );
  },

  updateCapacitacion({
    id,
    orden_id,
    tipoOrden,
    ispresencial,
    horainicio,
    horafin,
    numeroorden,
    planilla,
    attendees,
    sucursal,
    fechainicio,
    fechafin,
    fechavencimiento,
    filename,
  }) {
    let formData = new FormData();
    formData.append("ispresencial", ispresencial);
    formData.append("orden_id", orden_id);
    formData.append("horainicio", horainicio);
    formData.append("horafin", horafin);
    formData.append("numeroorden", numeroorden);
    formData.append("attendees", JSON.stringify(attendees));
    formData.append("tipoOrden_id", tipoOrden._id);
    formData.append("sucursal_id", sucursal._id);
    formData.append("cliente_id", sucursal.cliente_id);
    formData.append("fechainicio", fechainicio);
    formData.append("fechafin", fechafin);
    formData.append("fechavencimiento", fechavencimiento);
    if (planilla) {
      formData.append("planilla", planilla, planilla.name);
    }
    formData.append("oldfilename", filename);
    return api
      .axiosInstance(null, null, { "x-access-token": Cookies.get("token"), 'Content-Type': 'multipart/form-data' })
      .put(
        `${process.env.VUE_APP_IP}/api/capacitaciones/${id}`,
        formData
      );
  },
};

export default ordenApi;
