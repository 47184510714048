export const KEY_AUTH = "auth";

const getValue = key => {
    if (window.localStorage.getItem(key)) {
      try {
        return JSON.parse(window.localStorage.getItem(key));
      } catch (e) {
        window.localStorage.removeItem(key);
        throw e;
      }
    } else {
      return null;
    }
  }
  
  const saveValue = (key, value) => window.localStorage.setItem(key, JSON.stringify(value));

  const removeValue = key => window.localStorage.removeItem(key);
  
  export default {
    getValue,
    saveValue,
    removeValue
  }