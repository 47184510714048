<template>
  <v-app>
    <app-layout v-if="!['Login', 'Activation'].includes($route.name)"/>
    <router-view v-else/>
   </v-app>
</template>
<script>
  import AppLayout from './layouts/AppLayout.vue'
  export default {
    name: 'App',
    components: { AppLayout }
  }
</script>

<style lang="scss">
@import '@/styles/index.scss';

/* Remove in 1.2 */
.v-datatable thead th.column.sortable i {
  vertical-align: unset;
}
</style>
