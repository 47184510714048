<template>
	<v-layout row justify-center>
		<v-dialog v-model="showForm" persistent max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">Nuevo Perfil Técnico</span>
				</v-card-title>
				<v-card-text>
					<v-form
						ref="form-createtec"
						v-model="valid"
						lazy-validation
					>
						<v-container grid-list-md>
							<v-layout wrap>
								<v-flex xs12>
									<v-text-field
										label="Nombre"
										v-model="tecnico.nombre"
										required
										:rules="form_rules"
									/>
								</v-flex>
								<v-flex xs12>
									<v-text-field
										label="Apellido"
										v-model="tecnico.apellido"
										required
										:rules="form_rules"
									/>
								</v-flex>
								<v-flex xs12>
									<v-text-field
										label="E-mail"
										type="email"
										v-model="tecnico.email"
										required
										:rules="form_rules"
									/>
								</v-flex>
								<v-flex xs12>
									<v-text-field
										label="CUIT"
										v-model="tecnico.cuit"
										required
										:rules="form_rules"
									/>
								</v-flex>
							</v-layout>
						</v-container>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn flat @click="closeForm"> Cerrar </v-btn>
					<v-btn dark color="primary" depressed @click="sendForm">
						{{ loading ? 'Cargnado...' : 'Enviar' }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-layout>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'

export default {
	props: {
		showForm: {
			type: Boolean,
			required: true
		}
	},
	data: () => ({
		dialog: false,
		valid: true,
		form_rules: [(v) => !!v || 'Este campo es requerido'],
		loading: false,
		tecnico: {
			nombre: '',
			apellido: '',
			email: '',
			cuit: ''
		}
	}),

	mounted() {},

	computed: {
		...mapState({
			user: (state) => state.user
		}),
		is_admin() {
			return this.user.isAdmin
		}
	},

	methods: {
		...mapActions({
			createTecnico: 'tecnicos/createTecnico'
		}),
		resetForm() {
			this.tecnico = {
				nombre: '',
				apellido: '',
				email: '',
				cuit: ''
			}
			this.valid = true
		},
		closeForm() {
			this.resetForm()
			this.$refs['form-createtec'].reset()
			this.$emit('close')
		},
		async sendForm() {
			try {
				this.loading = true
				await this.createTecnico(this.tecnico)
				this.loading = false

				Vue.swal({
					type: 'success',
					title: 'Nuevo perfil técnico creado'
				}).then(() => {
					this.closeForm()
				})
			} catch (error) {
				this.loading = false
				Vue.swal({
					type: 'error',
					title: 'Error al crear perfil'
				})
			}
		}
	}
}
</script>
