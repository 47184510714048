<template>
	<v-navigation-drawer
		id="app-drawer"
		v-model="inputValue"
		app
		dark
		floating
		persistent
		mobile-break-point="991"
		width="260"
	>
		<v-layout class="fill-height" tag="v-list" column>
			<v-list-tile avatar>
				<v-list-tile-avatar color="white">
					<v-img
						:src="profileImage"
						height="100"
						contain
						style="top: 25%"
					/>
				</v-list-tile-avatar>
				<div v-if="user.cliente" name="razonSocial" class="title">
					{{ user.cliente.razonSocial }}
					<router-link to="user-profile/"></router-link>
				</div>
				<div v-else name="administrador" class="title">
					Administrador
					<router-link to="user-profile/"></router-link>
				</div>
			</v-list-tile>
			<v-divider />

			<div class="overflow-sidebar">
				<v-list-tile
					v-for="(link, i) in itemsFiltered"
					:key="i"
					:to="link.to"
					:active-class="color"
					avatar
					class="v-list-item custom-item"
				>
					<v-list-tile-action>
						<v-icon>{{ link.icon }}</v-icon>
					</v-list-tile-action>
					<v-list-tile-title>
						{{ link.text }}
					</v-list-tile-title>
				</v-list-tile>
			</div>
			<v-list-tile avatar class="v-list-item custom-logout">
				<v-list-tile-action>
					<v-icon>mdi-exit-to-app</v-icon>
				</v-list-tile-action>
				<v-list-tile-title
					v-text="'Cerrar Sesión'"
					@click="this.logoutUser"
				/>
			</v-list-tile>
		</v-layout>
	</v-navigation-drawer>
</template>

<script>
// Utilities
import { mapMutations, mapActions, mapState } from "vuex";

export default {
	props: {
		opened: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		logo: "img/adr.png",
		color: "#333",
		links: [
			{
				to: "/usuarios",
				icon: "mdi-account-multiple",
				text: "Usuarios",
				adminOnly: true,
			},
			{
				to: "/tecnicos",
				icon: "mdi-account-multiple",
				text: "Tecnicos",
				adminOnly: true,
			},
			{
				to: "/clientes",
				icon: "mdi-account-multiple",
				text: "Clientes",
				adminOnly: true,
			},
			{
				to: "/empleados",
				icon: "mdi-account-card-details",
				text: "Empleados",
				adminOnly: true,
			},
			/* {
        to: '/',
        icon: 'mdi-view-dashboard',
        text: 'Estadísticas',
        enable: true
      }, */

			{
				to: "/tiposOrdenes",
				icon: "mdi-clipboard-text",
				text: "Tipo de Orden",
				adminOnly: true,
			},
			{
				to: "/ordenes",
				icon: "mdi-library",
				text: "Ordenes",
				adminOnly: false,
			},

			/*
      {
        to: '/typography',
        icon: 'mdi-format-font',
        text: 'Typography'
      },

      {
        to: '/icons',
        icon: 'mdi-chart-bubble',
        text: 'Icons'
      },*/
			{
				to: "/localidades",
				icon: "mdi-map-marker",
				text: "Localidades",
				adminOnly: true,
			},
			{
				to: "/rubros",
				icon: "mdi-map-legend",
				text: "Rubros Laborales",
				adminOnly: true,
			},
			{
				to: "/sucursales",
				icon: "mdi-store",
				text: "Sucursales",
				adminOnly: true,
			},
			/*
      {
        to: '/maps',
        icon: 'mdi-map-legend',
        text: 'Mapa de Clientes',
        enable: false
      },
      *
      {
        to: '/user-profile',
        icon: 'mdi-account',
        text: 'Perfil',
        enable: true
      },
      */
			{
				to: "/notificaciones",
				icon: "mdi-bell",
				text: "Notificaciones",
				adminOnly: false,
			},
			{
				to: "/documentaciones",
				icon: "mdi-folder",
				text: "Documentación",
				adminOnly: false,
				showTecnico: true,
			},
			/* {
        to: '/Login',
        icon: 'mdi-exit-to-app',
        text: 'Cerrar sesión',
        adminOnly: false
      }, */
		],
	}),
	computed: {
		...mapState({
			user: (state) => state.user,
		}),

		itemsFiltered() {
			return this.links.filter((item) => {
				return (
					(item.adminOnly && this.user.isAdmin) ||
					(item.adminOnly === false && !!this.user.cliente) ||
					(item.showTecnico && !!this.user.tecnico) ||
					(item.showTecnico && this.user.isAdmin) ||
					(!item.adminOnly && this.user.isAdmin)
				);
			});
		},

		profileImage() {
			return this.user.cliente ? this.user.cliente.linktofile : this.logo;
		},

		inputValue: {
			get() {
				/*
        return this.$store.state.app.drawer   #tuve que comentar esto porque no me reconoce el $store.app, despues veremos por qué
        */
				return this.drawer; //evidentemente en algun lado se esta exportando de una manera que no me permite poner $store.state
			},
			set(val) {
				this.setDrawer(val);
			},
		},
		items() {
			return this.$t("Layout.View.items");
		},
	},

	methods: {
		...mapMutations("app", ["setDrawer", "toggleDrawer"]),
		...mapActions({
			loadUser: "user/loadUser",
			logoutUser: "user/logoutUser",
		}),

		callLogoutActions() {
			this.logoutUser;
		},
	},
};
</script>

<style lang="scss">
#app-drawer {
	.v-list__tile {
		border-radius: 4px;

		&--buy {
			margin-top: auto;
			margin-bottom: 17px;
		}
	}
	.overflow-sidebar {
		max-height: calc(100% - 150px);
	}
}
</style>
