import { defaultState } from "./config";

const state = Object.assign(
  {
    id: null,
    user_id: null,
    textoDescriptivo: null,
    leida: null,
  },
  defaultState()
);

export default state;
