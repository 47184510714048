import empleadoApi from '../../../api/empleadoApi';


export default {
    getEmpleados({ commit }) {
        return empleadoApi
            .getEmpleados()
            .then((response) => {
                let empleados = response.data;
                commit("GET_EMPLEADOS_SUCCESS", empleados);
            })
            .catch((e) => {
                commit("GET_EMPLEADOS_ERROR", e);
            });
    },

    postEmpleado({ dispatch, commit, state }) {
        return empleadoApi
            .postEmpleado(state)
            .then((_response) => {
                commit("POST_EMPLEADO_SUCCESS");
                dispatch("getEmpleados");
            })
            .catch((e) => {
                commit("POST_EMPLEADO_ERROR", e);
            });
    },
    postEmpleadoExcel({ dispatch, commit }, payload) {
        return empleadoApi
            .postEmpleadoExcel(payload)
            .then((_response) => {
                commit("POST_EMPLEADO_SUCCESS");
                dispatch("getEmpleados");
            })
            .catch((e) => {
                commit("POST_EMPLEADO_ERROR", e);
            });
    },

    updateEmpleado({ commit, dispatch, state }) {
        return empleadoApi
            .updateEmpleado(state)
            .then((_response) => {
                dispatch("getEmpleados");
                commit("UPDATE_EMPLEADO_SUCCESS");
            })
            .catch((e) => {
                commit("UPDATE_EMPLEADO_ERROR", e);
            });
    },

    removeEmpleado({ dispatch, commit }, id) {
        commit("REMOVE_EMPLEADO_LOADING");
        return empleadoApi
            .removeEmpleado(id)
            .then((_response) => {
                commit("REMOVE_EMPLEADO_SUCCESS");
                dispatch("getEmpleados");
            })
            .catch((e) => {
                commit("REMOVE_EMPLEADO_ERROR", e);
            });
    },
};