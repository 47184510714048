import api from './api';
import Cookies from 'js-cookie';

const tecnicosApi = {
  async getTecnicos() {
    return api.axiosInstance(null, null, { "x-access-token": Cookies.get("token") }).get(`${process.env.VUE_APP_IP}/api/tecnicos`);
  },
  async postTecnico({ nombre, apellido, email, cuit }) {
    return api.axiosInstance(null, null, { "x-access-token": Cookies.get("token") }).post(`${process.env.VUE_APP_IP}/api/tecnicos`, { nombre, apellido, email, cuit });
  },
  async updateTecnico({ id, nombre, apellido, email, cuit }) {
    return api.axiosInstance(null, null, { "x-access-token": Cookies.get("token") }).put(`${process.env.VUE_APP_IP}/api/tecnicos/${id}`, { nombre, apellido, email, cuit });
  },
  async removeTecnico({ id }) {
    return api.axiosInstance(null, null, { "x-access-token": Cookies.get("token") }).delete(`${process.env.VUE_APP_IP}/api/tecnicos/${id}`);
  }
};

export default tecnicosApi;