import api from "./api";
import Cookies from "js-cookie";

const userApi = {
  async getUser(id) {
    return await api
      .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
      .get(`${process.env.VUE_APP_IP}/api/users/${id}`);
  },
  async getUsers() {
    return await api
      .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
      .get(`${process.env.VUE_APP_IP}/api/users`);
  },

  removeUser(id) {
    return api
      .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
      .delete(`${process.env.VUE_APP_IP}/api/users/${id}`);
  },

  updateUser({ id, password, status }) {
    return api
      .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
      .put(`${process.env.VUE_APP_IP}/api/users/${id}`, {
        password,
        status,
      });
  },
  enableDisableAccount({ id }) {
    return api
      .axiosInstance(null, null, { "x-access-token": Cookies.get("token") })
      .patch(`${process.env.VUE_APP_IP}/api/admin/${id}`);
  },
  validateAccount({ token, password, id }) {
    return api.axiosInstance(null, null)
      .post(`${process.env.VUE_APP_IP}/api/activation/${id}`, {
        token,
        password
      })
  }
};

export default userApi;
