<template>
  <v-container>
    <h3 class="mb-4">Tipos de órdenes</h3>
    <!-- Card -->
    <v-card class="my-2">
      <v-card-title>
        <v-layout row wrap class="mb-2 px-2">
          <v-spacer></v-spacer>
          <v-flex xs3 class="px-2">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Filtrar"
              single-line
              hide-details
            />
          </v-flex>
        </v-layout>
      </v-card-title>
      <!-- Table -->
      <v-data-table :headers="headers" :items="tiposOrdenes_filter" :pagination.sync="pagination">
        <template v-slot:items="tiposOrdenes">
          <td class="text-xs-left">
            {{ tiposOrdenes.item.nombre }}
          </td>
          <td class="text-xs-center">-</td>
          <td
            v-if="tiposOrdenes.item.capacitacion"
            class="text-xs-center green--text text--lighten-1"
          >
            <b>Capacitación</b>
          </td>
          <td
            v-else-if="tiposOrdenes.item.compliance"
            class="text-xs-center blue--text text--lighten-1"
          >
            <b>Cumplimiento Normativa</b>
          </td>
          <td
            v-else-if="tiposOrdenes.item.inspeccion"
            class="text-xs-center  purple--text text--lighten-1"
          >
            <b>Inspección</b>
          </td>
          <td v-else class="text-xs-center purple--text text--lighten-1"><b>Inspección</b></td>
          <td class="justify-center layout px-0">
            <v-icon
              small
              title="Editar registro"
              class="mr-2"
              @click="showForm(tiposOrdenes.item)"
            >
              edit
            </v-icon>
            <v-icon
              small
              title="Eliminar registro"
              @click="showConfirmDelete(tiposOrdenes.item._id)"
            >
              delete
            </v-icon>
          </td>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
            No se encontraron resultados para "{{ search }}".
          </v-alert>
        </template>
      </v-data-table>
    </v-card>

    <v-layout align-center justify-end row fill-height class="mt-2">
      <v-flex xs3 class="mx-2">
        <v-btn color="primary" block @click="showForm(false)">
          Crear tipo de orden
        </v-btn>
      </v-flex>
    </v-layout>

    <!-- Modals -->
    <tipoOrden-form />
    <confirm-delete :tipoOrden_id="tipoOrden_id" />
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import TipoOrdenForm from "./TipoOrdenForm.vue";
import ConfirmDelete from "./ConfirmDelete.vue";

export default {
  data: () => ({
    tipoOrden_id: null,
    search: "",
    pagination: {
      rowsPerPage: -1, // con -1 mustra "Todos"
    },
    headers: [
      {
        sortable: true,
        text: "Nombre",
        value: "nombre",
        align: "left",
      },
      {
        sortable: true,
        text: "Cant. Servicios ofrecidos el ultimo año",
        value: "-",
        align: "center",
      },
      {
        sortable: true,
        text: "Tipo Servicio",
        value: "capacitacion",
        align: "center",
      },

      {
        sortable: false,
        text: "Acciones",
        value: "actions",
        align: "center",
      },

    ],
  }),

  mounted() {
    this.getTiposOrdenes();
  },

  components: {
    TipoOrdenForm,
    ConfirmDelete,
  },

  computed: {
    ...mapState({
      tiposOrdenes: (state) => state.tipoOrden.tiposOrdenes,
    }),

    tiposOrdenes_filter() {
      const valid = this.search && this.search.trim().length > 0;
      if (valid) {
        return this.tiposOrdenes.filter(
          (item) =>
            item.nombre.search(this.search) > -1
        );
      }
      return this.tiposOrdenes;
    },
  },

  methods: {
    ...mapActions({
      getTiposOrdenes: "tipoOrden/getTiposOrdenes",
    }),

    ...mapMutations({
      /**
       * Ordenes
       */
      setShowConfirmDelete: "tipoOrden/SET_SHOW_CONFIRM_DELETE",
      openForm: "tipoOrden/OPEN_FORM",
      loadForm: "tipoOrden/LOAD_FORM",
      setIsUpdateForm: "tipoOrden/SET_IS_UPDATE_FORM",
      resetForm: "tipoOrden/RESET",
    }),

    showConfirmDelete(id) {
      this.tipoOrden_id = id;
      this.setShowConfirmDelete(true);
    },

    showForm(tipoOrden) {
      if (tipoOrden) this.loadForm(tipoOrden);
      this.setIsUpdateForm(tipoOrden);
      this.openForm(true);
    }
  },
};
</script>

