<template>
	<v-container>
		<h3 class="mb-4">Lista de documentaciones</h3>

		<v-card class="my-2">
			<v-card-title>
				<v-layout row wrap class="mb-2 px-2">
					<v-spacer></v-spacer>
					<v-flex xs3 class="px-2">
						<v-text-field
							v-model="search"
							append-icon="search"
							label="Buscar"
							single-line
							hide-details
						/>
					</v-flex>
				</v-layout>
			</v-card-title>

			<v-card-text>
				<v-data-table
					:headers="headers"
					:items="documentos_results"
					:pagination.sync="pagination"
				>
					<template v-slot:items="documentos">
						<td class="text-xs-center">
							{{ documentos.item.nombre }}
						</td>
						<td class="text-xs-center">
							{{
								formatDate(
									documentos.item.createdAt.split("T")[0]
								)
							}}
						</td>
						<td class="text-xs-center">
							<v-chip small>{{
								documentos.item.etiqueta
							}}</v-chip>
						</td>
						<td class="text-xs-center">
							<v-btn
								:href="documentos.item.file"
								target="_blank"
								fab
								color="blue"
								dark
								small
								depressed
							>
								<v-icon>mdi-file</v-icon>
							</v-btn>
							<v-btn
								v-if="user.isAdmin"
								fab
								color="red"
								dark
								small
								depressed
								@click="removeDoc(documentos.item._id)"
							>
								<v-icon>mdi-delete</v-icon>
							</v-btn>
						</td>
					</template>
				</v-data-table>
			</v-card-text>
			<v-card-actions>
				<v-btn
					v-if="user.isAdmin || user.tecnico"
					@click="openForm"
					dark
					color="blue"
					class="ml-auto"
				>
					<v-icon>mdi-account</v-icon>Asignar Documentación
				</v-btn>
				<v-btn
					v-if="user.isAdmin"
					@click="openFormNew"
					dark
					color="green"
					class="ml-4"
				>
					<v-icon>mdi-upload</v-icon>Subir PDF
				</v-btn>
			</v-card-actions>
		</v-card>
		<form-asignar :show-form="showForm" @close="closeForm"></form-asignar>
		<form-crear :show-form="showFormNew" @close="closeFormNew"></form-crear>
	</v-container>
</template>

<script>
import Vue from "vue";
import FormAsignar from "./FormAsignar.vue";
import FormCrear from "./FormCrear.vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
	components: {
		FormAsignar,
		FormCrear,
	},
	data() {
		return {
			search: "",
			headers: [
				{
					sortable: true,
					text: "Nombre",
					value: "nombre",
					align: "center",
				},
				{
					sortable: true,
					text: "Fecha",
					value: "createdAt",
					align: "center",
				},
				{
					sortable: true,
					text: "Etiqueta",
					value: "etiqueta",
					align: "center",
				},
				{
					sortable: true,
					text: "Archivo",
					value: "file",
					align: "center",
				},
			],
			pagination: {
				rowsPerPage: -1, // con -1 mustra "Todos"
			},
			showForm: false,
			showFormNew: false,
		};
	},
	computed: {
		...mapState({
			user: (state) => state.user,
			documentos: (state) => state.pdf.documentos,
			clientes: (state) => state.cliente.clientes,
		}),
		documentos_results() {
			const valid = this.search && this.search.trim().length > 0;

			if (valid) {
				return this.documentos.filter((documento) =>
					Object.values(documento).some(
						(val) =>
							val != null &&
							val
								.toString()
								.toLowerCase()
								.includes(this.search.toLowerCase())
					)
				);
			}
			return this.documentos;
		},
	},
	methods: {
		...mapActions({
			getClientes: "cliente/getClientes",
			getDocumentos: "pdf/getDocumentos",
			removeDocumento: "pdf/removeDocumento",
		}),
		...mapMutations({
			selectDocumento: "pdf/SET_DOCUMENTO_SELECTED",
		}),
		formatDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
		openForm(document) {
			this.selectDocumento(document);
			this.showForm = true;
		},
		openFormNew() {
			this.showFormNew = true;
		},
		closeForm() {
			this.showForm = false;
			this.selectDocumento(null);
		},
		closeFormNew() {
			this.showFormNew = false;
		},
		removeDoc(id) {
			Vue.swal
				.fire({
					title: "¿Está seguro de eliminar?",
					type: "warning",
					showCancelButton: true,
					confirmButtonText: "Sí, estoy seguro",
					cancelButtonText: "Cancelar",
					reverseButtons: true,
				})
				.then((result) => {
					if (result.value) {
						try {
							this.removeDocumento({ id }).then(() => {
								Vue.swal({
									title: "Documentación eliminada",
									type: "success",
								});
							});
						} catch (error) {
							Vue.swal({
								title: "Error al eliminar",
								type: "Error",
							});
						}
					}
				});
		},
	},
	mounted() {
		if (this.clientes.length < 1) {
			this.getClientes();
		}

		// todo: validar si el usuario es admin o tecnico
		if (this.user.tecnico || this.user.isAdmin) {
			this.getDocumentos();
		} else {
			this.$router.push("/documentaciones");
		}
	},
};
</script>
