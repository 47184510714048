<template>
	<v-layout row justify-center>
		<v-dialog v-model="showForm" persistent max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">Asignar Documentación</span>
				</v-card-title>
				<v-card-text>
					<v-form ref="form-pdf" v-model="valid" lazy-validation>
						<v-container grid-list-md>
							<v-layout wrap>
								<v-flex xs12>
									<v-text-field
										label="Nombre"
										v-model="nombre"
										required
										:rules="form_rules"
									/>
								</v-flex>
								<v-flex xs12>
									<v-text-field
										label="Etiqueta"
										v-model="etiqueta"
										required
										:rules="form_rules"
									/>
								</v-flex>
								<v-flex xs12>
									<input
										type="file"
										name="pdf"
										id="pdf"
										@change="validateFile"
										required
									/>
								</v-flex>
								<v-flex xs12>
									<v-alert
										v-model="fileValid"
										outline
										type="success"
										>Archivo válidado
									</v-alert>
									<v-alert
										v-model="fileHasErrors"
										outline
										type="error"
										>Archivo inválido, por favor utilice uno
										de los siguientes formatos:
										<b>.pdf</b>
									</v-alert>
								</v-flex>
							</v-layout>
						</v-container>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn flat @click="closeForm"> Cerrar </v-btn>
					<v-btn dark color="primary" depressed @click="sendForm">
						{{ loading ? 'Enviando...' : 'Enviar' }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-layout>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'

export default {
	props: {
		showForm: {
			type: Boolean,
			required: true
		}
	},
	data: () => ({
		dialog: false,
		valid: true,
		fileValid: false,
		fileHasErrors: false,
		form_rules: [(v) => !!v || 'Este campo es requerido'],
		fileForm: null,
		nombre: '',
		etiqueta: '',
		loading: false
	}),

	mounted() {},

	methods: {
		...mapActions({
			uploadDocumento: 'pdf/uploadDocumento',
			getDocumentos: 'pdf/getDocumentos'
		}),
		resetForm() {
			this.valid = true
		},
		closeForm() {
			const fileInput = document.getElementById('pdf')

			this.nombre = ''
			this.etiqueta = ''
			this.fileForm = null
			this.fileHasErrors = false
			this.fileValid = false
			fileInput.value = null
			this.$refs['form-pdf'].reset();
			this.$emit('close')
		},
		validateFile() {
			const fileInput = document.getElementById('pdf')
			const filePath = fileInput.value

			// allowed file types
			var allowedExtensions = /(\.pdf)$/i

			if (!allowedExtensions.exec(filePath)) {
				this.fileHasErrors = true
				this.fileValid = false
				fileInput.value = ''
			} else {
				this.fileHasErrors = false
				this.fileValid = true

				if (fileInput.files && fileInput.files[0]) {
					this.fileForm = fileInput.files[0]
				}
			}
		},
		async sendForm() {
			//  nombre, etiqueta, file
			try {
				this.loading = true

				const response = await this.uploadDocumento({
					documento: this.fileForm,
					nombre: this.nombre,
					etiqueta: this.etiqueta
				})

				Vue.swal({
					type: 'success',
					title: 'Documento cargado con éxito'
				}).then(() => {
					this.getDocumentos();
					this.closeForm()
				})
			} catch (error) {
				console.error(error);
				Vue.swal({
					type: 'error',
					title: 'Error al cargar documento'
				})
			} finally {
				this.loading = false
			}
		}
	}
}
</script>
